import React, { useState } from "react";
import drop from "./options.module.scss";
import { useNavigate, useParams } from "react-router-dom";

const Options = ({ className, options, icon,propertyId,bookingId,id,setShowPopup,setSelectedItemId }) => {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const [onClick, setOnClick] = useState(false);
  return (
    <div
      className={`${drop.optionsWrap} ${className} ${onClick && drop.active}`}
    >
      <span className={drop.Icon} onClick={() => setOnClick(!onClick)}>
        {icon ? icon : <img src="/assets/svg/action4.svg" alt="" />}
      </span>
      <ul>
        {options ? (
          options?.map((value, index) => (
            <li
              key={index}
              onClick={() => {
                if (value.action) {
                  if (value.action === 'deleteAgent') {
                    setSelectedItemId(id);
                    setShowPopup(true);
                  } else {
                    value.action();
                  }
                } else {
                  navigate(`/${value.route}`);
                }
              }}
            >
              {value.icon} {value.dropmenu}
            </li>
          ))
        ) : (
          <>
            <li onClick={() => navigate(`/agent/property/${propertyId}`)}>
              View Details
            </li>
            <li onClick={() => navigate(`/agent/addPayment/${bookingId}`)}>
              Add Payment
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default Options;
