import React from "react";
import style from "./Receipt.module.scss";
import moment from "moment";

function Receipt2({ data }) {
  const customer = data.customer;
  const userInfo = customer.userInfo;
  const property = data.property;
  const receipt = data.receipts[0];
  const questAnswer = data.questAnswer;

  return (
    <div className={style.receiptWrapper}>
      <div className={style.receipt1Body}>
        <div className={style.topBody}>
          <div className={style.topBodyLeft}>
            <div className={style.logoWrap}>
              <img src="/assets/svg/shamanta.svg" alt="" />
            </div>
            <div className={style.contentWrap}>
              <p className={style.bookon}>
                Booked On:{" "}
                <span>{moment(data?.createdAt).format("DD/MM/YYYY")}</span>
              </p>
            </div>
          </div>
          <div className={style.contentWrapRight}>
            <h2>Issued By</h2>
            <p>702, 801 Shivalik Satyamev,</p>
            <p>Bopal-Ambli Cross Road,</p>
            <p>Opp. Vakil Saheb Bridge, Bopal</p>
            <p>Ahmedabad - 380058</p>
            <p>
              <b>URL:</b> shamanta.in
            </p>
            <p>
              <b>Mobile:</b> +919978917173
            </p>
            <p>
              <b>Mail: </b>shamantabuilders@gmail.com
            </p>
          </div>
        </div>

        <div className={style.MidBody}>
          <h2>Personal Details</h2>

          <div className={style.profilepicWrap}>
            <img src={customer?.profileImg ?? "/assets/img/user1.png"} alt="" />
            <div className={style.profilepicDetail}>
              <p>Customer Id</p>
              <p className={style.tag}>{customer?.code}</p>
              <p className={style.name}>{customer?.name}</p>
            </div>
          </div>
          <div className={style.outerDivWrap2}>
            <div className={style.contentWrap}>
              <p>Date of Birth</p>
              <p className={style.fontblack}>
                {userInfo?.dob
                  ? moment(userInfo?.dob).format("DD/MM/YYY")
                  : "-"}
              </p>
            </div>
            <div className={style.contentWrap}>
              <p>Email Id</p>
              <p className={style.fontblack}>{customer?.email}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Aadhar No.</p>
              <p className={style.fontblack}>{userInfo?.aadhar}</p>
            </div>
            <div className={style.contentWrap}>
              <p>PAN No.</p>
              <p className={style.fontblack}>{userInfo?.pan}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Guardian Name</p>
              <p className={style.fontblack}>{userInfo?.gaurdian}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Relation</p>
              <p className={style.fontblack}>{userInfo?.relation}</p>
            </div>
          </div>

          <div className={style.borderLight}></div>

          <h2>Correspondence Address</h2>
          <div className={style.outerDivWrap2}>
            <div className={style.contentWrap}>
              <p>Street 1</p>
              <p className={style.fontblack}>{userInfo?.stree1}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Street 2</p>
              <p className={style.fontblack}>{userInfo?.stree2}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Landmark</p>
              <p className={style.fontblack}>{userInfo?.landmark}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Pin Code</p>
              <p className={style.fontblack}>{customer?.pincode}</p>
            </div>
            <div className={style.contentWrap}>
              <p>City</p>
              <p className={style.fontblack}>{userInfo?.city}</p>
            </div>
            <div className={style.contentWrap}>
              <p>State</p>
              <p className={style.fontblack}>{userInfo?.state}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Country</p>
              <p className={style.fontblack}>{userInfo?.country}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Contact</p>
              <p className={style.fontblack}>{customer?.mobileNumber}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Alternate Contact</p>
              <p className={style.fontblack}>{userInfo?.alternateMobile}</p>
            </div>
          </div>
          <div className={style.borderLight}></div>

          <h2>Professional Details</h2>
          <div className={style.outerDivWrap2}>
            <div className={style.contentWrap}>
              <p>Company</p>
              <p className={style.fontblack}>{userInfo?.company}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Office Address</p>
              <p className={style.fontblack}>{userInfo?.officeAddress}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Industry</p>
              <p className={style.fontblack}>{userInfo?.industry}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Profession</p>
              <p className={style.fontblack}>{userInfo?.profession}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Annual Income(INR)</p>
              <p className={style.fontblack}>{userInfo?.annualIncome}</p>
            </div>
          </div>

          <div className={style.borderLight}></div>

          <div className={style.outerDivWrap3}>
            <div className={style.contentWrap}>
              <p>How did you come to know about this project?</p>
              <p className={style.fontblack}>{questAnswer?.projectSource}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Are you an Existing Shamantra Client?</p>
              <p className={style.fontblack}>{questAnswer?.existingClient}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Purpose of Purchase</p>
              <p className={style.fontblack}>{questAnswer?.purpose}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Mode of Payment / Source</p>
              <p className={style.fontblack}>{questAnswer?.paymentSource}</p>
            </div>
            <div className={style.contentWrap}>
              <p>
                Would you be Interested in Other Projects by Shamantra builders
                LLP?
              </p>
              <p className={style.fontblack}>
                {questAnswer?.interestInOtherProjects}
              </p>
            </div>
          </div>

          <div className={style.borderLight}></div>
          <h2>Unit Details</h2>

          <div className={style.outerDivWrap2}>
            <div className={style.contentWrap}>
              <p>Unit No.</p>
              <p className={style.fontblack}>Plot No. {property?.plotNo}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Scheme Name</p>
              <p className={style.fontblack}>{property?.scheme}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Area ({property?.areaType})</p>
              <p className={style.fontblack}>
                {property?.area} {property?.areaType}
              </p>
            </div>
            <div className={style.contentWrap}>
              <p>Total Rate per ({property?.areaType})</p>
              <p className={style.fontblack}>
                {property?.rate ?? "-"} {property?.areaType}
              </p>
            </div>
            <div className={style.contentWrap}>
              <p>Total Sale Value</p>
              <p className={style.fontblack}>{property?.price}/-</p>
            </div>
          </div>

          <div className={style.borderLight}></div>
          <h2>Payment Details</h2>

          <div className={style.outerDivWrap2}>
            <div className={style.contentWrap}>
              <p>Account Name</p>
              <p className={style.fontblack}>{receipt?.accountName}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Booking Amount</p>
              <p className={style.fontblack}>{receipt?.amount}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Bank / Cheque No / UPI Id</p>
              <p className={style.fontblack}>{receipt?.transactionId}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Cheque Date</p>
              <p className={style.fontblack}>
                {receipt?.checkDate
                  ? moment(receipt?.checkDate).format("DD/MM/YYYY")
                  : ""}
              </p>
            </div>
            <div className={style.contentWrap}>
              <p>Due Amount</p>
              <p className={style.fontblack}>{data?.dueAmount}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Payment Type</p>
              <p className={style.fontblack}>{data?.paymentType}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Payment Mode</p>
              <p className={style.fontblack}>{receipt?.paymentMode}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Receipt2;
