import React from "react";
import style from "./Receipt.module.scss";

function Receipt5({ data }) {
  const { customer, agent, property, project } = data;

  return (
    <div className={style.receiptWrapper}>
      <img
        src="/assets/svg/shamanta.svg"
        alt="Shamanta Builders Logo"
        className={style.logo}
      />

      <div className={style.letterHead}>
        <h6>To</h6>
        <h5>{customer.name}</h5>
        <p className={style.mt4}>
          {customer.userInfo.company}, {customer.userInfo.officeAddress}
        </p>
        <p className={style.mt4}>{customer.userInfo.stree1}</p>
        <p className={style.mt4}>{customer.userInfo.stree2}</p>
        <p className={style.mt4}>
          {customer.userInfo.city}, {customer.userInfo.state} -{" "}
          {customer.userInfo.pin}
        </p>
      </div>

      <p>Dear Sir/Ma’am,</p>
      <p>Congratulations on booking your own property!!!</p>
      <br />

      <div className={style.letterBody}>
        <p>
          You are warmly welcomed by the SHAMANTA BUILDERS to be a new member of
          "SHAMANTA RESIDENCY". We are very happy to announce that you have
          become a smart investor by investing in the first and best
          well-planned smart city project of <b>"SHAMANTA BUILDERS LLP"</b> at
          Dholera SIR, Dholera, Gujarat.
        </p>
        <br />
        <p>
          We value your positive approach and interest to invest in our
          above-mentioned project and we ensure that your experience with us
          will bring you the utmost satisfaction.
        </p>
        <br />
        <p>
          We are glad to have you as our esteemed family member and very much
          thankful for providing us with a great opportunity to start our new
          relationship with you. Also, we strongly believe in making places that
          are socially responsible, economically vibrant, and environmentally
          sustainable and bright future.
        </p>
        <br />
        <p>
          Thank you again for entrusting SHAMANTA BUILDERS with your most
          important "Emotional satisfaction of owning your own property" needs.
          We are honored to serve you at our level best.
        </p>
        <br />
        <p>
          We are praying to almighty for your happiness, health, wealth, and
          success with your Family.
        </p>
      </div>
      <br />
      <div className={style.receiptFooter}>
        <p>Regards,</p>
        <p>Jay Swaminarayan</p>
      </div>
      <div className={`${style.receiptFooter} mt20`}>
        <p>Yours Faithfully,</p>
        <p>For SHAMANTA BUILDERS LLP</p>
      </div>
      {/* <div className={`${style.receiptFooter} ${style.rightSide}`}>
        <p>Date: 16-05-2023</p>
        <p>Place: RAJASTHAN</p>
      </div> */}
      <div className={`${style.receiptFooter} ${style.signature}`}>
        {/* <img src="" alt="Authorized Sign" /> */}
        <p>(Authorized Sign.)</p>
      </div>
    </div>
  );
}

export default Receipt5;
