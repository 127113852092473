import React, { useEffect, useState } from "react";
import styles from "./Login.module.scss";
import Input from "../../Components/InputField/Input";
import Button from "../../Components/Button/Button";
import { Link } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  userSelector,
  clearState,
  forgotPassword,
  getOrgTheme,
  bypassUser,
  googleSignIn,
} from "../../reducer/userSlice";
import ErrorToast from "../../Components/Toast/ErrorToast";
import SuccessToast from "../../Components/Toast/SuccessToast";
import PageLoader from "../../Components/PageLoader/PageLoader";

const LoginForm = () => {
  const [type, setType] = useState(false);
  const [tabToggler, setTabToggler] = useState(1);

  //Login states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  //Validation states
  const [emailValidation, setEmailValidation] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);

  //Forgot Pass states
  const [forgotEmail, setForgotEmail] = useState("");

  //Toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const {
    isFetching,
    forgettingPass,
    isLoginFetching,
    isSuccess,
    isError,
    errorMessage,
    route,
    forgotPassSuccess,
  } = useSelector(userSelector);

  const handleEmailChange = (e) => {
    console.log("in ema");
    setEmail(e.target.value);
    setEmailValidation("");
    setPasswordValidation("");
  };

  const handleForgotEmailChange = (e) => {
    setForgotEmail(e.target.value);
    setEmailValidation("");
    setPasswordValidation("");
  };

  const handlePassChange = (e) => {
    setEmailValidation("");
    setPasswordValidation("");

    const { value } = e.target;
    setPassword(value);

    if (!value) {
      setPasswordValidation("Please fill password");
    } else {
      setPasswordValidation("");
    }
  };

  const handleSubmit = (e) => {
    console.log("in handle");
    e.preventDefault();
    if (!email || !password) {
    console.log("in fill");

      setPasswordValidation("Please fill both fields!");
    } else {
    console.log("in elsse");

      const filter =
        /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (!filter.test(email)) {
        setEmailValidation("Please enter valid email");
      } else {
    console.log("in api hit");

        setEmailValidation("");
        const obj = {
          email: email,
          password: password,
          rememberMe,
        };
        dispatch(login(obj));
      }
    }
  };

  const handleKeyPressSubmit = (e) => {
    if (e.key === "Enter") {
      const filter =
        /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (!email || !password) {
        setPasswordValidation("Please fill both fields!");
      } else {
        if (!filter.test(email)) {
          setEmailValidation("Please enter valid email");
        } else {
          setEmailValidation("");
          const obj = {
            email: email,
            password: password,
            rememberMe,
          };
          dispatch(login(obj));
        }
      }
    }
  };

  useEffect(() => {
    setEmailValidation("");
    setPasswordValidation("");
  }, [tabToggler]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
      navigate("/" + route, { replace: true });
      window.location.reload();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      setIsShowError(true);
      setErrMessage(errorMessage);
    }
  }, [isError]);

  return (
    <>
      {isLoginFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <SuccessToast
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessage}
      />
      <div className={styles.loginForm}>
        <Input
          label={"Email"}
          placeholder={"Enter your email"}
          value={email}
          type={"text"}
          name={"email"}
          onChange={handleEmailChange}
          onKeyDown={handleKeyPressSubmit}
          errorMessage={emailValidation}
        />
        <Input
          label={"Password"}
          placeholder={"**********"}
          id={"password"}
          type={type ? "text" : "password"}
          inputIcon={type ? "viewIcon" : "hideIcon"}
          value={password}
          onChange={handlePassChange}
          onKeyDown={handleKeyPressSubmit}
          errorMessage={passwordValidation}
        />

        <div className={styles.loginExtra}>
          <Input
            type={"checkbox"}
            label={"Stay Signed In"}
            id={"signed"}
            checkbox={true}
            checked={rememberMe}
            onChange={() =>
              rememberMe ? setRememberMe(false) : setRememberMe(true)
            }
          />
{/* 
          <Link className={styles.forgetPass} to={"/forgetPass"}>
            Forgot Password
          </Link> */}
        </div>
      </div>

      <div className={styles.buttonGroup}>
        <Button blueButton text={"Login"} onClick={handleSubmit} />
        {/* <Button whiteButton icon={"gmail"} text={"Continue with Gmail"} />
        <Button whiteButton icon={"google"} text={"Continue with Google"} /> */}
      </div>
    </>
  );
};

export default LoginForm;
