import React from 'react'
import common from '../popup.module.scss'
import FilterBox from '../../FilterBox/FilterBox'
import Button from '../../Button/Button'
import styles from './newMessage.module.scss'
import table from '../../../Pages/Admin/Projects/Project.module.scss'
import addMessage from './addMessage'

const NewMessage = ({popup, showPopup}) => {

    const close = [
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4.90234" y="5.79785" width="0.7" height="14" transform="rotate(-45 4.90234 5.79785)" fill="#171717" />
            <rect width="0.7" height="14" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 15.2988 5.79785)" fill="#171717" />
        </svg>
    ]

    const searchIcon = [
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 3.3125C5.24695 3.3125 2.8125 5.74695 2.8125 8.75C2.8125 11.753 5.24695 14.1875 8.25 14.1875C9.72046 14.1875 11.0546 13.6038 12.0333 12.6555C12.0502 12.6325 12.069 12.6105 12.0898 12.5898C12.1105 12.569 12.1325 12.5502 12.1555 12.5333C13.1038 11.5546 13.6875 10.2205 13.6875 8.75C13.6875 5.74695 11.253 3.3125 8.25 3.3125ZM13.2712 12.9757C14.233 11.834 14.8125 10.3597 14.8125 8.75C14.8125 5.12563 11.8744 2.1875 8.25 2.1875C4.62563 2.1875 1.6875 5.12563 1.6875 8.75C1.6875 12.3744 4.62563 15.3125 8.25 15.3125C9.85967 15.3125 11.334 14.733 12.4757 13.7712L15.3523 16.6477C15.5719 16.8674 15.9281 16.8674 16.1477 16.6477C16.3674 16.4281 16.3674 16.0719 16.1477 15.8523L13.2712 12.9757Z" fill="#737373" />
        </svg>
    ]

    return (
        <div className={`${common.popupWrapper} ${popup && common.active}`}>
            <div className={common.popup}>
                <div className={common.popupHeader}>
                    <h3>Select Agent</h3>
                    <span onClick={()=> showPopup(false)}>{close}</span>
                </div>
                <div className={common.popupBody}>
                    <div className={styles.filterBox}>
                        <div className={styles.wrap}>
                            <input type="text" placeholder='Search Here...' />
                            {searchIcon}
                        </div>
                        <FilterBox />
                    </div>

                    <table className={`${table.bookingTable} mt20`}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Property</th>
                                <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            {addMessage.map((value, index) => (
                                <tr key={index}>
                                    <td>
                                        <div className={table.tabData}>
                                            <img src={`/assets/img/${value.custImg}.png`} alt="" />
                                            {value.custName}
                                        </div>
                                    </td>
                                    <td>
                                        <div className={table.tabData}>
                                            <img src={`/assets/img/${value.propImg}.png`} alt="" />
                                            {value.prop}
                                        </div>
                                    </td>
                                    <td>
                                        <div className={table.tabData}>
                                            {value.city}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>
                <div className={common.popupFooter}>
                    <Button greyButton height={'auto'} text={'Discard'} padding={10} fontSize={12} maxWidth={80} onClick={()=> showPopup(false)}/>
                    <Button blueBtnLight height={'auto'} text={'Message'} padding={10} fontSize={12} maxWidth={80} />
                </div>
            </div>
        </div>
    )
}

export default NewMessage