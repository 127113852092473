import React, { useEffect, useState } from "react";
import styles from "../Project.module.scss";
import AboutSlider from "../../../../Components/Slider/AboutSlider";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearState,
  getProjectDetails,
  projectData,
} from "../../../../reducer/projectSlice";
import moment from "moment";
import Tags from "../../../../Components/Tags/Tags";
import Video from "../../../../Components/Popup/Video/Video";
import Button from "../../../../Components/Button/Button";

const About = () => {
  const dispatch = useDispatch();

  const { projectId } = useParams();

  const { projectDetails } = useSelector(projectData);

  useEffect(() => {
    dispatch(getProjectDetails(projectId));
  }, []);

  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      {showPopup && (
        <Video
          videoSrc={projectDetails?.video}
          onClose={() => setShowPopup(false)}
        />
      )}
      <div className={styles.aboutProjectWrapper}>
        <div className={styles.aboutProjectLeft}>
          <AboutSlider images={projectDetails?.media} />
          <div className={styles.upperSec}>
            <Tags
              green
              className={styles.tagsTags}
              customIcon={[]}
              text={projectDetails?.status}
            />
            {/* <div className={styles.starRating}>{star} 4.9</div> */}
          </div>
          <div className={styles.headRating}>
            <h3>{projectDetails?.name}</h3>
            {/* <div className={styles.tag}>
              <img src="/assets/svg/orangestart.svg" alt="" />
              4.9
            </div> */}
          </div>
          <div className={styles.locationDetails}>
            <div className={styles.box}>
              <img src="/assets/svg/location.svg" alt="" />
              {projectDetails?.address}
            </div>
            <div className={styles.box}>
              <img src="/assets/svg/calender.svg" alt="" />
              Updated {moment(projectDetails?.updatedAt).fromNow()}
            </div>
          </div>
          <p className={styles.contentText}>{projectDetails?.description}</p>
          {/* <h5>Project Classifications</h5>
          <div className={styles.projectDetailWrap}>
            <div className={styles.projectTag}>
              <img src="/assets/svg/pricetag.svg" alt="" />
              700
            </div>
            <div className={styles.projectTag}>
              <img src="/assets/svg/areatag.svg" alt="" />
              1892 Sqarefoot
            </div>
            <div className={styles.projectTag}>
              <img src="/assets/svg/residency.svg" alt="" />
              Residency
            </div>
          </div>

          <h5>Features</h5>
          <div className={styles.featuresWrap}>
            <p className={styles.contentText}>Near IT Industrial Area</p>
            <p className={styles.contentText}>Near IT Industrial Area</p>
            <p className={styles.contentText}>Near IT Industrial Area</p>
            <p className={styles.contentText}>Near IT Industrial Area</p>
            <p className={styles.contentText}>Near IT Industrial Area</p>
            <p className={styles.contentText}>Near IT Industrial Area</p>
            <p className={styles.contentText}>Near IT Industrial Area</p>
            <p className={styles.contentText}>Near IT Industrial Area</p>
          </div> */}
        </div>
        <div className={styles.aboutProjectRight}>
          <div
            className={styles.downloadbox}
            onClick={() => window.open(projectDetails?.brochure, "_blank")}
          >
            <img src="/assets/svg/brdownload.svg" alt="" />
            <div className={styles.group}>
              <p>For Detailed Information</p>
              <h4>Download Broucher</h4>
            </div>
          </div>
          <div
            className={styles.downloadbox}
            onClick={() => setShowPopup(true)}
          >
            <img src="/assets/svg/golf.svg" alt="" />
            <div className={styles.group}>
              <p>For 360 view of property</p>
              <h4>View Drone Shot</h4>
            </div>
          </div>

          {/* <button className={styles.addagentButton}>
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.50065 2.83337C8.77679 2.83337 9.00065 3.05723 9.00065 3.33337V7.50004H13.1673C13.4435 7.50004 13.6673 7.7239 13.6673 8.00004C13.6673 8.27618 13.4435 8.50004 13.1673 8.50004H9.00065V12.6667C9.00065 12.9429 8.77679 13.1667 8.50065 13.1667C8.22451 13.1667 8.00065 12.9429 8.00065 12.6667V8.50004H3.83398C3.55784 8.50004 3.33398 8.27618 3.33398 8.00004C3.33398 7.7239 3.55784 7.50004 3.83398 7.50004H8.00065V3.33337C8.00065 3.05723 8.22451 2.83337 8.50065 2.83337Z"
                fill="#0E77D3"
              />
            </svg>
            Assign Agent
          </button> */}

          {/* <div className={styles.mapBox}>
            <h2>Map Location</h2>
            <img src="/assets/img/map.png" alt="" />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default About;
