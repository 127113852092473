// Confirmation.js
import React from 'react';
import './Confirmation.css'; // Import the CSS file for styling

const Confirmation = ({ message, onConfirm, onCancel, id }) => {
  return (
    <div className="popup">
      <div className="popup-content newpop-up-content">
        <p>{message}</p>
        <div className="buttons">
          <button onClick={() => onConfirm(id)}>Yes</button>
          <button onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
