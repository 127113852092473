import React from 'react'
import styles from './Livechat.module.scss'
import CommonHead from '../../../Components/CommonHead/CommonHead'
import { Flexbox } from '../../../Components/TagsComponent/TagsComponent'
import ChatHead from './ChatComponets/ChatHead'
import ChatBubble from './ChatComponets/ChatBubble'

const CusLiveChat = () => {

    const search = [
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.66667 2.5C4.36548 2.5 2.5 4.36548 2.5 6.66667C2.5 8.96785 4.36548 10.8333 6.66667 10.8333C8.96785 10.8333 10.8333 8.96785 10.8333 6.66667C10.8333 4.36548 8.96785 2.5 6.66667 2.5ZM1.5 6.66667C1.5 3.8132 3.8132 1.5 6.66667 1.5C9.52014 1.5 11.8333 3.8132 11.8333 6.66667C11.8333 7.91333 11.3918 9.05686 10.6566 9.94942L14.3536 13.6465C14.5488 13.8417 14.5488 14.1583 14.3535 14.3536C14.1583 14.5488 13.8417 14.5488 13.6464 14.3535L9.94949 10.6565C9.05692 11.3918 7.91336 11.8333 6.66667 11.8333C3.8132 11.8333 1.5 9.52014 1.5 6.66667Z" fill="black" />
        </svg>
    ]

    const send = [
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="6" fill="#3397EF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9429 11.0513C11.9521 11.0555 11.9613 11.0596 11.9706 11.0638L28.7155 18.5989C28.9488 18.7039 29.1681 18.8026 29.3365 18.8973C29.4955 18.9868 29.7567 19.1492 29.9034 19.446C30.0761 19.7952 30.0761 20.2049 29.9034 20.554C29.7567 20.8508 29.4955 21.0132 29.3365 21.1027C29.1681 21.1974 28.9487 21.2961 28.7154 21.4011L11.9487 28.9461C11.7093 29.0539 11.4854 29.1547 11.2996 29.219C11.126 29.2792 10.8258 29.3694 10.5022 29.2791C10.1235 29.1734 9.81661 28.8957 9.67371 28.5294C9.5516 28.2164 9.61146 27.9088 9.65401 27.73C9.69954 27.5388 9.77751 27.3059 9.86089 27.057L12.2055 20.0534L12.2107 20.0376L12.2055 20.0218L9.86346 12.9655C9.86026 12.9559 9.85707 12.9463 9.85388 12.9367C9.77141 12.6883 9.69424 12.4559 9.64931 12.2649C9.60727 12.0862 9.54844 11.7791 9.67089 11.4669C9.81428 11.1013 10.1212 10.8243 10.4995 10.7191C10.8226 10.6292 11.1221 10.7191 11.2955 10.7791C11.4809 10.8434 11.7042 10.9439 11.9429 11.0513ZM11.2513 12.385C11.2626 12.419 11.2745 12.455 11.2871 12.493L13.5298 19.25H18.5013C18.9155 19.25 19.2513 19.5858 19.2513 20C19.2513 20.4142 18.9155 20.75 18.5013 20.75H13.5541L11.293 27.5043C11.2802 27.5424 11.2681 27.5785 11.2568 27.6125C11.2896 27.5978 11.3242 27.5823 11.3609 27.5658L28.0724 20.0456C28.108 20.0295 28.1417 20.0144 28.1735 20C28.1417 19.9856 28.108 19.9705 28.0724 19.9544L11.3551 12.4317C11.3185 12.4152 11.284 12.3997 11.2513 12.385Z" fill="white" />
        </svg>
    ]

    return (
        <div className={styles.chatWrap}>

            <CommonHead noMessage={true} noButton={true} customMsg={'Unread Messages -'} Heading={'Messages'} ProjectCount={0} buttonText={'New Message'} />

            <div className={styles.chatWrapper}>

                <div className={styles.chatLeft}>

                    <div className={styles.searchWrapper}>
                        <input type="text" placeholder='Search' />
                        <span>{search}</span>
                    </div>

                    <Flexbox alignStart wrap>
                        {Array(8).fill().map((_, index) => (
                            <ChatHead key={index} active={index === 0 ? styles.active : ''} />
                        ))}
                    </Flexbox>


                </div>

                <div className={styles.chatScreen}>

                    <div className={styles.user}>
                        <img src="/assets/img/user2.png" alt="" />
                        <p className={styles.userName}>Nimisha Shrivastava</p>
                    </div>

                    <div className={styles.chatArea}>

                        <ChatBubble>
                            Hi !
                        </ChatBubble>

                        <ChatBubble>
                            Hi !
                        </ChatBubble>

                        <ChatBubble>
                            Hi !
                        </ChatBubble>

                        <ChatBubble isTime={'12:00 PM'}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        </ChatBubble>

                        <ChatBubble sender>
                            Sed ut perspiciatis unde omnis iste natus error si
                        </ChatBubble>

                        <ChatBubble sender>
                            Sed ut perspiciatis unde omnis iste natus error si
                        </ChatBubble>

                        <ChatBubble isTime={'12:00 PM'} sender>
                            Quis autem vel eum iure reprehenderit qui in ea voluptate velit 
                        </ChatBubble>

                    </div>

                    <div className={styles.msgSender}>
                        <input type="text" placeholder='Write a message..'/>
                        <span>{send}</span>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default CusLiveChat