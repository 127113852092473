import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { environment } from "../environment/environments";
import RoleService from "../helpers/RoleServicec";
const apiRoute = "/api/";
let token = "";

const setTokenValues = () => {
  token = `user ${localStorage.getItem("token")}`;
}

export const addUser = createAsyncThunk(
  "admin/addUser",
  async ({ roleName, obj }, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        apiRoute +
        `${roleName}/addUser`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
          },
          body: JSON.stringify(obj)
        }
      );
      if (response.statusText === 'Unauthorized') {
        localStorage.clear();
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
      }
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateSpecificUser = createAsyncThunk(
  "admin/updateSpecificUser",
  async ({ userId, obj }, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        apiRoute +
        `${userId}/updateSpecificUser`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
          },
          body: JSON.stringify(obj)
        }
      );
      if (response.statusText === 'Unauthorized') {
        localStorage.clear();
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
      }
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "admin/deleteUser",
  async (userId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        apiRoute +
        `${userId}/deleteUser`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
          }
        }
      );
      if (response.statusText === 'Unauthorized') {
        localStorage.clear();
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
      }
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const login = createAsyncThunk(
  "login/login",
  async (obj, thunkAPI) => {
    try {
      const response = await fetch(
        environment.baseURL +
        apiRoute +
        "/login",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        localStorage.setItem("userId", data.Data.id);
        // localStorage.setItem("role", data.Data.role);
        RoleService.saveRoleName(data.Data.role);
        localStorage.setItem("profileImg", data.Data.profileImg);
        localStorage.setItem("name", data.Data.name);
        localStorage.setItem("token", data.Token);

        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "login/forgotPassword",
  async (email, thunkAPI) => {
    try {
      const response = await fetch(
        environment.baseURL +
        apiRoute +
        "forgotPassword",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email })
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const changeForgotPassword = createAsyncThunk(
  "login/changeForgotPassword",
  async ({ token, password }, thunkAPI) => {
    try {
      const response = await fetch(
        environment.baseURL +
        apiRoute +
        "changeForgotPassword",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({ password })
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const changePassword = createAsyncThunk(
  "login/changePassword",
  async (obj, thunkAPI) => {
    try {
      const response = await fetch(
        environment.baseURL +
        apiRoute +
        "changePassword",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `user ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(obj),
        }
      );
      if (response.statusText === 'Unauthorized') {
        localStorage.clear();
        window.location.reload();
      }
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const googleSignIn = createAsyncThunk(
  "login/googleSignIn",
  async (obj, thunkAPI) => {
    try {
      const response = await fetch(
        environment.baseURL +
        apiRoute +
        "googleSignIn",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj)
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        const pid = data?.Data?.programs.map((i) => i.id).join("|")
        localStorage.setItem("userId", data.Data.id);
        // localStorage.setItem("role", data.Data.role);
        RoleService.saveRoleName(data.Data.role);
        localStorage.setItem("orgId", data.Data.organizationId);
        localStorage.setItem("pid", pid);
        localStorage.setItem("logo", data.Data.organization?.logoUrl);
        localStorage.setItem("profileImg", data.Data.profileImg);
        localStorage.setItem("name", data.Data.firstName + " " + data.Data.lastName);
        localStorage.setItem("token", data.Token);
        if (!data.Data?.isPassChanged) localStorage.setItem("pc", data.Data?.isPassChanged)
        // window.location.reload();
        return data?.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAllUsers = createAsyncThunk(
  "login/getAllUsers",
  async ({ roleName, search, index, sortedBy, sortedOrder }, thunkAPI) => {
    try {
      setTokenValues();
      const title = search ?? "";
      const page = index ?? "";
      const sort = sortedBy ?? "";
      const order = sortedOrder ?? "";

      const response = await fetch(
        environment.baseURL +
        apiRoute +
        `${roleName}/getAllUsers?title=${title}&page=${page}&sort=${sort}&order=${order}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
          },
        }
      );

      if (response.statusText === 'Unauthorized') {
        localStorage.clear();
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
      }
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getTopUsers = createAsyncThunk(
  "login/getTopUsers",
  async ({ roleName, search, }, thunkAPI) => {
    try {
      setTokenValues();
      const title = search ?? "";

      const response = await fetch(
        environment.baseURL +
        apiRoute +
        `${roleName}/getTopUsers?title=${title}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
          },
        }
      );

      if (response.statusText === 'Unauthorized') {
        localStorage.clear();
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
      }
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "login/getUserDetails",
  async (userId, thunkAPI) => {
    try {
      setTokenValues();

      const response = await fetch(
        environment.baseURL +
        apiRoute +
        `${userId}/getUserDetails`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
          },
        }
      );

      if (response.statusText === 'Unauthorized') {
        localStorage.clear();
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
      }
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);


export const userSlice = createSlice({
  name: "user",
  initialState: {
    isFetching: false,
    forgettingPass: false,
    isLoginFetching: false,
    isSuccess: false,
    isPassChanged: false,
    forgotPassSuccess: false,
    isError: false,
    isUserAdded: false,
    isUserUpdated: false,
    isUserDeleted: false,
    errorMessage: "",
    route: "",
    allUsers: null,
    topUsers: null,
    userData: null,
    token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
    profileImg: localStorage.getItem('profileImg') ? localStorage.getItem('profileImg') : null
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isPassChanged = false;
      state.forgotPassSuccess = false;
      state.isFetching = false;
      state.forgettingPass = false;
      state.isLoginFetching = false;
      state.isUserAdded = false;
      state.isUserUpdated = false;
      state.isUserDeleted = false;
      state.allUsers = null;
      state.userData = null;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoginFetching = true;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.isLoginFetching = false;
      state.isSuccess = true;
      state.route = payload?.role
      state.profileImg = payload?.profileImg;
    });
    builder.addCase(login.rejected, (state, { payload }) => {
      state.isLoginFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(forgotPassword.pending, (state) => {
      state.forgettingPass = true;
    });
    builder.addCase(forgotPassword.fulfilled, (state, { payload }) => {
      state.forgettingPass = false;
      state.forgotPassSuccess = true;
    });
    builder.addCase(forgotPassword.rejected, (state, { payload }) => {
      state.forgettingPass = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(changeForgotPassword.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(changeForgotPassword.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isPassChanged = true;
    });
    builder.addCase(changeForgotPassword.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(changePassword.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(changePassword.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
    });
    builder.addCase(changePassword.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(googleSignIn.pending, (state) => {
      state.isLoaderTrue = true;
    });
    builder.addCase(googleSignIn.fulfilled, (state, { payload }) => {
      state.isLoaderTrue = false;
      state.isSuccess = true;
      state.route = payload?.role?.roleName
      state.logo = payload?.organization?.logoUrl;
      state.profileImg = payload?.userData?.profileImg;
    });
    builder.addCase(googleSignIn.rejected, (state, { payload }) => {
      state.isLoaderTrue = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(getAllUsers.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getAllUsers.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.allUsers = payload;
    });
    builder.addCase(getAllUsers.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(getTopUsers.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getTopUsers.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.topUsers = payload;
    });
    builder.addCase(getTopUsers.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(addUser.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(addUser.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isUserAdded = true;
    });
    builder.addCase(addUser.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(updateSpecificUser.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(updateSpecificUser.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isUserUpdated = true;
    });
    builder.addCase(updateSpecificUser.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(deleteUser.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isUserDeleted = true;
    });
    builder.addCase(deleteUser.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
    builder.addCase(getUserDetails.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getUserDetails.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.userData = payload;
    });
    builder.addCase(getUserDetails.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message ?? "Something went wrong!";
    });
  },
});


export const { clearState } = userSlice.actions;

export const userSelector = (state) => state.user;