import React, { useEffect, useState } from "react";
import styles from "./Payment.module.scss";
import CommonHead from "../../../Components/CommonHead/CommonHead";
import { useNavigate } from "react-router-dom";
import PaymentTab from "./paymentTable";
import Tags from "../../../Components/Tags/Tags";
import Button from "../../../Components/Button/Button";
import { getAllReceipts, projectData } from "../../../reducer/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Tablepagination from "../../../Components/pagination/Tablepagination";
import Reciptpop from "../../../Components/Popup/Reciptpop/Reciptpop";
import PageLoader from "../../../Components/PageLoader/PageLoader";
const Payment = () => {
  const searchIcon = [
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.25 3.3125C5.24695 3.3125 2.8125 5.74695 2.8125 8.75C2.8125 11.753 5.24695 14.1875 8.25 14.1875C9.72046 14.1875 11.0546 13.6038 12.0333 12.6555C12.0502 12.6325 12.069 12.6105 12.0898 12.5898C12.1105 12.569 12.1325 12.5502 12.1555 12.5333C13.1038 11.5546 13.6875 10.2205 13.6875 8.75C13.6875 5.74695 11.253 3.3125 8.25 3.3125ZM13.2712 12.9757C14.233 11.834 14.8125 10.3597 14.8125 8.75C14.8125 5.12563 11.8744 2.1875 8.25 2.1875C4.62563 2.1875 1.6875 5.12563 1.6875 8.75C1.6875 12.3744 4.62563 15.3125 8.25 15.3125C9.85967 15.3125 11.334 14.733 12.4757 13.7712L15.3523 16.6477C15.5719 16.8674 15.9281 16.8674 16.1477 16.6477C16.3674 16.4281 16.3674 16.0719 16.1477 15.8523L13.2712 12.9757Z"
        fill="#737373"
      />
    </svg>,
  ];

  const router = useNavigate();

  const dispatch = useDispatch();

  //pagination states
  const [index, setIndex] = useState(1);

  //filter states
  const [filters, setFilters] = useState({
    query: {},
    searchText: "",
  });

  //popup
  const [Show, setShow] = useState(false);
  const [bookingId, setBookingId] = useState(null);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setFilters({ searchText: event.target.value });
    }
  };

  const { isFetching, allReceipts } = useSelector(projectData);

  useEffect(() => {
    dispatch(
      getAllReceipts({
        index,
        query: {
          ...filters?.query,
        },
        search: filters?.searchText,
      })
    );
  }, [index, filters]);

  return (
    <>
    {isFetching && <PageLoader/>}
      <div className={styles.paymentWrap}>
        {Show && (
          <Reciptpop
            show={true}
            handleClose={() => setShow(false)}
            bookingId={bookingId}
          />
        )}
        <CommonHead
          noButton
          Heading={"Payment History"}
          customMsg={"Total receipts -"}
          ProjectCount={allReceipts?.totalCount}
          noSideButton={true}
        />

        <div className={`${styles.propertyTable} mt20`}>
          <div className={styles.countBar}>
            <p>
              Total Invoices <b>{allReceipts?.totalCount}</b>
            </p>

            <div className={styles.buttons}>
              <div className={styles.wrap}>
                <input
                  type="text"
                  placeholder="Search Here..."
                  onKeyDown={handleKeyPress}
                />
                {searchIcon}
              </div>
            </div>
          </div>

          <div className={styles.tableWrap}>
            <table className={styles.bookingTable}>
              <thead>
                <tr>
                  <th>Invoice No.</th>
                  <th>Issued Date</th>
                  <th>Payment Mode</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allReceipts?.receipt?.map((item, index) => (
                  <tr key={item?.id}>
                    <td>
                      <div className={styles.tabData}>
                        {"#INV" + item?.receiptNo}
                      </div>
                    </td>
                    <td>
                      <div className={styles.tabData}>
                        {moment(item?.createdAt).format("DD MMM YYYY")}
                      </div>
                    </td>
                    <td className={styles.greenText}>
                      <div className={styles.tabData}> {item?.paymentMode}</div>
                    </td>
                    <td>
                      <div className={styles.tabData}>
                        <span className={styles.textGreen}>
                          ₹ {item?.amount}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className={styles.tabData}>
                        <img
                          className="pointer"
                          src="/assets/svg/action1.svg"
                          alt=""
                          onClick={() => {
                            setBookingId(item?.bookingId);
                            setShow(true);
                          }}
                        />
                        {/* <img src="/assets/svg/action2.svg" alt="" /> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Tablepagination
          itemsPerPage={10}
          totalItems={allReceipts?.totalCount}
          setIndex={setIndex}
          itemName={"Biilings"}
        />
      </div>
    </>
  );
};

export default Payment;
