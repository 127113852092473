import React, { useEffect, useState } from "react";
import styles from "./Project.module.scss";
import About from "../../Admin/Projects/ProjectComponets/About";
import Properties from "../../Admin/Projects/ProjectComponets/Properties";
import OwnerShip from "./ProjectComponets/OwnerShip";
import CommonHead from "../../../Components/CommonHead/CommonHead";
import RoleService from "../../../helpers/RoleServicec";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { clearState, projectData } from "../../../reducer/projectSlice";
import ErrorToast from "../../../Components/Toast/ErrorToast";
import SuccessToast from "../../../Components/Toast/SuccessToast";
import PageLoader from "../../../Components/PageLoader/PageLoader";
import Receipts from "../../Admin/Projects/ProjectComponets/Receipts";
import StatusUpdate from "../../Admin/Projects/ProjectComponets/StatusUpdate";

const role = RoleService.getRoleName();

const AgentProject = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(1);
  const router = useNavigate();
  const { projectId } = useParams();

  const { isFetching, isSuccess, projectDetails, isError, errorMessage } =
    useSelector(projectData);

  //Toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      setIsShowError(true);
      setErrMessage(errorMessage);
    }
  }, [isError]);

  return (
    <>
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <SuccessToast
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessage}
      />
      <div className={styles.projectOverview}>
        <CommonHead
          Heading={"Project Overview"}
          customMsg={"Properties -"}
          ProjectCount={"Property Name 1"}
          buttonText={"Filter"}
          hasViewBtn={false}
          noSideButton={true}
        />

        <ul className={styles.projectTabs}>
          <li
            className={active === 1 && styles.active}
            onClick={() => setActive(1)}
          >
            About Project
          </li>
          <li
            className={active === 2 && styles.active}
            onClick={() => setActive(2)}
          >
            Properties
          </li>
          <li
            className={active === 3 && styles.active}
            onClick={() => setActive(3)}
          >
            Details of Ownership
          </li>
          <li
            className={active === 7 && styles.active}
            onClick={() => setActive(4)}
          >
            Status Update
          </li>
          <li
            className={active === 5 && styles.active}
            onClick={() => setActive(5)}
          >
            Billing
          </li>
        </ul>
        <div className={styles.projectPages}>
          {(active === 1 && <About />) ||
            (active === 2 && <Properties active={active} />) ||
            (active === 3 && <OwnerShip />) ||
            (active === 4 && <StatusUpdate />) ||
            (active === 5 && <Receipts active={active} />)}
        </div>
      </div>
    </>
  );
};

export default AgentProject;
