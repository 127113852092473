import React, { useEffect, useState } from "react";
import styles from "./Customer.module.scss";
import AgentCard from "../../../Components/AgentCard/AgentCard";
import CommonHead from "../../../Components/CommonHead/CommonHead";
import Filter from "../../../Components/Filter/Filter";
import { Flexbox } from "../../../Components/TagsComponent/TagsComponent";
import CustomerCard from "../../../Components/CustomerCard/CustomerCard";
import Tablepagination from "../../../Components/pagination/Tablepagination";
import {
  clearState,
  getAllUsers,
  userSelector,
} from "../../../reducer/userSlice";
import PageLoader from "../../../Components/PageLoader/PageLoader";
import ErrorToast from "../../../Components/Toast/ErrorToast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Customer = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  //pagination states
  const [index, setIndex] = useState(1);

  //filter states
  const [filters, setFilters] = useState({ searchText: "" });

  const { isFetching, isError, errorMessage, allUsers } =
    useSelector(userSelector);

  //Toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      setIsShowError(true);
      setErrMessage(errorMessage);
    }
  }, [isError]);

  useEffect(() => {
    clearState();

    dispatch(
      getAllUsers({
        roleName: "customer",
        search: filters?.searchText,
        index,
      })
    );
  }, [index, filters]);

  return (
    <>
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <div className={styles.customerWrap}>
        <CommonHead
          Heading={"Customers"}
          ProjectCount={allUsers?.totalCount}
          buttonText={"Add Customer"}
          noSideButton={true}
        />
        <Filter className={styles.agentFilter} onFilterChange={setFilters} />

        <Flexbox alignStart spaceBW className={styles.cardArrangement}>
          {allUsers?.users?.map((user, index) => (
            <CustomerCard
              onClick={() => navigate(`${user.id}`)}
              className={styles.custCard}
              key={user.id}
              customerName={user.name}
              img={user.profileImg ?? "/assets/img/user1.png"}
              address={user.address ?? "N/A"}
              propertyCount={user?.totalProperties}
            />
          ))}
        </Flexbox>
        <Tablepagination
          itemsPerPage={10}
          totalItems={allUsers?.totalCount}
          setIndex={setIndex}
          itemName={"Customers"}
        />
      </div>
    </>
  );
};

export default Customer;
