import './App.scss';

// admin
import Dashboard from './Pages/Admin/Dashboard/Dashboard';
import Login from './Pages/Login/Login';
import ForgetPass from './Pages/Login/ForgetPass';
import SideMenu from './Layouts/SideMenu/SideMenu';
import Project from './Pages/Admin/Projects/Project';

import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from './Layouts/Header/Header';
import Agents from './Pages/Admin/Agents/Agents';
// import SearchBox from './Components/SearchBox/SearchBox';
import Customer from './Pages/Admin/Customers/Customer';
import LiveChat from './Pages/LiveChat/LiveChat';
import AddProject from './Pages/Admin/AddProjects/AddProject';
import AddAgent from './Pages/Admin/AddAgent/AddAgent';
import Bookings from './Pages/Admin/Bookings/Bookings';
import ProjectPrev from './Pages/Admin/Projects/ProjectPrev';
import NewMessage from './Components/Popup/NewMessage/NewMessage';
import { AdminPrivateRoute } from './helpers/AdminPrivateRoute';
import { AgentPrivateRoute } from './helpers/AgentPrivateRoute';
import { CustomerPrivateRoute } from './helpers/CustomerPrivateRoute';
import { NoRouteFound } from './helpers/NoRouteFound';
import PageNotFound from './helpers/PageNotFound';
import AddProperty from './Pages/Admin/AddProperty/AddProperty';
import AgentProject from './Pages/Agent/Projects/AgentProject';
import Documentation from './Pages/Agent/Documentation/Documentation';
import AgentCustomer from './Pages/Agent/Customers/AgentCustomer';
import AgentLiveChat from './Pages/Agent/LiveChat/AgentLiveChat';
import Payment from './Pages/Customer/Payment/Payment';
import NewsUpdates from './Pages/Customer/NewsUpdates/NewsUpdates';
import CusProjectPrev from './Pages/Customer/Projects/ProjectPrev';
import CusProject from './Pages/Customer/Projects/Project';
import CusBookings from './Pages/Customer/Bookings/Bookings';
import CusLiveChat from './Pages/Customer/LiveChat/LiveChat';
import OwnerShip from './Pages/Agent/Projects/ProjectComponets/OwnerShip';
import Form2 from './Pages/Agent/form/Form2';
import Addreceipt from './Pages/Agent/addreceipt/Addreceipt';
import Reciptpop from './Components/Popup/Reciptpop/Reciptpop';
import Reciptpop2 from './Components/Popup/Reciptpop/Reciptpop2';
import Thanku from './Pages/Login/Thanku';
import UploadPopup from './Components/Popup/uploadBoxPopup/UploadPopup';

function App() {

  const { pathname } = useLocation();
  const [ham, setHam] = useState();
  const [searchMake, setSearchMake] = useState(false)

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.keyCode === 27) {
        setSearchMake(false);
      }
    };

    document.addEventListener('keydown', handleEscKey);

    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, []);

  const [popup, showPopup] = useState(false);

  return (
    <>
      <NewMessage popup={popup} showPopup={showPopup} />
      {(pathname !== '/login' && pathname !== '/forgetPass') && <SideMenu setHam={setHam} ham={ham} />}
      <div className={`appWrapper ${(pathname !== '/login' && pathname !== '/forgetPass') && 'afterLogin'} ${ham && 'smallMenu'}`}>
        {(pathname !== '/login' && pathname !== '/forgetPass') && <Header searchMake={searchMake} setSearchMake={setSearchMake} />}
        <div className={`workingArea ${(pathname !== '/login' && pathname !== '/forgetPass') && 'afterLogin'}`}>
          {/* {searchMake && <SearchBox />} */}
          <Routes>
            <Route exact path='/login' element={<Login />} />
            <Route exact path='/forgetPass' element={<ForgetPass />} />
            <Route exact path='/thanku' element={<Thanku />} />
            <Route path="*" element={<NoRouteFound />} />

            <Route exact path="/admin" element={<AdminPrivateRoute />} >
              <Route exact path='' element={<Dashboard />} />
              <Route path="*" exact element={<PageNotFound />} />
              <Route exact path='projects' element={<ProjectPrev />} />
              <Route exact path='projectPrev/:projectId' element={<Project/>} />
              <Route exact path='property/:propertyId' element={<CusProject />}/>
              <Route exact path='bookings' element={<Bookings />} />
              <Route exact path='addprojects' element={<AddProject />} />
              <Route exact path='addproperty/:projectId' element={<AddProperty />} />
              <Route exact path='editproperty/:propertyId' element={<AddProperty />} />
              <Route exact path='addagent' element={<AddAgent />} />
              <Route exact path='editAgent/:userId' element={<AddAgent isEdit={true}/>} />
              <Route exact path='agents' element={<Agents />} />
              <Route exact path='customers' element={<Customer />} />
              <Route exact path='customers/:customerId' element={<AgentCustomer />}/>
              <Route exact path='receiptpopup' element={<Reciptpop />}/>
              <Route exact path='receiptpopup2' element={<Reciptpop2 />}/>
              <Route exact path='messages' element={<LiveChat showPopup={showPopup} />} />
              <Route exact path='ownerShip/:bookingId' element={<OwnerShip />}/>

            </Route>

            <Route exact path="/agent" element={<AgentPrivateRoute />} >
              <Route path="*" exact element={<PageNotFound />} />
              <Route exact path='' element={<Dashboard />}/>
              <Route exact path='projects' element={<ProjectPrev />}/>
              <Route exact path='projectPrev/:projectId' element={<AgentProject />}/>
              <Route exact path='property/:propertyId' element={<CusProject />}/>
              <Route exact path='bookings' element={<Documentation />}/>
              <Route exact path='addBooking' element={<Form2 />}/>
              <Route exact path='addBooking/:customerId' element={<Form2 />}/>
              <Route exact path='customers' element={<Customer  />}/>
              <Route exact path='messages' element={<AgentLiveChat />}/>
              <Route exact path='customers/:customerId' element={<AgentCustomer />}/>
              <Route exact path='addPayment/:bookingId' element={<Addreceipt />}/>
              <Route exact path='ownerShip/:bookingId' element={<OwnerShip />}/>

            </Route>

            <Route exact path="/customer" element={<CustomerPrivateRoute />} >
              <Route exact path='' element={<Dashboard />}/>
              <Route exact path='properties' element={<CusProjectPrev />}/>
              <Route exact path='property/:propertyId' element={<CusProject />}/>
              <Route exact path='documents' element={<OwnerShip />}/>
              <Route exact path='bookings' element={<CusBookings />}/>
              <Route exact path='payment' element={<Payment />}/>
              <Route exact path='news' element={<NewsUpdates />}/>
              <Route exact path='messages' element={<CusLiveChat />}/>
              {/* <Route exact path='popup' element={<UploadPopup />}/> */}

              <Route exact path='messages' element={<CusLiveChat />}/>
            </Route>

          </Routes>
        </div>
      </div>
    </>
  );
}

export default App;
