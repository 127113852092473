import React, { useState } from "react";
import "./Video.css"; // Import CSS file for styling

function Video({ videoSrc, onClose }) {
  const close = [
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4.90234"
        y="5.79785"
        width="0.7"
        height="14"
        transform="rotate(-45 4.90234 5.79785)"
        fill="#171717"
      />
      <rect
        width="0.7"
        height="14"
        transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 15.2988 5.79785)"
        fill="#171717"
      />
    </svg>,
  ];
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  return (
    <div className={`video-popup ${isVisible ? "visible" : ""}`}>
      <div className="video-popup-content">
      <span onClick={handleClose}>{close}</span>
        <video controls autoPlay>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}

export default Video;
