const RoleService = {
    roleMappings: {
      admin: '49e37c8d-d7bc-4c2e-af5e-56d03a1e2070',
      agent: '6f09f7e7-96d3-49e6-8a8e-9a6e13e45a4d',
      customer: '9f92f570-5cf7-47ac-bcf6-ee4a08c743d1'
    },
  
    // Save role name as ID in localStorage
    saveRoleName: (roleName) => {
      if (RoleService.roleMappings.hasOwnProperty(roleName)) {
        const roleId = RoleService.roleMappings[roleName];
        localStorage.setItem('roleId', roleId);
      } else {
        console.error(`Role '${roleName}' not found in mappings.`);
      }
    },
  
    // Fetch role name based on ID from localStorage
    getRoleName: () => {
      const roleId = localStorage.getItem('roleId');
      const roleName = Object.keys(RoleService.roleMappings).find(key => RoleService.roleMappings[key] === roleId);
      return roleName || null; 
    }
  };

export default RoleService;