import React from "react";
import input from "./Input.module.scss";

const Input = ({
  id,
  type,
  name,
  label,
  placeholder,
  checkbox,
  className,
  onChange,
  onKeyDown,
  value
}) => {
  return (
    <div
      className={`${input.formGroup} ${
        checkbox && input.checbox
      } ${className} ${type === "checkbox" && input.fontPoppins}`}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <input
        type={type ? type : "text"}
        id={id}
        name={name}
        placeholder={placeholder ? placeholder : label}
        onKeyDown={onKeyDown}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default Input;
