import React, { useEffect, useState } from "react";
import styles from "../Project.module.scss";
import AboutSlider from "../../../../Components/Slider/AboutSlider";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getPropertyDetails,
  projectData,
} from "../../../../reducer/projectSlice";
import Video from "../../../../Components/Popup/Video/Video";
import moment from "moment";
import Tags from "../../../../Components/Tags/Tags";
import RoleService from "../../../../helpers/RoleServicec";
import Button from "../../../../Components/Button/Button";

import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import UploadPopup from "../../../../Components/Popup/uploadBoxPopup/UploadPopup";

const About = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const role = RoleService.getRoleName();

  const options = [
    { value: "receiptUrl", label: "Reciept" },
    { value: "bookingFormUrl", label: "Booking form" },
    {
      value: "bookingConfirmationLetterUrl",
      label: "Booking Confirmation Letter",
    },
    { value: "letterOfUndertakingUrl", label: "Letter of Undertaking" },
    { value: "gratitudeLetterUrl", label: "Gratitude Letter" },
  ];

  const [selectedOption, setSelectedOption] = useState(null);
  const [uploadPopup, setuploadPopup] = useState(false);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setuploadPopup(true);
  };

  const { propertyDetails } = useSelector(projectData);

  useEffect(() => {
    dispatch(getPropertyDetails(propertyId));
  }, []);

  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      {showPopup && (
        <Video
          videoSrc={propertyDetails?.video}
          onClose={() => setShowPopup(false)}
        />
      )}

      {uploadPopup && (
        <UploadPopup
          propertyId={propertyId}
          uploadPopupFileName={selectedOption.label}
          selectedType={selectedOption.value}
          handleClose={() => setuploadPopup(false)}
        />
      )}

      <div className={styles.aboutProjectWrapper}>
        <div className={styles.aboutProjectLeft}>
          <AboutSlider images={propertyDetails?.media} />
          <div className={`${styles.upperSec} mt20`}>
            <Tags
              green={propertyDetails?.type === "residency" && true}
              purple={propertyDetails?.type === "commercial" && true}
              red={propertyDetails?.type === "apartment" && true}
              className={styles.tagsTags}
              customIcon={[]}
              text={propertyDetails?.type}
            />
            {/* <div className={styles.starRating}>{star} 4.9</div> */}
          </div>
          <div className={styles.headRating}>
            <h3>{propertyDetails?.name}</h3>
            {/* <div className={styles.tag}>
              <img src="/assets/svg/orangestart.svg" alt="" />
              4.9
            </div> */}
          </div>
          <div className={styles.locationDetails}>
            <div className={styles.box}>
              <img src="/assets/svg/location.svg" alt="" />
              {propertyDetails?.address}
            </div>
            <div className={styles.box}>
              <img src="/assets/svg/calender.svg" alt="" />
              Updated {moment(propertyDetails?.updatedAt).fromNow()}
            </div>
          </div>
          <p className={styles.contentText}>{propertyDetails?.description}</p>
          <h5>Project Classifications</h5>
          <div className={styles.projectDetailWrap}>
            <div className={styles.projectTag}>
              <img src="/assets/svg/pricetag.svg" alt="" />
              {propertyDetails?.price}
            </div>
            <div className={styles.projectTag}>
              <img src="/assets/svg/areatag.svg" alt="" />
              {propertyDetails?.area} {propertyDetails?.areaType}
            </div>
            <div className={styles.projectTag}>
              <img src={`/assets/svg/residency.svg`} alt="" />
              {propertyDetails?.type}
            </div>
          </div>

          <h5>Features</h5>
          <div className={styles.featuresWrap}>
            {propertyDetails?.features?.map((feature) => (
              <p className={styles.contentText} key={feature?.id}>
                {feature?.featureName}
              </p>
            ))}
          </div>
        </div>

        <div className={styles.aboutProjectRight}>
          <div
            className={styles.downloadbox}
            onClick={() => window.open(propertyDetails?.brochure, "_blank")}
          >
            <img src="/assets/svg/brdownload.svg" alt="" />
            <div className={styles.group}>
              <p>For Detailed Information</p>
              <h4>Download Broucher</h4>
            </div>
          </div>
          <div
            className={styles.downloadbox}
            onClick={() => setShowPopup(true)}
          >
            <img src="/assets/svg/golf.svg" alt="" />
            <div className={styles.group}>
              <p>For 360 view of property</p>
              <h4>View Drone Shot</h4>
            </div>
          </div>

          {propertyDetails?.isBooked && (
            <div className={styles.mapBox}>
              <div className={styles.amountBox}>
                <span>Total Amount</span>
                <p>₹ {propertyDetails?.booking?.totalAmount}/-</p>
              </div>

              <div className={styles.amountBox}>
                <span>Amount Paid</span>
                <p>
                  ₹{" "}
                  {propertyDetails?.booking?.totalAmount -
                    propertyDetails?.booking?.dueAmount}{" "}
                  /-
                </p>
              </div>

              <div className={styles.amountBox}>
                <span>Due Amount</span>
                <p>₹ {propertyDetails?.booking?.dueAmount}/-</p>
              </div>
            </div>
          )}

          {role == "agent" && (
            <div className={`${styles.mapBox} ${styles.buttonWrap}`}>
              {/* <Button
              icon={"docs"}
              blueBorderBtn
              text={"Add Ownership Details"}
            /> */}
              <Button
                icon={"msg"}
                background={"var(--blue)"}
                blueButton
                text={"Add Payment"}
                onClick={() =>
                  navigate(`/agent/addPayment/${propertyDetails?.booking?.id}`)
                }
              />
              <Dropdown
                className={"dropdropDown"}
                options={options}
                onChange={handleSelect}
                value={selectedOption}
                placeholder="Add Ownership Details"
              />
            </div>
          )}

          {/* <div className={styles.mapBox}>
            <h2>Map Location</h2>
            <img src="/assets/img/map.png" alt="" />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default About;
