import tag from './Tags.module.scss'

export const Flexbox = ({ 
    className,
    children ,
    wrap,
    noWrap,
    alignCenter,
    justiCenter,
    column,
    row,
    colRev,
    rowRev,
    spaceBW,
    flexStart,
    flexEnd,
    alignStart,
    alignEnd,
    stretch,
    maxWidth,
    id,
    gap,
    rowGap
}) => {
    return (
        <div className={`
            ${className ? className : ''}
            ${tag.flexbox} 
            ${wrap ? tag.wrap : ''}
            ${noWrap ? tag.noWrap : ''}
            ${alignCenter ? tag.alignCenter : ''}
            ${justiCenter ? tag.justiCenter : ''}
            ${column ? tag.column : ''}
            ${row ? tag.row : ''}
            ${colRev ? tag.colRev : ''}
            ${rowRev ? tag.rowRev : ''}
            ${spaceBW ? tag.spaceBW : ''}
            ${flexStart ? tag.flexStart : ''}
            ${flexEnd ? tag.flexEnd : ''}
            ${alignStart ? tag.alignStart : ''}
            ${alignEnd ? tag.alignEnd : ''}
            ${stretch ? tag.stretch : ''}
        `} id={id} 
            style={{
            ...(gap && { gap: `${gap}px` }),
            ...(rowGap && { rowGap: `${rowGap}px` }),
            ...(maxWidth && { maxWidth: `${maxWidth}%`})
          }}
        >
            {children}
        </div>
    )
};