import React from 'react'
import styles from './bookings.module.scss'
import CommonHead from '../../../Components/CommonHead/CommonHead'
import Filter from '../../../Components/Filter/Filter'
import { useNavigate } from 'react-router-dom'
import Booking from './Booking'

const CusBookings = () => {

    const router = useNavigate()

    return (
        <div className={styles.bookingWrap}>
            <CommonHead Heading={'Bookings'} ProjectCount={21} buttonText={'Add Bookings'} onButtonClick={() => router('/addagent')} />
            <Filter className={styles.bookingFilter} />

            <div className={styles.tableWrap}>
                <table className={styles.bookingTable}>
                    <thead>
                        <tr>
                            <th>Sr.no</th>
                            <th>Customer Name</th>
                            <th>By Agent</th>
                            <th>Property Name</th>
                            <th>City</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Booking.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <div className={styles.tabData}>
                                        {index + 1}
                                    </div>
                                </td>
                                <td>
                                    <div className={styles.tabData}>
                                        <img src={`/assets/img/${item.custImg}.png`} alt="" />
                                        {item.custName}
                                    </div>
                                </td>
                                <td>
                                    <div className={styles.tabData}>
                                        <img src={`/assets/img/${item.agentImg}.png`} alt="" />
                                        {item.agent}
                                    </div>
                                </td>
                                <td>
                                    <div className={styles.tabData}>
                                        <img src={`/assets/img/${item.propertyImg}.png`} alt="" />
                                        {item.propertyName}
                                    </div>
                                </td>
                                <td>
                                    <div className={styles.tabData}>
                                        {item.city}
                                    </div>
                                </td>
                                <td>
                                    <div className={styles.tabData}>
                                        <img src="/assets/svg/action1.svg" alt="" />
                                        <img src="/assets/svg/action2.svg" alt="" />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>
        </div>
    )
}

export default CusBookings