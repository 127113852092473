
import Button from '../../../../Components/Button/Button'
import Booking from '../../Bookings/Booking'
import styles from '../Project.module.scss'


function Billing() {


  const searchIcon = [
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 3.3125C5.24695 3.3125 2.8125 5.74695 2.8125 8.75C2.8125 11.753 5.24695 14.1875 8.25 14.1875C9.72046 14.1875 11.0546 13.6038 12.0333 12.6555C12.0502 12.6325 12.069 12.6105 12.0898 12.5898C12.1105 12.569 12.1325 12.5502 12.1555 12.5333C13.1038 11.5546 13.6875 10.2205 13.6875 8.75C13.6875 5.74695 11.253 3.3125 8.25 3.3125ZM13.2712 12.9757C14.233 11.834 14.8125 10.3597 14.8125 8.75C14.8125 5.12563 11.8744 2.1875 8.25 2.1875C4.62563 2.1875 1.6875 5.12563 1.6875 8.75C1.6875 12.3744 4.62563 15.3125 8.25 15.3125C9.85967 15.3125 11.334 14.733 12.4757 13.7712L15.3523 16.6477C15.5719 16.8674 15.9281 16.8674 16.1477 16.6477C16.3674 16.4281 16.3674 16.0719 16.1477 15.8523L13.2712 12.9757Z" fill="#737373" />
    </svg>
  ]

  return (

    <div className={styles.propertyTable}>

<div className={styles.countBar}>
        <p>Total Invoices <b>52</b></p>

        <div className={styles.buttons}>
          <div className={styles.wrap}>
            <input type="text" placeholder='Search Here...' />
            {searchIcon}
          </div>
          <Button whiteButton text={'Filter'} icon={'filter-lines'} maxWidth={90} padding={10} height={'auto'} />
        </div>
      </div>

      <div className={styles.tableWrap}>
        <table className={styles.bookingTable}>
          <thead>
            <tr>
              <th>Invoice No.</th>
              <th>Customer Name</th>
              <th>By Agent</th>
              <th>Property Name</th>
              <th>Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Booking.map((item, index) => (
              <tr key={index}>
                <td>
                  <div className={styles.tabData}>
                    {index + 1}
                  </div>
                </td>
                <td>
                  <div className={styles.tabData}>
                    <img src={`/assets/img/${item.custImg}.png`} alt="" />
                    {item.custName}
                  </div>
                </td>
                <td>
                  <div className={styles.tabData}>
                    <img src={`/assets/img/${item.agentImg}.png`} alt="" />
                    {item.agent}
                  </div>
                </td>
                <td>
                  <div className={styles.tabData}>
                    <img src={`/assets/img/${item.propertyImg}.png`} alt="" />
                    {item.propertyName}
                  </div>
                </td>
                <td className={styles.greenText}>
                  <div className={styles.tabData}>
                    {item.Amount}
                  </div>
                </td>
                <td>
                  <div className={styles.tabData}>
                    <img src="/assets/svg/action1.svg" alt="" />
                    <img src="/assets/svg/action2.svg" alt="" />
                    <img src="/assets/svg/action3.svg" alt="" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>

        </table>
      </div>
    </div>

  )
}

export default Billing