import React from 'react'
import styles from '../Livechat.module.scss'

const ChatHead = ({onClick,active}) => {
    return (
        <div className={`${styles.chatPerson} ${active && styles.active}`} onClick={onClick}>
            <img src="/assets/img/user2.png" alt="" />

            <div className={styles.chatRight}>

                <div className={styles.asideLeft}>
                    <h5 className={styles.userName}>Nimisha Shrivastava</h5>
                    <p className={styles.lastMsg}>Hi, I checked the layout again but</p>
                </div>

                <div className={styles.asideRight}>
                    <span className={styles.time}>11:32 AM</span>
                    <span className={styles.notification}>6</span>
                </div>

            </div>
        </div>
    )
}

export default ChatHead