import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Styles from "./tablePagination.module.scss";


const arrowRight = (
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.08374 9.41332L7.07207 6.42499C7.42499 6.07207 7.42499 5.49457 7.07207 5.14165L4.08374 2.15332"
      stroke="#0E77D3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const arrowLeft = (
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.87497 9.41332L3.88664 6.42499C3.53372 6.07207 3.53372 5.49457 3.88664 5.14165L6.87497 2.15332"
      stroke="#0E77D3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

function Items({ currentPage, itemsPerPage, totalItems,itemName }) {
  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);
  return (
    <h3>
      Showing{" "}
      <b>
        {startIndex}-{endIndex}
      </b>{" "}
      of <b>{totalItems}</b> {itemName}
    </h3>
  );
}

function Tablepagination({ itemsPerPage, totalItems, setIndex, itemName }) {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setIndex(currentPage);
  }, [currentPage]);

  const handlePageClick = ({ selected }) => {
    setIndex(selected + 1);
  };

  const pageCount = Math.ceil(totalItems / itemsPerPage);

  return (
    <>
      <div className={Styles.numberWrapper}>
        <Items
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          itemName={itemName}
        />
        <ReactPaginate
          pageCount={pageCount}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5} // Adjust this value as needed
          marginPagesDisplayed={2} // Adjust this value as needed
          previousLabel={arrowLeft}
          nextLabel={arrowRight}
          containerClassName={Styles.pagination}
          activeClassName={Styles.active}
        />
      </div>
    </>
  );
}

export default Tablepagination;
