import React, { useEffect, useState } from "react";
import styles from "./custmersComponent.module.scss";
import Tags from "../../../../Components/Tags/Tags";
import FilterBox from "../../../../Components/FilterBox/FilterBox";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAllBookings, projectData } from "../../../../reducer/projectSlice";
import Tablepagination from "../../../../Components/pagination/Tablepagination";
import Options from "../../../../Components/Options/Options";
import RoleService from "../../../../helpers/RoleServicec";

function PropertyBought() {
  const searchIcon = [
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.25 3.3125C5.24695 3.3125 2.8125 5.74695 2.8125 8.75C2.8125 11.753 5.24695 14.1875 8.25 14.1875C9.72046 14.1875 11.0546 13.6038 12.0333 12.6555C12.0502 12.6325 12.069 12.6105 12.0898 12.5898C12.1105 12.569 12.1325 12.5502 12.1555 12.5333C13.1038 11.5546 13.6875 10.2205 13.6875 8.75C13.6875 5.74695 11.253 3.3125 8.25 3.3125ZM13.2712 12.9757C14.233 11.834 14.8125 10.3597 14.8125 8.75C14.8125 5.12563 11.8744 2.1875 8.25 2.1875C4.62563 2.1875 1.6875 5.12563 1.6875 8.75C1.6875 12.3744 4.62563 15.3125 8.25 15.3125C9.85967 15.3125 11.334 14.733 12.4757 13.7712L15.3523 16.6477C15.5719 16.8674 15.9281 16.8674 16.1477 16.6477C16.3674 16.4281 16.3674 16.0719 16.1477 15.8523L13.2712 12.9757Z"
        fill="#737373"
      />
    </svg>,
  ];

  const dispatch = useDispatch();
  const role = RoleService.getRoleName();
  const router = useNavigate();
  const { customerId } = useParams();

  //pagination states
  const [index, setIndex] = useState(1);

  //filter states
  const [filters, setFilters] = useState({
    query: {},
    searchText: "",
  });

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setFilters((prev) => {
        return { query: prev.query, searchText: event.target.value };
      });
    }
  };

  const { allBookings } = useSelector(projectData);

  useEffect(() => {
    dispatch(
      getAllBookings({
        index,
        query: { ...filters?.query, customerId },
        search: filters?.searchText,
      })
    );
  }, [index, filters]);

  return (
    <>
      <div className={styles.countBar}>
        <p>
          Total <b>{allBookings?.totalCount}</b>
        </p>

        <div className={styles.buttons}>
          <div className={styles.wrap}>
            <input
              type="text"
              placeholder="Search Here..."
              onKeyDown={handleKeyPress}
            />
            {searchIcon}
          </div>
          {/* <FilterBox  onFilterChange={setFilters} /> */}
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>Property Name</th>
            <th>Location</th>
            <th>Property Type</th>
            <th>Amount Paid</th>
            <th>Due Amount</th>
            {role == "agent" && <th>Action</th>}
          </tr>
        </thead>
        {allBookings?.bookings?.map((item, index) => (
          <tr>
            <td
              className={styles.propertyFflex}
              onClick={() => router(`/${role}/property/${item?.property?.id}`)}
            >
              <img src={item?.property?.media?.[0]?.mediaUrl} alt="" />
              {item?.property.name}
            </td>
            <td>{item?.property.address}</td>
            <td>
              <div className={styles.tabData}>
                <Tags
                  green={item?.property?.type === "residency" && true}
                  purple={item?.property?.type === "commercial" && true}
                  red={item?.property?.type === "apartment" && true}
                  text={item?.property?.type}
                />
              </div>
            </td>
            <td>₹ {item?.totalAmount - item?.dueAmount}</td>
            <td>₹ {item?.dueAmount}</td>

            {role == "agent" && (
              <td>
                <div className={styles.tabData}>
                  <Options
                    bookingId={item?.id}
                    propertyId={item?.property?.id}
                  />
                </div>
              </td>
            )}
          </tr>
        ))}
      </table>
      <Tablepagination
        itemsPerPage={10}
        totalItems={allBookings?.totalCount}
        setIndex={setIndex}
        itemName={"Properties"}
      />
    </>
  );
}

export default PropertyBought;
