const Booking = [
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Residency',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
        Amount: '₹ 30000'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Commercial',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
        Amount: '₹ 30000'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Apartment',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
        Amount: '₹ 30000'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Residency',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
        Amount: '₹ 30000'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Residency',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
        Amount: '₹ 30000'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Residency',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
        Amount: '₹ 30000'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Residency',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
        Amount: '₹ 30000'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Residency',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
        Amount: '₹ 30000'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Residency',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
        Amount: '₹ 30000'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Residency',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
        Amount: '₹ 30000'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Residency',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
        Amount: '₹ 30000'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Residency',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
        Amount: '₹ 30000'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Residency',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
        Amount: '₹ 30000'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Residency',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
        Amount: '₹ 30000'
    },
    
]

export default Booking;