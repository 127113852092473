import React, { useState } from 'react'
import styles from './Login.module.scss'
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import { useNavigate } from 'react-router';

const Login = () => {
     
    const [auth, setAuth] = useState(true);

  return (
    <div className={styles.loginWrap}>
        <div className={styles.loginBox}>
            
            <div className={styles.logoBox}>
                <img src="/assets/svg/logoDark.svg" alt="" />
                <p className={styles.logoText}>
                    {auth ?
                        'Welcome! Please enter your details.' : 'Fill in details to create your account now.'
                    }
                </p>
            </div>

            {auth ? <LoginForm /> : <SignupForm />}

            {/* <div className={styles.signUp}>
                {auth ? 
                <>Don’t have an account? <span onClick={()=> setAuth(false)}> Sign Up</span></> 
                    :
                <>Already have an account? <span onClick={()=> setAuth(true)}> Login</span></>
                }
            </div> */}

        </div>
    </div>
  )
}

export default Login