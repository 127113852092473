import React, { useRef, useState } from "react";
import styles from "./Addreceipt.module.scss";
import CommonHead from "../../../Components/CommonHead/CommonHead";
import InputField from "../../../Components/Input/Input";
import Button from "../../../Components/Button/Button";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addPayment } from "../../../reducer/projectSlice";
import PageLoader from "../../../Components/PageLoader/PageLoader";
import ErrorToast from "../../../Components/Toast/ErrorToast";
import SuccessToast from "../../../Components/Toast/SuccessToast";

function Addreceipt() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const { bookingId } = useParams();
  const refs = {
    amount: useRef(null),
    paymentMode: useRef(null),
    transactionId: useRef(null),
    accountName: useRef(null),
    accountNo: useRef(null),
    checkDate: useRef(null),
  };

  const validateForm = () => {
    if (!refs.amount.current?.value) {
      setErrMessage("Amount is required");
      return false;
    }
    if (!refs.paymentMode.current?.value) {
      setErrMessage("Payment mode is required");
      return false;
    }
    if (!refs.transactionId.current?.value) {
      setErrMessage("Transaction ID is required");
      return false;
    }
    if (!refs.accountName.current?.value) {
      setErrMessage("Account name is required");
      return false;
    }
    if (!refs.accountNo.current?.value) {
      setErrMessage("Account number is required");
      return false;
    }
    if (!refs.checkDate.current?.value) {
      setErrMessage("Check date is required");
      return false;
    }
    return true;
  };

  const gatherFormData = () => {
    return {
      amount: refs.amount.current?.value,
      paymentMode: refs.paymentMode.current?.value,
      transactionId: refs.transactionId.current?.value,
      accountName: refs.accountName.current?.value,
      accountNo: refs.accountNo.current?.value,
      checkDate: refs.checkDate.current?.value,
    };
  };

  const onFormSubmit = () => {
    if (validateForm()) {
      const formData = gatherFormData();
      setIsFetching(true);
      dispatch(addPayment({ obj: formData, bookingId }))
        .then((response) => {
          setIsFetching(false);
          if (response.error) {
            setIsShowError(true);
            setErrMessage(response.error.message);
          } else {
            setIsShowSuccess(true);
            setSuccessMessage("Payment Added Successfully");
            navigate(-1)
          }
        })
        .catch((error) => {
          setIsFetching(false);
          setIsShowError(true);
          setErrMessage(error.message);
        });
    } else {
      setIsShowError(true);
    }
  };

  return (
    <>
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <SuccessToast
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessage}
      />

      <CommonHead
        Heading={"Add a Payment"}
        ProjectCount={""}
        // customMsg={""}
        noSideButton={true}
      />
      <div className={styles.formWrapper}>
        <h2>Receipt Details</h2>

        <div className={styles.outerBox}>

          <div className={styles.inputFormWrapper}>
            <h3 className={styles.heading}>Property Details </h3>

            <div className={styles.inputFieldWrapper2}>
              <InputField
                // inputRef={refs.amount}
                labelName={"Name"}
                placeholder={"Input Price"}
                type={"number"}
              />
              <InputField
                // inputRef={refs.paymentMode}
                labelName={"Payment Name"}
                placeholder={"Input Name"}
              />
            </div>
            <div className={styles.inputFieldWrapper2}>
              <InputField
                // inputRef={refs.transactionId}
                labelName={"Address"}
                placeholder={"Input Address"}
              />
              <InputField
                // inputRef={refs.accountName}
                labelName={"City"}
                placeholder={"Input City"}
              />
            </div>
          </div>

          <div className={styles.inputFormWrapper}>
            <h3 className={styles.heading}>Payment Information</h3>

            <div className={styles.inputFieldWrapper2}>

              <div className={styles.formGroup}>
                <label for="cars">Paymemt Mode</label>

                <select id="cars">
                  <option value="not selected">Not Selected</option>
                  <option value="cash">Cash</option>
                  <option value="online">Online</option>
                  <option value="gpay">Gpay</option>
                  <option value="paytm">Paytm</option>
                </select>
              </div>

              <InputField
                inputRef={refs.transactionId}
                labelName={"Transaction ID"}
                placeholder={"Enter transaction ID"}
              />
            </div>

            <div className={styles.inputFieldWrapper2}>
              <InputField
                // inputRef={refs.paymentMode}
                labelName={"Amount Paid"}
                placeholder={"Input Price"}
              />
            </div>

            {/* <div className={styles.inputFieldWrapper2}>
              <InputField
                inputRef={refs.amount}
                labelName={"Amount"}
                placeholder={"Enter amount"}
                type={"number"}
              />
              <InputField
                inputRef={refs.paymentMode}
                labelName={"Payment Mode"}
                placeholder={"Enter payment mode"}
              />
            </div>
            <div className={styles.inputFieldWrapper2}>
              <InputField
                inputRef={refs.transactionId}
                labelName={"Transaction ID"}
                placeholder={"Enter transaction ID"}
              />
              <InputField
                inputRef={refs.accountName}
                labelName={"Account Name"}
                placeholder={"Enter account name"}
              />
            </div>
            <div className={styles.inputFieldWrapper2}>
              <InputField
                inputRef={refs.accountNo}
                labelName={"Account Number"}
                placeholder={"Enter account number"}
              />
              <InputField
                inputRef={refs.checkDate}
                labelName={"Check Date"}
                placeholder={"Enter check date"}
                type="date"
              />
            </div> */}

          </div>

          <div className={styles.ButtonWrapper}>
            <div className={styles.buttonRight} style={{ gap: "10px" }}>
              <Button greyButton text={"Discard"} />
              <Button blueBtnLight text={"Save"} onClick={onFormSubmit} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Addreceipt;
