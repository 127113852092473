import React from "react";
import style from "./InputNew.module.scss";

const InputField = ({
  type,
  className,
  placeholder,
  id,
  labelName,
  checkBoxWrap,
  onChange,
  disabled,
  value,
  checked,
  isError,
  errorMessage,
  notImp,
  onKeyDown,
  blackLabel,
  name,
  min,
  max,
  onBlur,
  maxlength,
  inputRef
}) => {
  return (
    <div className={`${style.formGroups} ${checkBoxWrap ?? ""}`}>
      <label className={blackLabel && "visible0"} htmlFor={id}>
        {labelName}
        {!notImp && <span className={style.impRed}> *</span>}
      </label>
      <div className={style.w - 100}>
        <input
          type={type}
          min={min}
          max={max}
          maxlength={maxlength}
          className={className}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          disabled={disabled}
          value={value}
          onChange={onChange}
          id={id}
          checked={checked}
          name={name}
          onBlur={onBlur}
          ref={inputRef}
        />
        {errorMessage && (
          <span className={style.FormError}>{errorMessage}</span>
        )}
      </div>
    </div>
  );
};

export default InputField;
