import React, { useEffect, useState } from "react";
import filter from "./Filter.module.scss";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProAgeCusByProject,
  getProjectsList,
  projectData,
} from "../../reducer/projectSlice";
import RoleService from "../../helpers/RoleServicec";

const role = RoleService.getRoleName();

const Filter = ({ className, onFilterChange }) => {
  const arrowDown = [
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.60225 6.45226C8.82192 6.22913 9.17808 6.22913 9.39775 6.45226L13.8977 11.0231C14.1174 11.2462 14.1174 11.6079 13.8977 11.8311C13.6781 12.0542 13.3219 12.0542 13.1023 11.8311L9 7.66427L4.89775 11.8311C4.67808 12.0542 4.32192 12.0542 4.10225 11.8311C3.88258 11.6079 3.88258 11.2462 4.10225 11.0231L8.60225 6.45226Z"
        fill="#737373"
      />
    </svg>,
  ];

  const search = [
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.70964 12.9701C3.4138 12.9701 0.730469 10.2867 0.730469 6.99089C0.730469 3.69505 3.4138 1.01172 6.70964 1.01172C10.0055 1.01172 12.6888 3.69505 12.6888 6.99089C12.6888 10.2867 10.0055 12.9701 6.70964 12.9701ZM6.70964 1.88672C3.89214 1.88672 1.60547 4.17922 1.60547 6.99089C1.60547 9.80255 3.89214 12.0951 6.70964 12.0951C9.52714 12.0951 11.8138 9.80255 11.8138 6.99089C11.8138 4.17922 9.52714 1.88672 6.70964 1.88672Z"
        fill="#737373"
      />
      <path
        d="M12.8332 13.5539C12.7223 13.5539 12.6115 13.513 12.524 13.4255L11.3573 12.2589C11.1882 12.0897 11.1882 11.8097 11.3573 11.6405C11.5265 11.4714 11.8065 11.4714 11.9757 11.6405L13.1423 12.8072C13.3115 12.9764 13.3115 13.2564 13.1423 13.4255C13.0548 13.513 12.944 13.5539 12.8332 13.5539Z"
        fill="#737373"
      />
    </svg>,
  ];

  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const [openFilter, setOpenFilter] = useState(false);

  const [projectId, setProjectId] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const [agentId, setAgentId] = useState("");
  const [customerId, setCustomerId] = useState("");

  const { filterData, projectsList } = useSelector(projectData);

  useEffect(() => {
    if (pathname.includes("/bookings")) {
      dispatch(getProjectsList());
    }
  }, []);
  useEffect(() => {
    if (pathname.includes("/bookings")) {
      if (projectId) dispatch(getAllProAgeCusByProject(projectId));
    }
  }, [projectId]);

  useEffect(() => {
    if (pathname.includes("/bookings")) {
      onFilterChange((prev) => {
        return { ...prev, projectId, propertyId, agentId, customerId };
      });
    }
  }, [projectId, propertyId, agentId, customerId]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    onFilterChange((prev) => {return { ...prev,[name]: value }});
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onFilterChange({ searchText: event.target.value });
    }
  };

  return (
    <>
      <div
        className={`${filter.filterWrap} ${
          openFilter && filter.active
        } ${className}`}
        onClick={() => setOpenFilter(!openFilter)}
      >
        <h4>Filters</h4>
        {arrowDown}
      </div>
      <div className={`${filter.filterBox} ${openFilter && filter.active}`}>
        <div className={filter.searchWrap}>
          {search}
          <input
            type="text"
            className={filter.search}
            placeholder="Search by name or keyword"
            onKeyDown={handleKeyPress}
          />
        </div>

        <div className={filter.filterFlexBox}>
          <div
            className={`${filter.optionWrap} ${
              (pathname.includes("/bookings") ||
                pathname.includes("/projects")) &&
              filter.flexer
            }`}
          >
            {/* <div className={filter.selectorWrapper}>
              <span>{arrowDown}</span>
              <select name="location" id="location">
                <option value={"value"}>Select Location</option>
                <option value={"value"}>Saab</option>
                <option value={"value"}>Mercedes</option>
                <option value={"value"}>Audi</option>
              </select>
            </div> */}

            {pathname.includes("/projects") && (
              <>
                <div className={filter.selectorWrapper}>
                  <span>{arrowDown}</span>
                  <select
                    name="status"
                    id="status"
                    onChange={handleFilterChange}
                  >
                    <option value={""}>Property Status</option>
                    <option value={"ongoing"}>OnGoing</option>
                    <option value={"hold"}>Hold</option>
                    <option value={"completed"}>Completed</option>
                  </select>
                </div>
              </>
            )}

            {pathname.includes("/bookings") && (
              <>
                <div className={filter.selectorWrapper}>
                  <span>{arrowDown}</span>
                  <select
                    name="projectId"
                    id="projectId"
                    onChange={(e) => setProjectId(e.target.value)}
                  >
                    <option value={""}>Select Project</option>
                    {projectsList?.map((pro) => (
                      <option value={pro.id}>{pro.name}</option>
                    ))}
                  </select>
                </div>
                {projectId && (
                  <>
                    <div className={filter.selectorWrapper}>
                      <span>{arrowDown}</span>
                      <select
                        name="propertyId"
                        id="propertyId"
                        onChange={(e) => setPropertyId(e.target.value)}
                      >
                        <option value={""}>Select Property</option>
                        {filterData?.property?.map((pro) => (
                          <option value={pro.id}>{pro.name}</option>
                        ))}
                      </select>
                    </div>

                    {role !== 'agent' && (<div className={filter.selectorWrapper}>
                      <span>{arrowDown}</span>
                      <select
                        name="agentId"
                        id="agentId"
                        onChange={(e) => setAgentId(e.target.value)}
                      >
                        <option value={""}>Select Agent</option>
                        {filterData?.agents?.map((pro) => (
                          <option value={pro.id}>{pro.name}</option>
                        ))}
                      </select>
                    </div>)}

                    {role !== 'customer' && (<div className={filter.selectorWrapper}>
                      <span>{arrowDown}</span>
                      <select
                        name="customerId"
                        id="customerId"
                        onChange={(e) => setCustomerId(e.target.value)}
                      >
                        <option value={""}>Select Customer</option>
                        {filterData?.customers?.map((pro) => (
                          <option value={pro.id}>{pro.name}</option>
                        ))}
                      </select>
                    </div>)}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Filter;
