import React from "react";
import styles from "./SideMenu.module.scss";
import data from "./menu.json";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import RoleService from "../../helpers/RoleServicec";

const SideMenu = ({ setHam, ham }) => {
  const navigate = useNavigate();
  const pathname = useLocation();
  const role = RoleService.getRoleName();

  return (
    <div className={`${styles.sideMenuWrapper} ${ham && styles.active}`}>
      <span
        className={`${styles.humButton} ${ham && styles.active}`}
        onClick={() => setHam(!ham)}
      >
        <img src=" /assets/svg/hamIcon.svg" alt="" />
      </span>

      <div className={styles.mainSideWrap}>
        <div className={styles.logoWrap}>
          <img
            className={styles.mainLogo}
            src="/assets/svg/logoWhite.svg"
            alt=""
          />
        </div>

        {ham && <span className={styles.menuHeading}>Main Menu</span>}

        <ul className={styles.sidmenuList}>
          {role &&
            data[role].map((value, index) => (
              <li
                key={index}
                className={
                  pathname.pathname === `/${value.route}` && styles.active
                }
                onClick={() => navigate(`/${value.route}`)}
                data-tooltip-id={`my-tooltip${index + 1}`}
                data-tooltip-content={value.tooltip}
              >
                <img
                  src={`/assets/svg/${value.icon}.svg`}
                  alt=""
                  style={!ham ? { minWidth: "18px" } : {}}
                />
                <span>{value.name}</span>
                {!ham && (
                  <ReactTooltip place="right" id={`my-tooltip${index + 1}`} />
                )}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
