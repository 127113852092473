import React, { useEffect, useState } from "react";
import styles from "./uploadPopup.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  clearState,
  getBookingDetails,
  projectData,
  uploadBookingFiles,
} from "../../../reducer/projectSlice";
import PageLoader from "../../PageLoader/PageLoader";
import { FileUploader } from "react-drag-drop-files";
import Button from "../../Button/Button";
import { Flexbox } from "../../TagsComponent/TagsComponent";
import axios from "axios";
import ErrorToast from "../../Toast/ErrorToast";
import SuccessToast from "../../Toast/SuccessToast";

const UploadPopup = ({
  propertyId,
  uploadPopupFileName,
  selectedType,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const { bookingDetails, errorMessage, isError, uploadedFile, isFetching } =
    useSelector(projectData);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [urls, setUrls] = useState({
    receiptUrl: null,
    bookingFormUrl: null,
    bookingConfirmationLetterUrl: null,
    letterOfUndertakingUrl: null,
    gratitudeLetterUrl: null,
  });

  useEffect(() => {
    if (propertyId) {
      dispatch(getBookingDetails(propertyId));
    }
  }, [propertyId, dispatch]);

  useEffect(() => {
    if (bookingDetails) {
      setUrls({
        receiptUrl: bookingDetails.receiptUrl,
        bookingFormUrl: bookingDetails.bookingFormUrl,
        bookingConfirmationLetterUrl:
          bookingDetails.bookingConfirmationLetterUrl,
        letterOfUndertakingUrl: bookingDetails.letterOfUndertakingUrl,
        gratitudeLetterUrl: bookingDetails.gratitudeLetterUrl,
      });
    }
  }, [bookingDetails]);

  const handleFileChange = (file) => {
    setFile(file);
  };

  const handleUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "qg8p7tcq");
    formData.append("cloud_name", "dizdephou");

    try {
      setLoading(true);
      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/dizdephou/upload`,
        formData
      );
      const url = response.data.secure_url;
      setLoading(false);
      // Update the corresponding URL based on the selected type
      const obj = { [selectedType]: url };

      dispatch(uploadBookingFiles({ obj, bookingId: bookingDetails.id }));

      setUrls(obj);
    } catch (error) {
      console.error("Error uploading file to Cloudinary:", error);
    }
  };

  //toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      setIsShowError(true);
      setErrMessage(errorMessage);
    }
  }, [isError]);

  useEffect(() => {
    if (uploadedFile) {
      setIsShowSuccess(true);
      setSuccessMessage("File Uploaded Successfully");
      setTimeout(() => {
        handleClose()
      }, 1000);
      dispatch(clearState());
    }
  }, [uploadedFile]);

  return (
    <>
      {(isFetching || loading) && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <SuccessToast
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessage}
      />
      <div className={`popup true`}>
        <div className={styles.popupinner}>
          <div className={styles.popupHeader}>
            <h3>
              {urls?.[selectedType] ? "Update" : "Upload"} {uploadPopupFileName}
            </h3>
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={handleClose}
            >
              <rect
                x="4.79883"
                y="5.79785"
                width="0.7"
                height="14"
                transform="rotate(-45 4.79883 5.79785)"
                fill="#171717"
              />
              <rect
                width="0.7"
                height="14"
                transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 15.1953 5.79785)"
                fill="#171717"
              />
            </svg>
          </div>
          <div className={styles.popupbody}>
            <div className={`${styles.inputFieldWrapper} ${styles.uploadBox}`}>
              <div className={styles.uploadWrapper}>
                <div className={styles.fileUploaderBox}>
                  <FileUploader
                    label={"Select a file to upload or drag & drop it here"}
                    name="medias"
                    multiple={false}
                    handleChange={handleFileChange}
                    types={["pdf", "doc"]}
                  />
                </div>

                {(urls?.[selectedType] || file) && (
                  <div className={styles.uploadViewBox}>
                    <div className={styles.viewImg}>
                      <img src="/assets/svg/popupview.svg" alt="" />
                    </div>
                    <div className={styles.viewDetail}>
                      <p
                        onClick={() =>
                          window.open(urls[selectedType], "_blank")
                        }
                      >
                        {file?.name ?? selectedType}
                      </p>
                      <img
                        src="/assets/svg/crossView.svg"
                        alt=""
                        onClick={() => setFile(null)}
                      />
                    </div>
                  </div>
                )}

                <Flexbox
                  alignCenter
                  justifyCenter
                  className={styles.buttonWrapper}
                  gap={10}
                >
                  <Button
                    greyButton
                    text={"Discard"}
                    maxWidth={150}
                    onClick={handleClose}
                  />
                  <Button
                    blueBtnLight
                    text={"Save"}
                    maxWidth={150}
                    onClick={handleUpload}
                  />
                </Flexbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadPopup;
