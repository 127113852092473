import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommonHead from "../../../Components/CommonHead/CommonHead";
import styles from "./Form.module.scss";
import InputField from "../../../Components/Input/Input";
import Button from "../../../Components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewBooking,
  clearState,
  getProjectsList,
  getPropertyList,
  projectData,
} from "../../../reducer/projectSlice";
import PageLoader from "../../../Components/PageLoader/PageLoader";
import ErrorToast from "../../../Components/Toast/ErrorToast";
import SuccessToast from "../../../Components/Toast/SuccessToast";
import { getUserDetails, userSelector } from "../../../reducer/userSlice";

function Form2() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerId } = useParams();

  const {
    projectsList,
    propertyList,
    isFetching,
    isSuccess,
    isError,
    errorMessage,
    isBookedAdded,
  } = useSelector(projectData);

  const { userData, isFetching: newFetching } = useSelector(userSelector);

  useEffect(() => {
    dispatch(getProjectsList());
    if (customerId) dispatch(getUserDetails(customerId));
  }, []);

  const [paymentType, setPaymentType] = useState("");
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      setIsShowError(true);
      setErrMessage(errorMessage);
    }
  }, [isError]);

  useEffect(() => {
    if (isBookedAdded) {
      setIsShowSuccess(true);
      setSuccessMessage("Booking Added Successfully");
      setTimeout(() => {
        navigate(-1);
      }, 1000);
      dispatch(clearState());
    }
  }, [isBookedAdded]);
  const onProjectChange = (e) => {
    dispatch(getPropertyList({ projectId: e.target.value, isBooked: false }));
  };

  const refs = {
    projectId: useRef(null),
    propertyId: useRef(null),
    name: useRef(null),
    email: useRef(null),
    phone: useRef(null),
    aadhar: useRef(null),
    pan: useRef(null),
    gaurdian: useRef(null),
    relation: useRef(null),
    address1: useRef(null),
    address2: useRef(null),
    landmark: useRef(null),
    pinCode: useRef(null),
    city: useRef(null),
    state: useRef(null),
    country: useRef(null),
    altPhone: useRef(null),
    companyName: useRef(null),
    officeAddress: useRef(null),
    contactNo: useRef(null),
    industry: useRef(null),
    function: useRef(null),
    annualIncome: useRef(null),
    projectSource: useRef(null),
    existingClient: useRef(null),
    purpose: useRef(null),
    paymentSource: useRef(null),
    interestInOtherProjects: useRef(null),
    paymentType: useRef(null),
    paymentMode: useRef(null),
    transactionId: useRef(null),
    amountPaid: useRef(null),
    emiCollectionDate: useRef(null),
    emiDurationYears: useRef(null),
    emiStartDate: useRef(null),
    accountName: useRef(null),
    accountNo: useRef(null),
    checkDate: useRef(null),
  };

  const validateForm = () => {
    if (!refs.projectId.current?.value) {
      setErrMessage("Project is required");
      return false;
    }
    if (!refs.propertyId.current?.value) {
      setErrMessage("Property is required");
      return false;
    }
    if (!customerId) {
      if (!refs.name.current?.value) {
        setErrMessage("Name is required");
        return false;
      }
      if (!refs.email.current?.value) {
        setErrMessage("Email is required");
        return false;
      }
      if (!refs.phone.current?.value) {
        setErrMessage("Phone number is required");
        return false;
      }
      if (!refs.aadhar.current?.value) {
        setErrMessage("Aadhar card number is required");
        return false;
      }
      if (!refs.pan.current?.value) {
        setErrMessage("PAN card number is required");
        return false;
      }
      if (!refs.address1.current?.value) {
        setErrMessage("Address street 1 is required");
        return false;
      }
      if (!refs.landmark.current?.value) {
        setErrMessage("Landmark is required");
        return false;
      }
      if (!refs.pinCode.current?.value) {
        setErrMessage("PIN code is required");
        return false;
      }
      if (!refs.city.current?.value) {
        setErrMessage("City is required");
        return false;
      }
      if (!refs.state.current?.value) {
        setErrMessage("State is required");
        return false;
      }
      if (!refs.country.current?.value) {
        setErrMessage("Country is required");
        return false;
      }
    }
    if (!refs.amountPaid.current?.value) {
      setErrMessage("Amount paid is required");
      return false;
    }
    if (paymentType === "emi") {
      if (!refs.emiCollectionDate.current?.value) {
        setErrMessage("EMI collection date is required for EMI payment type");
        return false;
      }
      if (!refs.emiDurationYears.current?.value) {
        setErrMessage("EMI duration is required for EMI payment type");
        return false;
      }
      if (!refs.emiStartDate.current?.value) {
        setErrMessage("EMI start date is required for EMI payment type");
        return false;
      }
    }
    return true;
  };

  const gatherFormData = () => {
    const questAnswer = {
      projectSource: refs.projectSource.current?.value,
      existingClient: refs.existingClient.current?.value,
      purpose: refs.purpose.current?.value,
      paymentSource: refs.paymentSource.current?.value,
      interestInOtherProjects: refs.interestInOtherProjects.current?.value,
    };

    const paymentData = {
      paymentType: paymentType,
      amountPaid: refs.amountPaid.current?.value,
      transactionId: refs.transactionId.current?.value,
      ...(paymentType === "emi" && {
        emiCollectionDate: refs.emiCollectionDate.current?.value,
        emiDurationYears: refs.emiDurationYears.current?.value,
        emiStartDate: refs.emiStartDate.current?.value,
      }),
      paymentMode: refs.paymentMode.current?.value,
      accountName: refs.accountName.current?.value,
      accountNo: refs.accountNo.current?.value,
      checkDate: refs.checkDate.current?.value,
    };

    if (customerId) {
      return {
        customerId,
        questAnswer,
        ...paymentData,
      };
    } else {
      return {
        questAnswer,
        ...paymentData,
        name: refs.name.current?.value,
        email: refs.email.current?.value,
        phone: refs.phone.current?.value,
        aadhar: refs.aadhar.current?.value,
        pan: refs.pan.current?.value,
        gaurdian: refs.gaurdian.current?.value,
        relation: refs.relation.current?.value,
        address1: refs.address1.current?.value,
        address2: refs.address2.current?.value,
        landmark: refs.landmark.current?.value,
        pinCode: refs.pinCode.current?.value,
        city: refs.city.current?.value,
        state: refs.state.current?.value,
        country: refs.country.current?.value,
        altPhone: refs.altPhone.current?.value,
        companyName: refs.companyName.current?.value,
        officeAddress: refs.officeAddress.current?.value,
        contactNo: refs.contactNo.current?.value,
        industry: refs.industry.current?.value,
        function: refs.function.current?.value,
        annualIncome: refs.annualIncome.current?.value,
      };
    }
  };

  const OnFormSubmit = () => {
    if (validateForm()) {
      const formData = gatherFormData();
      console.log("formData: ", formData);
      dispatch(
        addNewBooking({
          obj: formData,
          propertyId: refs.propertyId.current?.value,
        })
      );
    } else {
      setIsShowError(true);
    }
  };
  return (
    <>
      {(isFetching || newFetching) && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <SuccessToast
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessage}
      />
      <CommonHead
        Heading={userData?.name ?? "Add Customer"}
        ProjectCount={"Booking"}
        customMsg={"Add"}
        noSideButton={true}
      />
      <div className={styles.formWrapper}>
        <h2>New Booking Details</h2>

        <div className={styles.outerBox}>
          <div className={styles.inputFormWrapper}>
            <h3 className={styles.heading}>Property Details</h3>

            <div className={styles.inputFieldWrapper2}>
              <div className={styles.formGroups}>
                <label>Select Project *</label>
                <select
                  name="project"
                  id="project"
                  onChange={onProjectChange}
                  ref={refs.projectId}
                >
                  <option value={""} selected={true} disabled={true}>
                    Projects
                  </option>
                  {projectsList?.map((pro) => (
                    <option value={pro?.id} key={pro?.id}>
                      {pro?.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className={styles.formGroups}>
                <label>Select Property *</label>
                <select name="Type" id="Type" ref={refs.propertyId}>
                  <option value={""} selected={true} disabled={true}>
                    Properties
                  </option>
                  {propertyList?.map((pro) => (
                    <option value={pro?.id} key={pro?.id}>
                      {pro?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {!customerId && (
            <>
              <div className={styles.inputFormWrapper}>
                <h3 className={styles.heading}>Personal Details</h3>

                <div className={styles.inputFieldWrapper2}>
                  <InputField
                    inputRef={refs.name}
                    labelName={"Name"}
                    placeholder={"Enter full name"}
                  />
                </div>
                <div className={styles.inputFieldWrapper2}>
                  <InputField
                    inputRef={refs.email}
                    labelName={"Email ID"}
                    placeholder={"Enter email"}
                  />
                  <InputField
                    inputRef={refs.phone}
                    labelName={"Phone No."}
                    placeholder={"Enter phone number"}
                  />
                </div>
                <div className={styles.inputFieldWrapper2}>
                  <InputField
                    inputRef={refs.gaurdian}
                    labelName={"Gaurdian"}
                    placeholder={"Enter Gaurdian Name"}
                    notImp
                  />
                  <InputField
                    inputRef={refs.relation}
                    labelName={"Relation"}
                    placeholder={"Enter Relation with Gaurdian"}
                    notImp
                  />
                </div>
                <div className={styles.inputFieldWrapper2}>
                  <InputField
                    inputRef={refs.aadhar}
                    labelName={"Aadhar No."}
                    placeholder={"Enter aadhar number"}
                  />
                  <InputField
                    inputRef={refs.pan}
                    labelName={"PAN No."}
                    placeholder={"Enter PAN number"}
                  />
                </div>
                <div className={styles.inputFieldWrapper2}>
                  <InputField
                    inputRef={refs.address1}
                    labelName={"Street 1"}
                    placeholder={"Enter address street 1"}
                  />
                  <InputField
                    notImp
                    inputRef={refs.address2}
                    labelName={"Street 2"}
                    placeholder={"Enter address street 2 "}
                  />
                </div>
                <div className={styles.inputFieldWrapper2}>
                  <InputField
                    inputRef={refs.landmark}
                    labelName={"Landmark"}
                    placeholder={"Enter landmark"}
                  />
                  <InputField
                    inputRef={refs.pinCode}
                    labelName={"PIN Code"}
                    placeholder={"Enter PIN code"}
                  />
                </div>
                <div className={styles.inputFieldWrapper2}>
                  <InputField
                    inputRef={refs.city}
                    labelName={"City"}
                    placeholder={"Enter city"}
                  />
                  <InputField
                    inputRef={refs.state}
                    labelName={"State"}
                    placeholder={"Enter state"}
                  />
                </div>
                <div className={styles.inputFieldWrapper2}>
                  <InputField
                    inputRef={refs.country}
                    labelName={"Country"}
                    placeholder={"Enter country"}
                  />
                  <InputField
                    notImp
                    inputRef={refs.altPhone}
                    labelName={"Alternate Phone No."}
                    placeholder={"Enter alternate phone number "}
                  />
                </div>
              </div>

              <div className={styles.inputFormWrapper}>
                <h3 className={styles.heading}>Professional Details</h3>

                <div className={styles.inputFieldWrapper2}>
                  <InputField
                    notImp
                    inputRef={refs.companyName}
                    labelName={"Company Name"}
                    placeholder={"Enter company name "}
                  />
                  <InputField
                    notImp
                    inputRef={refs.officeAddress}
                    labelName={"Office Address"}
                    placeholder={"Enter office address "}
                  />
                </div>
                <div className={styles.inputFieldWrapper2}>
                  <InputField
                    notImp
                    inputRef={refs.contactNo}
                    labelName={"Contact No."}
                    placeholder={"Enter contact number "}
                  />
                  <InputField
                    notImp
                    inputRef={refs.industry}
                    labelName={"Industry"}
                    placeholder={"Enter industry "}
                  />
                </div>
                <div className={styles.inputFieldWrapper2}>
                  <InputField
                    notImp
                    inputRef={refs.function}
                    labelName={"Profession"}
                    placeholder={"Enter Profession "}
                  />
                  <InputField
                    notImp
                    inputRef={refs.annualIncome}
                    labelName={"Annual Income (INR)"}
                    placeholder={"Enter annual income "}
                  />
                </div>
              </div>
            </>
          )}

          <div className={styles.inputFormWrapper}>
            <h3 className={styles.heading}>General Questions</h3>
            <div className={styles.inputFieldWrapper2}>
              <InputField
                inputRef={refs.projectSource}
                labelName={"How did you come to know about this project?"}
                placeholder={"Enter source"}
              />
              <InputField
                inputRef={refs.existingClient}
                labelName={"Are you an existing Shamanta client?"}
                placeholder={"Enter yes or no"}
              />
            </div>
            <div className={styles.inputFieldWrapper2}>
              <InputField
                inputRef={refs.purpose}
                labelName={"Purpose of Purchase"}
                placeholder={"Enter purpose"}
              />
              <InputField
                inputRef={refs.paymentSource}
                labelName={"Mode of Payment / Source"}
                placeholder={"Enter payment source"}
              />
            </div>
            <div className={styles.inputFieldWrapper2}>
              <InputField
                inputRef={refs.interestInOtherProjects}
                labelName={
                  "Would you be interested in other projects by Shamanta builders LLP?"
                }
                placeholder={"Enter yes or no"}
              />
            </div>
          </div>

          <div className={styles.inputFormWrapper}>
            <h3 className={styles.heading}>Payment Details</h3>

            <div className={styles.inputFieldWrapper2}>
              <div className={styles.formGroups}>
                <label>Payment Type *</label>
                <select
                  ref={refs.paymentType}
                  name="paymentType"
                  id="paymentType"
                  onChange={(e) => setPaymentType(e.target.value)}
                >
                  <option value={""} selected={true} disabled={true}>
                    Select Payment Type
                  </option>
                  <option value={"emi"}>EMI</option>
                  <option value={"full"}>Full Payment</option>
                  <option value={"partial"}>Partial Payment</option>
                </select>
              </div>

              <InputField
                inputRef={refs.transactionId}
                labelName={"Transaction Id"}
                placeholder={"Enter transaction ID"}
              />
            </div>

            <div className={styles.inputFieldWrapper2}>
              <InputField
                inputRef={refs.amountPaid}
                labelName={"Amount Paid"}
                placeholder={"Enter amount paid"}
              />
            </div>

            {paymentType === "emi" && (
              <div className={styles.inputFieldWrapper2}>
                <InputField
                  inputRef={refs.emiCollectionDate}
                  labelName={"EMI Collection Date"}
                  placeholder={"Enter EMI collection date"}
                  type={"date"}
                />
                <div className={styles.formGroups}>
                  <label>Payment Tenure (Years)</label>
                  <select
                    name="emiDurationYears"
                    id="emiDurationYears"
                    ref={refs.emiDurationYears}
                  >
                    <option value={""} selected={true} disabled={true}>
                      Select Tenure
                    </option>
                    <option value={"2"}>2 Years</option>
                    <option value={"3"}>3 Years</option>
                    <option value={"5"}>5 Years</option>
                  </select>
                </div>
                <InputField
                  inputRef={refs.emiStartDate}
                  labelName={"EMI Start Date"}
                  placeholder={"Enter EMI start date"}
                  type="date"
                />
              </div>
            )}

            <div className={styles.inputFieldWrapper2}>
              <InputField
                inputRef={refs.paymentMode}
                labelName={"Payment Mode"}
                placeholder={"Enter payment mode"}
              />
              <InputField
                inputRef={refs.accountName}
                labelName={"Account Name"}
                placeholder={"Enter account name"}
              />
            </div>
            <div className={styles.inputFieldWrapper2}>
              <InputField
                inputRef={refs.accountNo}
                labelName={"Account No."}
                placeholder={"Enter account number"}
              />
              <InputField
                inputRef={refs.checkDate}
                labelName={"Check Date"}
                placeholder={"Enter check date"}
                type="date"
              />
            </div>
          </div>

          <div className={styles.ButtonWrapper}>
            <div className={styles.buttonRight} style={{ gap: "10px" }}>
              <Button greyButton text={"Discard"} />
              <Button blueBtnLight text={"Save"} onClick={OnFormSubmit} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Form2;
