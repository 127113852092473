import React, { useEffect, useState } from "react";
import styles from "./Project.module.scss";
import CommonHead from "../../../Components/CommonHead/CommonHead";
import Filter from "../../../Components/Filter/Filter";
import { useNavigate } from "react-router-dom";
import { Flexbox } from "../../../Components/TagsComponent/TagsComponent";
import PropertyCard from "./PropertyCard";
import { useDispatch, useSelector } from "react-redux";
import Tablepagination from "../../../Components/pagination/Tablepagination";
import {
  clearState,
  getAllProjects,
  projectData,
} from "../../../reducer/projectSlice";
import PageLoader from "../../../Components/PageLoader/PageLoader";
import ErrorToast from "../../../Components/Toast/ErrorToast";
import SuccessToast from "../../../Components/Toast/SuccessToast";
import RoleService from "../../../helpers/RoleServicec";

const role = RoleService.getRoleName();

const CusProjectPrev = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [view, setView] = useState(false);

  //filter states
  const [filters, setFilters] = useState({
    status: "",
    searchText: "",
  });

  //Toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  //pagination states
  const [index, setIndex] = useState(1);

  const { allProjects, isFetching, isSuccess, isError, errorMessage } =
    useSelector(projectData);

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      setIsShowError(true);
      setErrMessage(errorMessage);
    }
  }, [isError]);

  useEffect(() => {
    dispatch(
      getAllProjects({
        index,
        stus: filters?.status,
        search: filters?.searchText,
      })
    );
  }, [index, filters]);

  return (
    <>
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <SuccessToast
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessage}
      />
      <div className={styles.projectPrevWrap}>
        <CommonHead
          Heading={"Projects"}
          customMsg={"Total Projects - "}
          ProjectCount={allProjects?.totalCount ?? 0}
          buttonText={"Add Project"}
          onButtonClick={() => router(`/admin/addprojects`)}
          hasViewBtn={true}
          view={view}
          setView={setView}
          noSideButton={role !="admin"}
        />
        <Filter className={styles.projectFilter} onFilterChange={setFilters} />

        <Flexbox
          flexStart
          alignStart
          wrap
          className={styles.propertyMaster}
          gap={20}
        >
          {allProjects?.project?.map((project, index) => (
            <PropertyCard
              key={project?.id}
              onClick={() => router(`/${role}/projectPrev/` + project?.id)}
              data={project}
              view={view}
            />
          ))}
        </Flexbox>

        <Tablepagination
          itemsPerPage={10}
          totalItems={allProjects?.totalCount}
          setIndex={setIndex}
          itemName={"Projects"}
        />
      </div>
    </>
  );
};

export default CusProjectPrev;
