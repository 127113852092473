import React, { useEffect, useState } from "react";
import styles from "../Project.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAllTimelines, projectData } from "../../../../reducer/projectSlice";
import { useNavigate, useParams } from "react-router-dom";
import PageLoader from "../../../../Components/PageLoader/PageLoader";
import Tablepagination from "../../../../Components/pagination/Tablepagination";
import moment from "moment";
import RoleService from "../../../../helpers/RoleServicec";

function StatusUpdate() {
  //pagination states
  const role = RoleService.getRoleName();
  const [index, setIndex] = useState(1);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { projectId, propertyId } = useParams();

  const { allTimeslines, isFetching } = useSelector(projectData);

  useEffect(() => {
    dispatch(getAllTimelines({ projectId: projectId ?? propertyId, index }));
  }, []);

  return (
    <>
      {isFetching && <PageLoader />}
      {allTimeslines?.timeline?.length > 0 && (
        <div className={styles.timelineWrapper}>
          {allTimeslines?.timeline?.map((ev) => (
            <div className={styles.timeBox} key={ev?.id}>
              <p className={styles.time}>{moment(ev?.createdAt).fromNow()}</p>
              {ev?.eventType == "userAdded" && (
                <div className={styles.nameCardWrapper}>
                  <p>{ev?.description}</p>
                  <div className={styles.namecard}>
                    <img
                      src={
                        ev?.createdFor?.profileImg ?? "/assets/img/user1.png"
                      }
                      alt=""
                    />
                    <p className={styles.name}>{ev?.createdFor?.name}</p>
                  </div>
                </div>
              )}
              {["addProperty", "deleteProperty","booking","payment"].includes(ev?.eventType) && (
                <div className={styles.nameCardWrapper}>
                  <p>{ev?.description}</p>
                  <div
                    className={styles.namecard}
                    onClick={() =>
                      navigate(`/${role}/property/${ev?.propertyId}`)
                    }
                  >
                    <p className={styles.name}>{ev?.property?.name}</p>
                  </div>
                </div>
              )}
              {ev?.eventType == "addProject" && role != "customer" && (
                <div className={styles.nameCardWrapper}>
                  <p>{ev?.description}</p>
                  <div
                    className={styles.namecard}
                    onClick={() =>
                      navigate(`/${role}/projectPrev/${ev?.projectId}`)
                    }
                  >
                    <p className={styles.name}>{ev?.project?.name}</p>
                  </div>
                </div>
              )}
              {ev?.eventType == "image" && (
                <>
                  <p>{ev?.description} :</p>
                  <div className={styles.imgWrapper}>
                    {ev?.fileUrls.split("|")?.map((img) => (
                      <div className={styles.imgCard}>
                        <img src={img} alt="" />
                      </div>
                    ))}
                  </div>
                </>
              )}
              {ev?.eventType == "file" && (
                <>
                  <p>{ev?.description} :</p>
                  <div className={styles.namedocWrapper}>
                    {ev?.fileUrls.split("|")?.map((file) => (
                      <div
                        className={styles.docCard}
                        onClick={() => window.open(file, "_blank")}
                      >
                        <img src="/assets/svg/doc.svg" alt="" />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      )}

      <Tablepagination
        itemsPerPage={10}
        totalItems={allTimeslines?.totalCount}
        setIndex={setIndex}
        itemName={"Activities"}
      />
    </>
  );
}

export default StatusUpdate;
