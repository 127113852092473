import React, { useEffect, useState } from "react";
import styles from "../Project.module.scss";

import Button from "../../../../Components/Button/Button";
import Receipt1 from "../../../../Components/Receipt/Receipt1";
import Receipt2 from "../../../../Components/Receipt/Receipt2";
import { useDispatch, useSelector } from "react-redux";
import {
  getBookingDetails,
  getCustomerPropertiesList,
  getPropertyList,
  projectData,
} from "../../../../reducer/projectSlice";
import { useParams } from "react-router-dom";
import PageLoader from "../../../../Components/PageLoader/PageLoader";
import RoleService from "../../../../helpers/RoleServicec";
import Receipt3 from "../../../../Components/Receipt/Receipt3";
import Receipt4 from "../../../../Components/Receipt/Receipt4";
import Receipt5 from "../../../../Components/Receipt/Receipt5";
// import Receipt3 from '../../../../Components/Receipt/Receipt3'
// import Receipt4 from '../../../../Components/Receipt/Receipt4'

const OwnerShip = () => {
  const [tab, setTab] = useState(1);

  const [property, setProperty] = useState(null);
  const role = RoleService.getRoleName();
  const { projectId, bookingId, propertyId } = useParams();
  const dispatch = useDispatch();

  const { isFetching, bookingDetails, propertyList, allCustPropertiesList } =
    useSelector(projectData);

  useEffect(() => {
    setProperty(allCustPropertiesList?.[0]?.id ?? propertyList?.[0]?.id);
    if (!bookingDetails) {
      if (allCustPropertiesList?.[0]?.id)
        dispatch(getBookingDetails(allCustPropertiesList?.[0]?.id));
      if (propertyList?.[0]?.id)
        dispatch(getBookingDetails(propertyList?.[0]?.id));
    }
  }, [allCustPropertiesList, propertyList]);

  useEffect(() => {
    if (bookingId || propertyId)
      dispatch(getBookingDetails(bookingId ?? propertyId));
    if (projectId && !propertyId)
      dispatch(getPropertyList({ projectId: projectId, isBooked: true }));
    if (role == "customer") {
      dispatch(getCustomerPropertiesList());
    }
  }, []);

  const onPropertyChange = (e) => {
    setProperty(e.target.value);
    if (e.target.value) dispatch(getBookingDetails(e.target.value));
  };

  const urls = {
    1: bookingDetails?.receiptUrl,
    2: bookingDetails?.bookingFormUrl,
    3: bookingDetails?.bookingConfirmationLetterUrl,
    4: bookingDetails?.letterOfUndertakingUrl,
    5: bookingDetails?.gratitudeLetterUrl,
  };

  const openUrl = (url) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  const arrow = [
    <svg
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.54554 0.646447C7.7408 0.451184 8.05738 0.451184 8.25264 0.646447L12.2526 4.64645C12.4479 4.84171 12.4479 5.15829 12.2526 5.35355L8.25264 9.35355C8.05738 9.54882 7.7408 9.54882 7.54554 9.35355C7.35027 9.15829 7.35027 8.84171 7.54554 8.64645L10.692 5.5H1.23242C0.956279 5.5 0.732422 5.27614 0.732422 5C0.732422 4.72386 0.956279 4.5 1.23242 4.5H10.692L7.54554 1.35355C7.35027 1.15829 7.35027 0.841709 7.54554 0.646447Z"
        fill="#0E77D3"
      />
    </svg>,
  ];

  return (
    <>
      {isFetching && <PageLoader />}
      <div className={styles.ownerWrap}>
        <div className={styles.ownerTop}>
          <div className={styles.Downloadbutton}>
            {(projectId || propertyList) && (
              <div className={styles.formGroups}>
                <label>Select Property *</label>
                <select
                  name="Type"
                  id="Type"
                  onChange={onPropertyChange}
                  value={property}
                >
                  <option value={""} selected={true} disabled={true}>
                    Properties
                  </option>
                  {propertyList &&
                    propertyList?.map((pro) => (
                      <option value={pro?.id} key={pro?.id}>
                        {pro?.name}
                      </option>
                    ))}
                  {allCustPropertiesList &&
                    allCustPropertiesList?.map((pro) => (
                      <option value={pro?.id} key={pro?.id}>
                        {pro?.name}
                      </option>
                    ))}
                </select>
              </div>
            )}
            {urls[tab] && (
              <Button
                onClick={() => openUrl(urls[tab])}
                blueBtnLight
                className="blueBtnLight"
                text="Download"
              />
            )}
          </div>
        </div>
        {bookingDetails && (
          <div className={styles.OwnerShipWrap}>
            <div className={styles.ownerLeft}>
              <p
                className={`${tab === 1 && styles.active}`}
                onClick={() => setTab(1)}
              >
                Receipt
                {arrow}
              </p>
              <p
                className={`${tab === 2 && styles.active}`}
                onClick={() => setTab(2)}
              >
                Booking Form
                {arrow}
              </p>
              <p
                className={`${tab === 3 && styles.active}`}
                onClick={() => setTab(3)}
              >
                Booking Confirmation Letter
              </p>
              <p
                className={`${tab === 4 && styles.active}`}
                onClick={() => setTab(4)}
              >
                Letter of Undertaking
              </p>
              <p
                className={`${tab === 5 && styles.active}`}
                onClick={() => setTab(5)}
              >
                Gratitude Letter
              </p>
            </div>
            <div className={styles.ownerRight}>
              {(tab === 1 && <Receipt1 data={bookingDetails} />) ||
                (tab === 2 && <Receipt2 data={bookingDetails} />) ||
                (tab === 3 && <Receipt3 data={bookingDetails} />) ||
                (tab === 4 && <Receipt4 data={bookingDetails} />) ||
                (tab === 5 && <Receipt5 data={bookingDetails} />)}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OwnerShip;
