import React, { useEffect, useState } from "react";
import styles from "./Project.module.scss";
import About from "./ProjectComponets/About";
import Billing from "./ProjectComponets/Billing";
import OwnerShip from "../../Agent/Projects/ProjectComponets/OwnerShip";
import { useDispatch, useSelector } from "react-redux";
import { projectData } from "../../../reducer/projectSlice";
import PageLoader from "../../../Components/PageLoader/PageLoader";
import ErrorToast from "../../../Components/Toast/ErrorToast";
import SuccessToast from "../../../Components/Toast/SuccessToast";
import { clearState } from "../../../reducer/userSlice";
import CommonHead from "../../../Components/CommonHead/CommonHead";
import StatusUpdate from "../../Admin/Projects/ProjectComponets/StatusUpdate";

const CusProject = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(1);

  const { isFetching, isError, errorMessage ,propertyDetails} = useSelector(projectData);

  //Toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      setIsShowError(true);
      setErrMessage(errorMessage);
    }
  }, [isError]);

  return (
    <>
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <SuccessToast
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessage}
      />
      <div className={styles.projectOverview}>
        <CommonHead
          Heading={"Property Overview"}
          customMsg={"Property -"}
          ProjectCount={propertyDetails?.name}
          buttonText={"Filter"}
          hasViewBtn={false}
          noSideButton={true}
        />
        <ul className={styles.projectTabs}>
          <li
            className={active === 1 && styles.active}
            onClick={() => setActive(1)}
          >
            About
          </li>
          <li
            className={active === 2 && styles.active}
            onClick={() => setActive(2)}
          >
            Details of Ownership
          </li>
          <li
            className={active === 3 && styles.active}
            onClick={() => setActive(3)}
          >
            Status Update
          </li>
          <li
            className={active === 4 && styles.active}
            onClick={() => setActive(4)}
          >
            Billing
          </li>
        </ul>
        <div className={styles.projectPages}>
          {(active === 1 && <About />) ||
            (active === 2 && <OwnerShip />) ||
            (active === 3 && <StatusUpdate />) ||
            (active === 4 && <Billing />)}
        </div>
      </div>
    </>
  );
};

export default CusProject;
