import React, { useEffect, useState } from "react";
import styles from "./AddProperty.module.scss";
import InputField from "../../../Components/Input/Input";
import Button from "../../../Components/Button/Button";
import CommonHead from "../../../Components/CommonHead/CommonHead";
import Textarea from "../../../Components/Input/Textarea";
import CustomCheckbox from "../../../Components/CustomCheckbox/CustomCheckbox";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import PageLoader from "../../../Components/PageLoader/PageLoader";
import ErrorToast from "../../../Components/Toast/ErrorToast";
import SuccessToast from "../../../Components/Toast/SuccessToast";
import {
  addProperty,
  clearState,
  projectData,
  getPropertyDetails,
  updateProperty
} from "../../../reducer/projectSlice";

function AddProperty() {
  const arrowDown = [
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.60225 6.45226C8.82192 6.22913 9.17808 6.22913 9.39775 6.45226L13.8977 11.0231C14.1174 11.2462 14.1174 11.6079 13.8977 11.8311C13.6781 12.0542 13.3219 12.0542 13.1023 11.8311L9 7.66427L4.89775 11.8311C4.67808 12.0542 4.32192 12.0542 4.10225 11.8311C3.88258 11.6079 3.88258 11.2462 4.10225 11.0231L8.60225 6.45226Z"
        fill="#737373"
      />
    </svg>,
  ];
  const router = useNavigate();

  const {
    allPropertys,
    isFetching,
    isPropertyAdded,
    isPropertyUpdated,
    propertyDetails,
    isSuccess,
    isError,
    errorMessage,
  } = useSelector(projectData);

  //toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  // State for form fields
  const [propertyName, setPropertyName] = useState("");
  const [price, setPrice] = useState("");
  const [rate, setRate] = useState("");
  const [plotNo, setPlotNo] = useState("");
  const [scheme, setScheme] = useState("");
  const [area, setArea] = useState("");
  const [areaType, setAreaType] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [broucher, setBroucher] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [mediaFiles, setMediaFiles] = useState([]);
  const [features, setFeatures] = useState([]);

  const { projectId,propertyId  } = useParams();

  useEffect(() => {
    if (area && rate) {
      const calculatedPrice = parseFloat(area) * parseFloat(rate);
      setPrice(calculatedPrice.toFixed(2));
    }
  }, [area, rate]);

  useEffect(() => {
    if (propertyId) {
      dispatch(getPropertyDetails(propertyId));
    }
  }, [propertyId]);

  useEffect(() => {
    if (propertyDetails && propertyId) {
      setPropertyName(propertyDetails.name);
      setPrice(propertyDetails.price);
      setRate(propertyDetails.rate);
      setPlotNo(propertyDetails.plotNo);
      setScheme(propertyDetails.scheme);
      setArea(propertyDetails.area);
      setAreaType(propertyDetails.areaType);
      setType(propertyDetails.type);
      setDescription(propertyDetails.description);
      setAddress(propertyDetails.address);
      setPincode(propertyDetails.pin);
      setBroucher(propertyDetails.brochure);
      setVideoLink(propertyDetails.video);
      setFeatures(propertyDetails?.features?.map(f => f?.featureName));
    }
  }, [propertyDetails, propertyId]);

  const handleSubmit = () => {
    try {
      const formData = {
        name: propertyName,
        description,
        price: price,
        area,
        areaType,
        rate,
        plotNo,
        scheme,
        type,
        address,
        pin: pincode,
        brochure: broucher,
        video: videoLink,
        medias: mediaFiles,
        features,
      };
      console.log("formData: ", formData);
      if (
        !propertyName ||
        !description ||
        !type ||
        !area ||
        !areaType ||
        !rate ||
        !plotNo ||
        !scheme ||
        !price ||
        !address ||
        !pincode ||
        !broucher ||
        !videoLink ||
        !mediaFiles.length > 0 ||
        !features.length > 0
      ) {
        throw new Error("All fields are required.");
      }

      if (propertyId) {
        dispatch(updateProperty({ obj: formData, propertyId }));
      } else {
        dispatch(addProperty({ obj: formData, projectId }));
      }

    } catch (error) {
      console.error("Error adding property:", error.message);
      setIsShowError(true);
      setErrMessage(error.message);
    }
  };

  const handleUploadBrochure = (file) => {
    try {
      if (file) {
        console.log("file: ", file);
        console.log(" size: ", file.size / 1000000);
        if (file.size / 1000000 > 10) {
          throw new Error("File size should not exceed 10Mb");
        }
        setIsLoading(true);
        const data = new FormData();

        data.append("file", file);
        data.append("upload_preset", "qg8p7tcq");
        data.append("cloud_name", "dizdephou");

        fetch("https://api.cloudinary.com/v1_1/dizdephou/raw/upload", {
          method: "POST",
          body: data,
        })
          .then((res) => res.json())
          .then((res) => {
            setIsLoading(false);
            setBroucher(res.secure_url);
          });
      }
    } catch (err) {
      setIsLoading(false);
      setIsShowError(true);
      setErrMessage(err.message);
    }
  };

  const handleUploadMediaFiles = (files) => {
    try {
      if (files && files.length > 5) {
        throw new Error("Files should not be more than 5");
      }
      if (files && files.length > 0) {
        setIsLoading(true);

        // Loop through each selected file
        for (let i = 0; i < files.length; i++) {
          console.log("file: ", files[i]);
          console.log(" size: ", files[i].size / 1000000);
          if (files[i].size / 1000000 > 10) {
            throw new Error("File size should not exceed 10 Mb");
          }
          const data = new FormData();
          data.append("file", files[i]);
          data.append("upload_preset", "qg8p7tcq");
          data.append("cloud_name", "dizdephou");

          fetch("https://api.cloudinary.com/v1_1/dizdephou/raw/upload", {
            method: "POST",
            body: data,
          })
            .then((res) => res.json())
            .then((res) => {
              setIsLoading(false);
              if (res.secure_url) {
                setMediaFiles((prevMediaFiles) => [
                  ...prevMediaFiles,
                  res.secure_url,
                ]);
              }
            });
        }
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setIsShowError(true);
      setErrMessage(err.message);
    }
  };

  useEffect(() => {
    if (isPropertyAdded) {
      setIsShowSuccess(true);
      setSuccessMessage("Property Created Successfully");
      setTimeout(() => {
        router(-1);
      }, 1000);
      dispatch(clearState());
    }
  }, [isPropertyAdded]);

  useEffect(() => {
    if (isPropertyUpdated) {
      setIsShowSuccess(true);
      setSuccessMessage("Property Updated Successfully");
      setTimeout(() => {
        router(-1);
      }, 1000);
      dispatch(clearState());
    }
  }, [isPropertyUpdated]);

  return (
    <>
      {(isFetching || isLoading) && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <SuccessToast
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessage}
      />
      <CommonHead
        Heading={propertyId ? "Update Property" : "Add Property"}
        PropertyCount={"Dashboard"}
        customMsg={"Home -"}
        noSideButton={true}
      />
      <div className={styles.formWrapper}>
        <h2>Details</h2>
        <div className={styles.inputFormWrapper}>
          <div className={styles.inputFieldWrapper}>
            <InputField
              labelName={"Property Name"}
              placeholder={"Input Name"}
              value={propertyName}
              onChange={(e) => setPropertyName(e.target.value)}
            />

            <div className={styles.formGroups}>
            <label>
              Property Type
            </label>
              <select
                name="Type"
                id="Type"
                onChange={(e) => setType(e.target.value)}
                value={type}
              >
                <option value={""} selected={true} disabled={true}>
                  Property Type
                </option>
                <option value={"residency"}>Residency</option>
                <option value={"apartment"}>Apartment</option>
                <option value={"commercial"}>Commercial</option>
              </select>
            </div>

            {/* <InputField
              labelName={"Property Type"}
              placeholder={"Property Area"}
            /> */}
          </div>

          <div className={styles.inputFieldWrapper}>
          <InputField
              labelName={"Area"}
              type={"number"}
              placeholder={"Input Area of property"}
              value={area}
              onChange={(e) => setArea(e.target.value)}
            />

            <div className={styles.formGroups}>
            <label>
              Area Type
            </label>
              <select
                name="Type"
                id="Type"
                onChange={(e) => setAreaType(e.target.value)}
                value={areaType}
              >
                <option value={""} selected={true} disabled={true}>
                  Area Type
                </option>
                <option value={"sq.ft"}>Square feet</option>
                <option value={"sq.m"}>Square meters</option>
                <option value={"acres"}>Acres</option>
                <option value={"hectares"}>Hectares</option>
              </select>
            </div>
          </div>

          <div className={styles.inputFieldWrapper}>
            <InputField
              labelName={`Rate ${areaType ? "per " + areaType : ""}`}
              type={"number"}
              placeholder={"Input Rate in Rupees"}
              value={rate}
              onChange={(e) => setRate(e.target.value)}
            />

            <InputField
              labelName={`Price`}
              type={"number"}
              placeholder={"Input Price in Rupees"}
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>

          <div className={styles.inputFieldWrapper}>
            <InputField
              labelName={`Plot No`}
              type={"string"}
              placeholder={"Input Plot Number"}
              value={plotNo}
              onChange={(e) => setPlotNo(e.target.value)}
            />

            <InputField
              labelName={`Scheme`}
              type={"text"}
              placeholder={"Input Scheme"}
              value={scheme}
              onChange={(e) => setScheme(e.target.value)}
            />
          </div>

          <div className={styles.inputFieldWrapper}>
            <Textarea
              labelName={"Description"}
              className={styles.checkBox}
              placeholder={"Write something about the property"}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div className={styles.inputFieldWrapper}>
            <InputField
              labelName={"Address"}
              placeholder={"Address of your property"}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <InputField
              labelName={"Pincode"}
              placeholder={"Enter pin code"}
              value={pincode}
              type={"number"}
              onChange={(e) => setPincode(e.target.value)}
            />
          </div>

          <div className={`${styles.inputFieldWrapper} ${styles.uploadBox}`}>
            <label htmlFor="" className={styles.labelz}>
              Media (5 allowed) *
            </label>
            <FileUploader
              label={"Drop files here or click to upload media files."}
              name="medias"
              multiple={true}
              maxSize={5}
              handleChange={handleUploadMediaFiles}
              types={[
                "jpg",
                "jpeg",
                "png",
                "gif",
                "bmp",
                "svg",
                "webp",
                "tiff",
              ]}
            />
          </div>

          <div className={`${styles.inputFieldWrapper} ${styles.uploadBox}`}>
            <label htmlFor="" className={styles.labelz}>
              Broucher: *
            </label>
            <FileUploader
              label={"Drop files here or click to upload brochure."}
              name="brochure"
              handleChange={handleUploadBrochure}
              types={["pdf", "doc"]}
            />
          </div>

          <div className={`${styles.inputFieldWrapper}`}>
            <InputField
              labelName={"Drone Video (mp4)"}
              placeholder={"Enter video link here"}
              value={videoLink}
              onChange={(e) => setVideoLink(e.target.value)}
            />
          </div>

          <div
            className={`${styles.inputFieldWrapper} ${styles.additionalWrap}`}
          >
            <div
              className={`${styles.propertyType} ${styles.additionalFeatures}`}
            >
              <label htmlFor="" className={styles.labelz}>
                Features *
              </label>
              <TagsInput
                inputProps={{ placeholder: "Press enter to add" }}
                onlyUnique={true}
                value={features}
                onChange={(tags) => {
                  setFeatures(tags);
                }}
              />
            </div>
          </div>

          <div className={styles.ButtonWrapper}>
            <div className={styles.buttonRight} style={{ gap: "10px" }}>
              <Button greyButton text={"Discard"} />
              <Button blueBtnLight text={"Save"} onClick={handleSubmit} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddProperty;
