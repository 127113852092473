import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./AboutSlider.css";
import React from "react";

function AboutSlider({ images }) {
  return (
    <>
      <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
        {images?.map((img) => (
          <SwiperSlide key={img?.id}>
            <img src={img?.mediaUrl}></img>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default AboutSlider;
