import React from "react";
import style from "./Receipt.module.scss";

const Receipt4 = ({ data }) => {
  const { customer, property, project, agent } = data;

  return (
    <div className={style.receiptWrapper}>
      <div className={style.receipt1Head}>
        <img src="/assets/svg/samantha.svg" alt="" />
      </div>

      <div className={style.receipt1Body}>
        <p className={style.subHeadingReceipt}>
          <span>Sub:</span> Holding details of Plot No. {property.plotNo} in{" "}
          {project.name}, {property.scheme}.
        </p>
        <p className={style.mt40}>Respected Sir/Ma'am,</p>
        <p className={style.mt40}>
          We <b>"SHAMANTA BUILDERS LLP” </b>thank you for investing in us as a
          developer in {project.name} and assure you of our premier services.
        </p>

        <h2 className={style.mt40}>Customer Details</h2>
        <div className={style.outerDivWrapOuter}>
          <div className={style.outerDivWrap}>
            <div className={style.contentWrap}>
              <p>Name</p>
              <p className={style.fontblack}>{customer.name}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Customer Id</p>
              <p className={style.fontblack}>{customer.code}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Address</p>
              <p className={style.fontblack}>
                {customer.userInfo.stree1}, {customer.userInfo.stree2},{" "}
                {customer.userInfo.city}, {customer.userInfo.state},{" "}
                {customer.userInfo.country}
              </p>
            </div>
            <div className={style.contentWrap}>
              <p>Mobile No.</p>
              <p className={style.fontblack}>{customer.mobileNumber}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Email Id</p>
              <p className={style.fontblack}>{customer.email || "-"}</p>
            </div>
            <div className={style.contentWrap}>
              <p>PAN Card No.</p>
              <p className={style.fontblack}>{customer.userInfo.pan}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Aadhaar Card No.</p>
              <p className={style.fontblack}>{customer.userInfo.aadhar}</p>
            </div>
          </div>
        </div>

        <h2 className={style.mt40}>Property Details</h2>
        <div className={style.outerDivWrapOuter}>
          <div className={style.outerDivWrap}>
            <div className={style.contentWrap}>
              <p>Property Name</p>
              <p className={style.fontblack}>{property.name}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Plot Number</p>
              <p className={style.fontblack}>{property.plotNo}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Location</p>
              <p className={style.fontblack}>{property.address}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Area ({property.areaType})</p>
              <p className={style.fontblack}>{property.area} {property.areaType} </p>
            </div>
          </div>
        </div>

        <h2 className={style.mt40}>Payment Details</h2>
        <div className={style.outerDivWrapOuter}>
          <div className={style.outerDivWrap}>
            <div className={style.contentWrap}>
              <p>Total Amount</p>
              <p className={style.fontblack}>{data.totalAmount}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Due Amount</p>
              <p className={style.fontblack}>{data.dueAmount}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Payment Type</p>
              <p className={style.fontblack}>{data.paymentType}</p>
            </div>
            <div className={style.contentWrap}>
              <p>EMI Amount</p>
              <p className={style.fontblack}>{data.emiAmount}</p>
            </div>
            <div className={style.contentWrap}>
              <p>EMI Duration</p>
              <p className={style.fontblack}>{data.emiDurationYears} years</p>
            </div>
            <div className={style.contentWrap}>
              <p>EMI Count</p>
              <p className={style.fontblack}>{data.emiCount}</p>
            </div>
            <div className={style.contentWrap}>
              <p>EMI Start Date</p>
              <p className={style.fontblack}>
                {new Date(data.emiStartDate).toLocaleDateString()}
              </p>
            </div>
            <div className={style.contentWrap}>
              <p>EMI End Date</p>
              <p className={style.fontblack}>
                {new Date(data.emiEndDate).toLocaleDateString()}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={style.cards}>

        <div className={style.greenCard}>
          <h4>Total Amount</h4>
          <p>{data.totalAmount}/-</p>
        </div>
        <div className={style.greyCard}>
          <h4>Amount Paid</h4>
          <p>{data.totalAmount - data.dueAmount}/-</p>
        </div>
        <div className={style.redCard}>
          <h4>Due Amount</h4>
          <p>{data.dueAmount}/-</p>
        </div>

      </div>


      <div className={style.termandConditions}>
        <h2>General Terms and Conditions:</h2>

        <ul>
          {[
            "All payment will have to be paid fully within your committed period from booking date.",
            "Every 3 months it is required to pay 1 mega EMI.",
            "All EMI need to be paid on time every month on the committed date.",
            "At the time of booking whichever payment terms are discussed and confirmed by, that has to be strictly followed by you in future.",
            "If EMI is not paid on time or not responding to us in that case @18% of interest will be charged on your overdue days.",
            "In case if you fail to pay your EMIs more than 3 months without any intimation, we are bound to consider that you are not further interested to continue with us and so that we have all the right to cancel your booking and give it to others. And the booking amount or any paid amount is non-refundable.",
            "Full payment has to be made within committed time period.",
            "If any kind of change in your communication address and contact number, it is your responsibility to inform at our head office to better communicate to you.",
            "Any verbal promises given by our sales executive will not be considered as legal other than written and authorized signed documents by company. Company is not liable to fulfill the same.",
          ].map((term, index) => (
            <li key={index}>
              <img src="/assets/svg/listtick.svg" alt="tick" />
              <p>{term}</p>
            </li>
          ))}
        </ul>
      </div>

      <p>
          I, <b>{customer.name} </b>understand, agreed and accepted all
          necessary terms & conditions for being member and my booking to Plot
          No. {property.plotNo} in {property.name} at {property.address}
        </p>
        <div className={style.spacebetween}>
          <p>Accepted By,</p>
          <p>Yours Faithfully,</p>
        </div>
    </div>
  );
};

export default Receipt4;
