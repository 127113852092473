import React from "react";
import style from "./Receipt.module.scss";
import moment from "moment";

function Receipt1({ data }) {

  return (
    <div className={style.receiptWrapper}>
      <div className={style.receipt1Head}>
        <img src="/assets/svg/samantha.svg" alt="" />
        <div className={style.contentWrap}>
          <p>
            Receipt No: <span>#INV{data?.receipts?.[0]?.receiptNo}</span>
          </p>
          <p>
            Issued On:{" "}
            <span>{moment(data?.createdAt).format("DD/MM/YYYY")}</span>
          </p>
        </div>
      </div>

      <div className={style.receipt1Body}>
        <div className={style.topBody}>
          <div className={style.topBodyLeft}>
            <div className={style.contentWrap}>
              <h2>Bill TO</h2>
              <p>{data?.customer?.name}</p>
              <p>{data?.customer?.address}</p>
            </div>
            <div className={style.contentWrap}>
              <h2>Agent Name(s)</h2>
              <p>{data?.agent?.name}</p>
            </div>
          </div>
          <div className={style.contentWrapRight}>
            <h2>Issued By</h2>
            <p>702, 801 Shivalik Satyamev,</p>
            <p>Bopal-Ambli Cross Road,</p>
            <p>Opp. Vakil Saheb Bridge, Bopal</p>
            <p>Ahmedabad - 380058</p>
            <p>
              {" "}
              <b>URL:</b> shamanta.in
            </p>
            <p>
              <b>Mobile:</b> +919978917173
            </p>
            <p>
              <b>Mail: </b>shamantabuilders@gmail.com
            </p>
          </div>
        </div>

        <div className={style.MidBody}>
          <h2>Property Details</h2>

          <div className={style.outerDivWrap}>
            <div className={style.contentWrap}>
              <p>Property Name</p>
              <p className={style.fontblack}>{data?.property?.name}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Plot No.</p>
              <p className={style.fontblack}>{data?.property?.plotNo}</p>
            </div>
            <div className={style.contentWrap}>
              <p>Plot Location</p>
              <p className={style.fontblack}>{data?.property?.address}</p>
            </div>
          </div>

          <div className={style.borderLight}></div>

          <h2>Payment Details</h2>

          <div className={style.outerDivWrap}>
            <div className={style.contentWrap}>
              <p>Payment Mode</p>
              <p className={style.fontblack}>
                {data?.receipts?.[0]?.paymentMode}
              </p>
            </div>
            <div className={style.contentWrap}>
              <p>Transaction ID</p>
              <p className={style.fontblack}>
                {data?.receipts?.[0]?.transactionId}
              </p>
            </div>
            <div className={style.contentWrap}>
              <p>Amount Paid</p>
              <p className={style.fontblack}>{data?.receipts?.[0]?.amount}</p>
            </div>
            <div className={style.contentWrap}>
              <p>EMI Collection Data</p>
              <p className={style.fontblack}>
                {data?.emiCollectionDate
                  ? moment(data?.emiCollectionDate).format("DD/MM/YYYY")
                  : "-"}
              </p>
            </div>
          </div>

          <div className={style.cards}>
            <div className={style.greenCard}>
              <h4>Total Amount</h4>
              <p>{data?.totalAmount}/-</p>
            </div>
            <div className={style.greyCard}>
              <h4>Amount Paid</h4>
              <p>{data?.totalAmount - data?.dueAmount}/-</p>
            </div>
            <div className={style.redCard}>
              <h4>Due Amount</h4>
              <p>{data?.dueAmount}/-</p>
            </div>
          </div>
        </div>

        <div className={style.bottomBody}>
          <div className={style.content}>
            <h2>Thank You</h2>
            <p>
              Cheque Subject to Realization Ahmedabad Jurisdiction
              Non-Refundable
            </p>
          </div>
          <div className={style.contentSign}>
            <h2>Authorized Signature</h2>
            <img src="/assets/svg/samanthaSign.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Receipt1;
