import React from 'react'
import styles from './Login.module.scss'
import { Link } from 'react-router-dom'

function Thanku() {
    return (
        <div className={styles.forgetWrap}>
            <img src="/assets/svg/logoDark.svg" alt="" />

            <div className={`${styles.forgetBox}`}>
                <div className={styles.forgetHead}>
                    <img src="/assets/svg/thanku.svg" alt="" />
                    <h4 className={styles.thnkuheadText}>Thank You</h4>
                    <p  className={styles.thnkusubText}>Your password has been changed successfully. Kindly re-login with the new password to access your account information.</p>
                </div>
               
                <Link className={styles.goBack} to={'/'}>
                    <img src="/assets/svg/backArrowLeft.svg" alt="" />
                    Back to Login
                </Link>
            </div>

            <div className={styles.blank}></div>
        </div>
    )
}

export default Thanku