import styles from "../Project.module.scss";
import React, { useEffect, useState } from "react";
import style from "../../Payment/Payment.module.scss";
import moment from "moment";
import PaymentTab from "../../Payment/paymentTable";

import { useNavigate, useParams } from "react-router-dom";
import CommonHead from "../../../../Components/CommonHead/CommonHead";
import Tags from "../../../../Components/Tags/Tags";
import Button from "../../../../Components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllReceipts,
  getBookingDetails,
  projectData,
} from "../../../../reducer/projectSlice";
import Tablepagination from "../../../../Components/pagination/Tablepagination";
import Reciptpop from "../../../../Components/Popup/Reciptpop/Reciptpop";

function Billing() {
  const searchIcon = [
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.25 3.3125C5.24695 3.3125 2.8125 5.74695 2.8125 8.75C2.8125 11.753 5.24695 14.1875 8.25 14.1875C9.72046 14.1875 11.0546 13.6038 12.0333 12.6555C12.0502 12.6325 12.069 12.6105 12.0898 12.5898C12.1105 12.569 12.1325 12.5502 12.1555 12.5333C13.1038 11.5546 13.6875 10.2205 13.6875 8.75C13.6875 5.74695 11.253 3.3125 8.25 3.3125ZM13.2712 12.9757C14.233 11.834 14.8125 10.3597 14.8125 8.75C14.8125 5.12563 11.8744 2.1875 8.25 2.1875C4.62563 2.1875 1.6875 5.12563 1.6875 8.75C1.6875 12.3744 4.62563 15.3125 8.25 15.3125C9.85967 15.3125 11.334 14.733 12.4757 13.7712L15.3523 16.6477C15.5719 16.8674 15.9281 16.8674 16.1477 16.6477C16.3674 16.4281 16.3674 16.0719 16.1477 15.8523L13.2712 12.9757Z"
        fill="#737373"
      />
    </svg>,
  ];

  const router = useNavigate();
  const dispatch = useDispatch();

  const { propertyId } = useParams();

  //pagination states
  const [index, setIndex] = useState(1);

  //popup
  const [Show, setShow] = useState(false);
  const [bookingId, setBookingId] = useState(null);

  //filter states
  const [filters, setFilters] = useState({
    query: {},
    searchText: "",
  });

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setFilters({ searchText: event.target.value });
    }
  };

  const { allReceipts, bookingDetails } = useSelector(projectData);

  useEffect(() => {
    dispatch(getBookingDetails(propertyId));
    dispatch(
      getAllReceipts({
        index,
        query: {
          ...filters?.query,
          propertyId: propertyId ?? "",
        },
        search: filters?.searchText,
      })
    );
  }, [index, filters]);

  return (
    <>
      {Show && (
        <Reciptpop
          show={true}
          handleClose={() => setShow(false)}
          bookingId={bookingId}
        />
      )}
      {bookingDetails && <div className={styles.bookingHistoryWrap}>
        <div className={styles.bookingHistoryBox}>
          <div className={styles.flexbox}>
            <div className={styles.Wrapper}>
              <div className={styles.contentBoxHistory}>
                <p className={styles.pLeft}>Booking Date</p>
                <p>{moment(bookingDetails?.createdAt).format("DD/MM/YYYY")}</p>
              </div>

              <div className={styles.contentBoxHistory}>
                <p className={styles.pLeft}>Booking Rate</p>
                <p>
                  {bookingDetails?.property?.rate
                    ? bookingDetails?.property?.rate +
                      " per " +
                      bookingDetails?.property?.areaType
                    : "-"}{" "}
                </p>
              </div>

              <div className={styles.contentBoxHistory}>
                <p className={styles.pLeft}>Booking Amount</p>
                <p>{bookingDetails?.receipts?.[0]?.amount} </p>
              </div>
              <div className={styles.contentBoxHistory}>
                <p className={styles.pLeft}>Payment Type</p>
                <p>{bookingDetails?.paymentType} </p>
              </div>

              <div className={styles.contentBoxHistory}>
                <p className={styles.pLeft}>Payment Plan</p>
                <p>{bookingDetails?.emiDurationYears ?? "-"}</p>
              </div>
            </div>
            {/* <img src="/assets/svg/progress.svg" alt="" /> */}
          </div>
        </div>

        <div className={styles.bookingHistoryBox}>
          <div className={styles.flexbox}>
            {bookingDetails?.paymentType == "emi" && (
              <div className={styles.bookingHistoryContentBox}>
                <div className={styles.purpuleTag}>Recurring</div>
                <h2>
                  {bookingDetails?.emiAmount}/- <span>Per Month</span>
                </h2>
                <h5>
                  Total <b>{bookingDetails?.emiCount}</b> EMI’s to be paid.
                  Starting from{" "}
                  {moment(bookingDetails?.emiStartDate).format("MM YYYY")} to
                  {moment(bookingDetails?.emiEndDate).format("MM YYYY")}
                </h5>
              </div>
            )}
            <div className={styles.redCard}>
              <h4>Due Amount</h4>
              <p>{bookingDetails?.dueAmount}/-</p>
            </div>
          </div>
        </div>
      </div>}

      <div className={style.paymentWrap}>
        <div className={style.propertyTable}>
          <div className={style.countBar}>
            <p>
              Total Invoices <b>{allReceipts?.totalCount}</b>
            </p>

            <div className={style.buttons}>
              <div className={style.wrap}>
                <input
                  type="text"
                  placeholder="Search Here..."
                  onKeyDown={handleKeyPress}
                />
                {searchIcon}
              </div>
            </div>
          </div>

          <div className={style.tableWrap}>
            <table className={style.bookingTable}>
              <thead>
                <tr>
                  <th>Invoice No.</th>
                  <th>Customer Name</th>
                  <th>Issued Date</th>
                  <th>Amount</th>
                  <th>Payment Mode</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allReceipts?.receipt?.map((item, index) => (
                  <tr key={item?.id}>
                    <td>
                      <div className={styles.tabData}>
                        {"#INV" + item?.receiptNo}
                      </div>
                    </td>
                    <td>
                      <div className={styles.tabData}>
                        <img
                          src={
                            item?.user?.profileImg ?? "/assets/img/user1.png"
                          }
                          alt="profileImg"
                        />
                        {item?.user.name}
                      </div>
                    </td>
                    <td>
                      <div className={styles.tabData}>
                        {moment(item?.createdAt).format("DD MMM YYYY")}
                      </div>
                    </td>
                    <td className={styles.greenText}>
                      <div className={styles.tabData}>₹ {item?.amount}</div>
                    </td>
                    <td className={styles.greenText}>
                      <div className={styles.tabData}> {item?.paymentMode}</div>
                    </td>
                    <td>
                      <div className={styles.tabData}>
                        <img
                          src="/assets/svg/action1.svg"
                          alt=""
                          onClick={() => {
                            setBookingId(item?.bookingId);
                            setShow(true);
                          }}
                        />
                        {/* <img src="/assets/svg/action2.svg" alt="" /> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Tablepagination
        itemsPerPage={10}
        totalItems={allReceipts?.totalCount}
        setIndex={setIndex}
        itemName={"Biilings"}
      />
    </>
  );
}

export default Billing;
