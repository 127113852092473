import React, { useEffect, useState } from "react";
import styles from "./AddProject.module.scss";
import InputField from "../../../Components/Input/Input";
import Button from "../../../Components/Button/Button";
import CommonHead from "../../../Components/CommonHead/CommonHead";
import Textarea from "../../../Components/Input/Textarea";
import "react-tagsinput/react-tagsinput.css";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from "react-redux";

import PageLoader from "../../../Components/PageLoader/PageLoader";
import ErrorToast from "../../../Components/Toast/ErrorToast";
import SuccessToast from "../../../Components/Toast/SuccessToast";
import {
  addProject,
  clearState,
  projectData,
} from "../../../reducer/projectSlice";
import { useNavigate } from "react-router-dom";

function AddProject() {
  const [tags, setTags] = useState([]);
  const router = useNavigate();

  const {
    allProjects,
    isFetching,
    isProjectAdded,
    isSuccess,
    isError,
    errorMessage,
  } = useSelector(projectData);

  //toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      setIsShowError(true);
      setErrMessage(errorMessage);
    }
  }, [isError]);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  // State for form fields
  const [projectName, setProjectName] = useState("");
  const [projectArea, setProjectArea] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [broucher, setBroucher] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [mediaFiles, setMediaFiles] = useState([]);

  const handleSubmit = () => {
    try {
      const formData = {
        name: projectName,
        description,
        area: projectArea,
        address,
        pin: pincode,
        brochure: broucher,
        video: videoLink,
        medias: mediaFiles,
      };
      console.log("formData: ", formData);
      if (
        !projectName ||
        !description ||
        !projectArea ||
        !address ||
        !pincode ||
        !broucher ||
        !videoLink ||
        !mediaFiles
      ) {
        throw new Error("All fields are required.");
      }

      dispatch(addProject(formData));
    } catch (error) {
      console.error("Error adding project:", error.message);
      setIsShowError(true);
      setErrMessage(error.message);
    }
  };

  const handleChange = (tags) => {
    setTags(tags);
  };

  const handleUploadBrochure = (file) => {
    try {
      if (file) {
        console.log("file: ", file);
        console.log(" size: ", file.size / 1000000);
        if (file.size / 1000000 > 10) {
          throw new Error("File size should not exceed 10Mb");
        }
        setIsLoading(true);
        const data = new FormData();

        data.append("file", file);
        data.append("upload_preset", "qg8p7tcq");
        data.append("cloud_name", "dizdephou");

        fetch("https://api.cloudinary.com/v1_1/dizdephou/raw/upload", {
          method: "POST",
          body: data,
        })
          .then((res) => res.json())
          .then((res) => {
            setIsLoading(false);
            setBroucher(res.secure_url);
          });
      }
    } catch (err) {
      setIsLoading(false);
      setIsShowError(true);
      setErrMessage(err.message);
    }
  };

  const handleUploadMediaFiles = (files) => {
    try {
      if (files && files.length > 5) {
        throw new Error("Files should not be more than 5");
      }
      if (files && files.length > 0) {
        setIsLoading(true);

        // Loop through each selected file
        for (let i = 0; i < files.length; i++) {
          console.log("file: ", files[i]);
          console.log(" size: ", files[i].size / 1000000);
          if (files[i].size / 1000000 > 10) {
            throw new Error("File size should not exceed 10 Mb");
          }
          const data = new FormData();
          data.append("file", files[i]);
          data.append("upload_preset", "qg8p7tcq");
          data.append("cloud_name", "dizdephou");

          fetch("https://api.cloudinary.com/v1_1/dizdephou/raw/upload", {
            method: "POST",
            body: data,
          })
            .then((res) => res.json())
            .then((res) => {
              setIsLoading(false);
              if (res.secure_url) {
                setMediaFiles((prevMediaFiles) => [
                  ...prevMediaFiles,
                  res.secure_url,
                ]);
              }
            });
        }
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setIsShowError(true);
      setErrMessage(err.message);
    }
  };

  useEffect(() => {
    if (isProjectAdded) {
      setIsShowSuccess(true);
      setSuccessMessage("Project Created Successfully");
      setTimeout(() => {
        router("/admin/projects");
      }, 1000);
      dispatch(clearState());
    }
  }, [isProjectAdded]);
  return (
    <>
      {(isFetching || isLoading) && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <SuccessToast
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessage}
      />
      <CommonHead
        Heading={"Add Project"}
        ProjectCount={"Dashboard"}
        customMsg={"Home -"}
        noSideButton={true}
      />
      <div className={styles.formWrapper}>
        <h2>Details</h2>
        <div className={styles.inputFormWrapper}>
          <div className={styles.inputFieldWrapper}>
            <InputField
              labelName={"Project Name"}
              placeholder={"Input Name"}
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
            <InputField
              labelName={"Project Area"}
              placeholder={"Enter Measurements of Project"}
              value={projectArea}
              onChange={(e) => setProjectArea(e.target.value)}
            />
            {/* <InputField
              labelName={"Project Status"}
              placeholder={"Project Area"}
            /> */}
          </div>

          <div className={styles.inputFieldWrapper}>
            <Textarea
              labelName={"Description"}
              className={styles.checkBox}
              placeholder={"Write something about the project"}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div className={styles.inputFieldWrapper}>
            <InputField
              labelName={"Address"}
              placeholder={"Address of your property"}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <InputField
              labelName={"Pincode"}
              placeholder={"Enter pin code"}
              value={pincode}
              type={"number"}
              onChange={(e) => setPincode(e.target.value)}
            />
          </div>

          <div className={`${styles.inputFieldWrapper} ${styles.uploadBox}`}>
            <label htmlFor="" className={styles.labelz}>
              Media (5 allowed) *
            </label>
            <FileUploader
              label={"Drop files here or click to upload media files."}
              name="medias"
              multiple={true}
              handleChange={handleUploadMediaFiles}
              types={[
                "jpg",
                "jpeg",
                "png",
                "gif",
                "bmp",
                "svg",
                "webp",
                "tiff",
              ]}
            />
          </div>

          <div className={`${styles.inputFieldWrapper} ${styles.uploadBox}`}>
            <label htmlFor="" className={styles.labelz}>
              Broucher: *
            </label>
            <FileUploader
              label={"Drop files here or click to upload brochure."}
              name="brochure"
              handleChange={handleUploadBrochure}
              types={["pdf", "doc"]}
            />
          </div>

          <div className={`${styles.inputFieldWrapper}`}>
            <InputField
              labelName={"Drone Video (mp4)"}
              placeholder={"Enter video link here"}
              value={videoLink}
              onChange={(e) => setVideoLink(e.target.value)}
            />
          </div>

          <div className={styles.ButtonWrapper}>
            <div className={styles.buttonRight} style={{ gap: "10px" }}>
              <Button greyButton text={"Discard"} />
              <Button blueBtnLight text={"Save"} onClick={handleSubmit} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddProject;
