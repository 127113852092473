import React from 'react'
import './PagenotefoundStyle.css'

const PageNotFound = () => {
  return (
    <div className='ErrorPageforallscreen'>
    <img src="/assets/img/gif/datanotfound.gif" alt="datanotfound" />
    <p className='text2 mt-10'>Sorry, the page you are looking for, doesn’t exist</p>
  </div>
  )
}

export default PageNotFound
