import React, { useEffect, useState } from "react";
import CommonHead from "../../../Components/CommonHead/CommonHead";
import styles from "./Agents.module.scss";
import { Flexbox } from "../../../Components/TagsComponent/TagsComponent";
import AgentCard from "../../../Components/AgentCard/AgentCard";
import Filter from "../../../Components/Filter/Filter";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Tablepagination from "../../../Components/pagination/Tablepagination";
import {
  clearState,
  getAllUsers,
  userSelector,
} from "../../../reducer/userSlice";
import PageLoader from "../../../Components/PageLoader/PageLoader";
import ErrorToast from "../../../Components/Toast/ErrorToast";

const Agents = () => {
  const router = useNavigate();

  const dispatch = useDispatch();

  //pagination states
  const [index, setIndex] = useState(1);

  //filter states
  const [filters, setFilters] = useState({ searchText: "" });

  const { isFetching, isError, errorMessage, allUsers } =
    useSelector(userSelector);

  //Toast states
  const [isShowError, setIsShowError] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      setIsShowError(true);
      setErrMessage(errorMessage);
    }
  }, [isError]);

  useEffect(() => {
    clearState();

    dispatch(
      getAllUsers({
        roleName: "agent",
        search: filters?.searchText,
        index,
      })
    );
  }, [index, filters]);

  useEffect(() => {
    if (deleted) {
      clearState();

      dispatch(
        getAllUsers({
          roleName: "agent",
          search: filters?.searchText,
          index,
        })
      );
    }
  }, [deleted]);

  return (
    <>
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <div className={styles.agentWrap}>
        <CommonHead
          Heading={"Agents"}
          ProjectCount={allUsers?.totalCount}
          buttonText={"Add Agent"}
          onButtonClick={() => router("/admin/addagent")}
        />
        <Filter className={styles.agentFilter} onFilterChange={setFilters} />

        <Flexbox stretch spaceBW className={styles.cardArrangement}>
          {allUsers?.users?.map((user, index) => (
            <AgentCard
              key={user.id}
              id={user.id}
              className={styles.myAgent}
              userImg={user.profileImg ?? "/assets/img/user1.png"}
              AgentCode={user.code}
              AgentName={user.name}
              Location={user.address ?? "N/A"}
              Properties={user?.totalProperties}
              setDeleted={setDeleted}
            />
          ))}
        </Flexbox>
        <Tablepagination
          itemsPerPage={10}
          totalItems={allUsers?.totalCount}
          setIndex={setIndex}
          itemName={"Agents"}
        />
      </div>
    </>
  );
};

export default Agents;
