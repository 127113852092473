import React from 'react'
import styles from '../Livechat.module.scss'

const ChatBubble = ({sender,children,isTime}) => {
  return (
    <>
    <div className={`${styles.chatBubble} ${sender && styles.senderBubble}`}>
        {children}
    </div>
    {isTime && <span className={styles.timeLast}>{isTime}</span>}
    </>
  )
}

export default ChatBubble