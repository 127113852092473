import React, { useEffect, useState } from "react";
import styles from "./bookings.module.scss";
import CommonHead from "../../../Components/CommonHead/CommonHead";
import Filter from "../../../Components/Filter/Filter";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllBookings, projectData } from "../../../reducer/projectSlice";
import Tablepagination from "../../../Components/pagination/Tablepagination";
import PageLoader from "../../../Components/PageLoader/PageLoader";
import RoleService from "../../../helpers/RoleServicec";
import Reciptpop2 from "../../../Components/Popup/Reciptpop/Reciptpop2";

const Bookings = () => {
  const router = useNavigate();

  const dispatch = useDispatch();
  const role = RoleService.getRoleName();
  //pagination states
  const [index, setIndex] = useState(1);

  //popup
  const [Show, setShow] = useState(false);
  const [bookingId, setBookingId] = useState(null);

  //filter states
  const [filters, setFilters] = useState({
    projectId: "",
    propertyId: "",
    agentId: "",
    customerId: "",
    searchText: "",
  });
  console.log("filters: ", filters);

  const { isFetching,isFetching2, allBookings } = useSelector(projectData);

  useEffect(() => {
    dispatch(
      getAllBookings({
        index,
        query: { ...filters },
        search: filters?.searchText,
      })
    );
  }, [index, filters]);

  return (
    <>
      {(isFetching || isFetching2) && <PageLoader />}
      {Show && <Reciptpop2 show={true} handleClose={() => setShow(false)} bookingId={bookingId} />}
      <div className={styles.bookingWrap}>
        <CommonHead
          Heading={"Bookings"}
          customMsg={"Bookings"}
          ProjectCount={allBookings?.totalCount}
          buttonText={"Add Bookings"}
          onButtonClick={() => router("/addBooking")}
          noSideButton={role !== "agent"}
        />
        <Filter className={styles.bookingFilter} onFilterChange={setFilters} />

        <div className={styles.tableWrap}>
          <table className={styles.bookingTable}>
            <thead>
              <tr>
                <th>Sr.no</th>
                <th>Customer Name</th>
                <th>By Agent</th>
                <th>Property Name</th>
                <th>City</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {allBookings?.bookings?.map((item, index) => (
                <tr key={item?.id}>
                  <td>
                    <div className={styles.tabData}>{index + 1}</div>
                  </td>
                  <td>
                    <div className={styles.tabData}>
                      <img
                        src={
                          item?.customer.profileImg ?? "/assets/img/user1.png"
                        }
                        alt="profileImg"
                      />
                      {item?.customer.name}
                    </div>
                  </td>
                  <td>
                    <div className={styles.tabData}>
                      <img
                        src={item?.agent?.profileImg ?? "/assets/img/user1.png"}
                        alt="profileImg"
                      />
                      {item?.agent.name}
                    </div>
                  </td>
                  <td>
                    <div className={styles.tabData} onClick={() => router(`/${role}/property/${item?.property?.id}`)}>
                      <img
                        src={item?.property?.media?.[0]?.mediaUrl}
                        alt="proImg"
                      />
                      {item?.property.name}
                    </div>
                  </td>
                  <td>
                    <div className={styles.tabData}>
                      {item?.property.address}
                    </div>
                  </td>
                  <td>
                    <div className={styles.tabData}>
                      <img
                        className="pointer"
                        src="/assets/svg/action1.svg"
                        alt=""
                        onClick={() => {
                          setBookingId(item?.id);
                          setShow(true);
                        }}
                      />
                      {/* <img src="/assets/svg/action2.svg" alt="" /> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Tablepagination
        itemsPerPage={10}
        totalItems={allBookings?.totalCount}
        setIndex={setIndex}
        itemName={"Bookings"}
      />
    </>
  );
};

export default Bookings;
