import React from 'react'
import styles from './custmersComponent.module.scss'
import Tags from "../../../../Components/Tags/Tags";
import FilterBox from '../../../../Components/FilterBox/FilterBox';

function Billings() {
  const searchIcon = [
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.25 3.3125C5.24695 3.3125 2.8125 5.74695 2.8125 8.75C2.8125 11.753 5.24695 14.1875 8.25 14.1875C9.72046 14.1875 11.0546 13.6038 12.0333 12.6555C12.0502 12.6325 12.069 12.6105 12.0898 12.5898C12.1105 12.569 12.1325 12.5502 12.1555 12.5333C13.1038 11.5546 13.6875 10.2205 13.6875 8.75C13.6875 5.74695 11.253 3.3125 8.25 3.3125ZM13.2712 12.9757C14.233 11.834 14.8125 10.3597 14.8125 8.75C14.8125 5.12563 11.8744 2.1875 8.25 2.1875C4.62563 2.1875 1.6875 5.12563 1.6875 8.75C1.6875 12.3744 4.62563 15.3125 8.25 15.3125C9.85967 15.3125 11.334 14.733 12.4757 13.7712L15.3523 16.6477C15.5719 16.8674 15.9281 16.8674 16.1477 16.6477C16.3674 16.4281 16.3674 16.0719 16.1477 15.8523L13.2712 12.9757Z"
        fill="#737373"
      />
    </svg>,
  ];
  return (
    <>
      <div className={styles.countBar}>
        <p>
          Total <b>21</b>
        </p>

        <div className={styles.buttons}>
          <div className={styles.wrap}>
            <input
              type="text"
              placeholder="Search Here..."
            />
            {searchIcon}
          </div>
          <FilterBox />
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>Invoice No.</th>
            <th>Property Name</th>
            <th>Customer Name</th>
            <th>Date</th>
            <th>Status</th>
            <th>Amount</th>
            <th>Action</th>
          </tr>
        </thead>
        <tr>
          <td>Property 1</td>

          <td className={styles.propertyFflex}>
            <img src="https://res.cloudinary.com/dizdephou/raw/upload/v1713619597/kjj3efbytqpjq90y85ax.jpg" alt="" />
            Property 1
          </td>
          <td className={styles.propertyFflex2}>
            <div>
              <img src="https://res.cloudinary.com/dizdephou/raw/upload/v1713619597/kjj3efbytqpjq90y85ax.jpg" alt="" />
              New York, NY
            </div>
          </td>

          <td>
            <div className={styles.tabDate}>
              <h3>13 Jul 2023</h3>
              <p>Friday</p>
            </div>
          </td>
          <td>
            <div className={styles.tabData}>
              <Tags
                green={"Residence" === "residency" && true}
                purple={"Residence" === "commercial" && true}
                red={"Residence" === "apartment" && true}
                text="Residence"
              />
            </div>
          </td>
          <td>$100,000</td>
          <td>
            <div className={styles.tabData}>
              <img
                src="/assets/svg/action2.svg"
                alt=""

              />
              <img
                src="/assets/svg/action3.svg"
                alt=""

              />
            </div>
          </td>
        </tr>
        <tr>
          <td>Property 1</td>

          <td className={styles.propertyFflex}>
            <img src="https://res.cloudinary.com/dizdephou/raw/upload/v1713619597/kjj3efbytqpjq90y85ax.jpg" alt="" />

            Property 2</td>
          <td className={styles.propertyFflex2}>
            <div>

              <img src="https://res.cloudinary.com/dizdephou/raw/upload/v1713619597/kjj3efbytqpjq90y85ax.jpg" alt="" />
              Los Angeles, CA</div>
          </td>
          <td>
            <div className={styles.tabDate}>
              <h3>13 Jul 2023</h3>
              <p>Friday</p>
            </div>
          </td>
          <td>
            <div className={styles.tabData}>
              <Tags
                green={"Residence" === "residency" && true}
                purple={"Residence" === "commercial" && true}
                red={"Residence" === "apartment" && true}
                text="Residence"
              />
            </div>
          </td>
          <td>$250,000</td>
          <td>
            <div className={styles.tabData}>
              <img
                src="/assets/svg/action2.svg"
                alt=""

              />
              <img
                src="/assets/svg/action3.svg"
                alt=""

              />
            </div>
          </td>
        </tr>
        <tr>
          <td>Property 1</td>

          <td className={styles.propertyFflex}>
            <img src="https://res.cloudinary.com/dizdephou/raw/upload/v1713619597/kjj3efbytqpjq90y85ax.jpg" alt="" />
            Property 3</td>
          <td className={styles.propertyFflex2}>
            <div>
              <img src="https://res.cloudinary.com/dizdephou/raw/upload/v1713619597/kjj3efbytqpjq90y85ax.jpg" alt="" />
              Chicago, IL
            </div>
          </td>
          <td>

            <div className={styles.tabDate}>
              <h3>13 Jul 2023</h3>
              <p>Friday</p>
            </div>
          </td>

          <td>
            <div className={styles.tabData}>
              <Tags
                green={"Residence" === "residency" && true}
                purple={"Residence" === "commercial" && true}
                red={"Residence" === "apartment" && true}
                text="Residence"
              />
            </div>
          </td>
          <td>$50,000</td>
          <td>
            <div className={styles.tabData}>
              <img
                src="/assets/svg/action2.svg"
                alt=""

              />
              <img
                src="/assets/svg/action3.svg"
                alt=""

              />
            </div>
          </td>
        </tr>
        <tr>
          <td>Property 1</td>

          <td className={styles.propertyFflex}>
            <img src="https://res.cloudinary.com/dizdephou/raw/upload/v1713619597/kjj3efbytqpjq90y85ax.jpg" alt="" />
            Property 4</td>

          <td className={styles.propertyFflex2}>
            <div>
              <img src="https://res.cloudinary.com/dizdephou/raw/upload/v1713619597/kjj3efbytqpjq90y85ax.jpg" alt="" />
              Miami, FL
            </div></td>
          <td>

            <div className={styles.tabDate}>
              <h3>13 Jul 2023</h3>
              <p>Friday</p>
            </div>
          </td>

          <td>
            <div className={styles.tabData}>
              <Tags
                green={"Residence" === "residency" && true}
                purple={"Residence" === "commercial" && true}
                red={"Residence" === "apartment" && true}
                text="Residence"
              />
            </div>
          </td>
          <td>$300,000</td>
          <td>
            <div className={styles.tabData}>
              <img
                src="/assets/svg/action2.svg"
                alt=""

              />
              <img
                src="/assets/svg/action3.svg"
                alt=""

              />
            </div>
          </td>
        </tr>
      </table>

    </>
  )
}

export default Billings