import React, { useEffect, useState } from "react";
import styles from "./AddAgent.module.scss";
import InputField from "../../../Components/Input/Input";
import Button from "../../../Components/Button/Button";
import ErrorToast from "../../../Components/Toast/ErrorToast";
import PageLoader from "../../../Components/PageLoader/PageLoader";
import SuccessToast from "../../../Components/Toast/SuccessToast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

import {
  addUser,
  clearState,
  getUserDetails,
  updateSpecificUser,
  userSelector,
} from "../../../reducer/userSlice";
import { getProjectsList, projectData } from "../../../reducer/projectSlice";

function AddAgent({ isEdit }) {
  const router = useNavigate();
  const { userId } = useParams();

  const {
    isFetching,
    isError,
    errorMessage,
    isUserAdded,
    isUserUpdated,
    userData,
  } = useSelector(userSelector);

  const { projectsList, ...proData } = useSelector(projectData);

  //toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      setIsShowError(true);
      setErrMessage(errorMessage);
    }
  }, [isError]);

  useEffect(() => {
    if (isUserUpdated) {
      setIsShowSuccess(true);
      setSuccessMessage("Agent Updated Successfully");
      setTimeout(() => {
        router(-1);
      }, 1000);
      dispatch(clearState());
    }
  }, [isUserUpdated]);

  useEffect(() => {
    if (isUserAdded) {
      setIsShowSuccess(true);
      setSuccessMessage("Agent Created Successfully");
      setTimeout(() => {
        router(-1);
      }, 1000);
      dispatch(clearState());
    }
  }, [isUserAdded]);

  useEffect(() => {
    dispatch(clearState());
    dispatch(getProjectsList());
    if (isEdit) {
      dispatch(getUserDetails(userId));
    }
  }, []);

  useEffect(() => {
    if (userData) {
      setUserName(userData?.name);
      setEmail(userData?.email);
      setmobile(userData?.mobileNumber);
      setAddress(userData?.address);
      setPincode(userData?.pincode);
      setProfileImg(userData?.profileImg);
      if (userData?.projects) {
        const formattedProjects = userData.projects.map(project => ({
          projectId: project?.id,
          scheme: project?.userProjects?.scheme ?? '-',
          commision: project?.userProjects?.commision ?? 0
        }));
        setProjects(formattedProjects);
      }
      dispatch(clearState());
    }
  }, [userData]);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  // State for form fields
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setmobile] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [profileImg, setProfileImg] = useState(null);
  const [projects, setProjects] = useState([
    { projectId: "", scheme: "", commision: "" },
  ]);

  const handleProjectChange = (index, field, value) => {
    const newProjects = [...projects];
    if (field === "commision") {
      if (value > 100) {
        value = 100;
      } else if (value < 0) {
        value = 0;
      }
    }
    newProjects[index][field] = value;
    setProjects(newProjects);
  };

  const handleAddProject = () => {
    setProjects([...projects, { projectId: "", scheme: "", commision: "" }]);
  };

  const handleRemoveProject = (index) => {
    const newProjects = projects.filter((_, i) => i !== index);
    setProjects(newProjects);
  };

  const handleSubmit = () => {
    try {
      const formData = {
        name: userName,
        email,
        mobileNumber: mobile,
        address,
        pincode,
        profileImg,
        projects: projects,
      };
      console.log("formData: ", formData);
      if (
        !userName ||
        !mobile ||
        !email ||
        !address ||
        !pincode ||
        projects.some((p) => !p.projectId || !p.scheme || !p.commision)
      ) {
        throw new Error("All fields are required.");
      }
      if (isEdit) {
        dispatch(updateSpecificUser({ obj: formData, userId }));
      } else {
        dispatch(addUser({ obj: formData, roleName: "agent" }));
      }
    } catch (error) {
      console.error("Error adding user:", error.message);
      setIsShowError(true);
      setErrMessage(error.message);
    }
  };

  const handleUploadImg = (e) => {
    try {
      let file = e.target.files[0];
      if (file) {
        console.log(" size: ", file.size / 1000000);
        if (file.size / 1000000 > 10) {
          throw new Error("File size should not exceed 10 Mb");
        }
        setIsLoading(true);
        const data = new FormData();

        data.append("file", file);
        data.append("upload_preset", "qg8p7tcq");
        data.append("cloud_name", "dizdephou");

        fetch("https://api.cloudinary.com/v1_1/dizdephou/raw/upload", {
          method: "POST",
          body: data,
        })
          .then((res) => res.json())
          .then((res) => {
            setIsLoading(false);
            setProfileImg(res.secure_url);
          });
      }
    } catch (err) {
      setIsLoading(false);
      setIsShowError(true);
      setErrMessage(err.message);
    }
  };

  const getFilteredProjectOptions = (index) => {
    const selectedProjectIds = projects
      .map((p) => p.projectId)
      .filter((id, i) => i !== index);
    return projectsList
      .filter((proj) => !selectedProjectIds.includes(proj.id))
      .map((proj) => ({ value: proj.id, label: proj.name }));
  };

  const getSelectedProjectOption = (projectId) => {
    const project = projectsList.find((proj) => proj.id === projectId);
    return project ? { value: project.id, label: project.name } : null;
  };

  return (
    <>
      {(proData?.isFetching || isFetching || isLoading) && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <SuccessToast
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessage}
      />
      <div className={styles.formWrapper}>
        <h2>{isEdit ? "Edit" : "Add"} Agent</h2>
        <div className={styles.inputFormWrapper}>
          <div className={styles.inputFieldWrapper}>
            <label className={styles.customUser}>
              <span>Avatar</span>
              <div>
                <img
                  src={profileImg ?? "/assets/svg/uploadUser.svg"}
                  alt="profile Img"
                />
              </div>
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={handleUploadImg}
              />
            </label>
          </div>
          <div className={styles.inputFieldWrapper}>
            <InputField
              labelName={"Full Name"}
              placeholder={"Enter Full Name"}
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
            <InputField
              type={"email"}
              labelName={"Email"}
              placeholder={"Enter Email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={styles.inputFieldWrapper}>
            <InputField
              type={"tel"}
              maxlength={15}
              labelName={"Mobile"}
              placeholder={"Enter Mobile"}
              value={mobile}
              onChange={(e) => setmobile(e.target.value)}
            />
          </div>
          <div className={styles.inputFieldWrapper}>
            <InputField
              labelName={"Address"}
              placeholder={"Enter short Address"}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <InputField
              labelName={"Pincode"}
              type={"number"}
              placeholder={"Enter Pincode"}
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
            />
          </div>

          <div className={styles.projectWrapper}>
            <span className={styles.newlabel}>Projects Governed *</span>
            {projects.map((project, index) => (
              <div key={index} className={styles.projectItem}>
                {/* Single-select dropdown with filtered options */}
                <Select
                  className={styles.selectProject}
                  options={getFilteredProjectOptions(index)}
                  value={getSelectedProjectOption(project.projectId)}
                  onChange={(selectedOption) =>
                    handleProjectChange(
                      index,
                      "projectId",
                      selectedOption.value
                    )
                  }
                  placeholder="Select Project"
                />
                <div className={styles.schemeWrap}>

                  <InputField
                    labelName={"Scheme"}
                    placeholder={"Enter Scheme Name"}
                    value={project.scheme}
                    onChange={(e) =>
                      handleProjectChange(index, "scheme", e.target.value)
                    }
                  />
                  <InputField
                    labelName={"Commission"}
                    placeholder={"Enter Commission"}
                    type={"number"}
                    value={project.commision}
                    onChange={(e) =>
                      handleProjectChange(index, "commision", e.target.value)
                    }
                  />
                </div>

                {index > 0 && (
                  <button
                    type="button"
                    className={styles.removeProjectred}
                    onClick={() => handleRemoveProject(index)}
                  >
                    Remove
                  </button>
                )}
              </div>
            ))}
            <button
              type="button"
              className={styles.removeProjectnew}
              onClick={handleAddProject}
            >
              Add Project
            </button>
          </div>

          <div className={styles.ButtonWrapper}>
            <div className={styles.buttonRight}>
              <Button greyButton text={"Discard"} />
              <Button blueBtnLight text={"Save"} onClick={handleSubmit} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddAgent;
