import React from 'react'
import common from './CommonHead.module.scss'
import Button from '../Button/Button'
import { Flexbox } from '../TagsComponent/TagsComponent'
import { Btn1, Btn2 } from './viewBtn'

const CommonHead = ({
    Heading,
    ProjectCount,
    buttonText,
    customMsg,
    onButtonClick,
    hasViewBtn,
    setView,
    view,
    noSideButton
}) => {
  return (
    <div className={common.headerWrap}>
        <div className={common.Left}>
            <h4>{Heading}</h4>
            
            {ProjectCount && <p>{customMsg ? customMsg : 'Project -'}  <strong>{ProjectCount}</strong></p>}
        </div>

        <div className={`${common.Right} ${hasViewBtn && common.addFlex}`}>
            {
              hasViewBtn && 
              <Flexbox flexStart alignCenter gap={5}>
                <span style={{lineHeight: '0'}} onClick={()=> setView(true)}><Btn1 color={view && '#0E77D3'} /></span>
                <span style={{lineHeight: '0'}} onClick={()=> setView(false)}><Btn2 color={!view && '#0E77D3'} /></span>
              </Flexbox>
            }
            {
              !noSideButton && <Button blueBtnLight icon={'add'} text={buttonText} onClick={onButtonClick}/>
            }
        </div>
    </div>
  )
}

export default CommonHead