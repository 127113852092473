const Booking = [
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Property Name 1',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Property Name 1',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Property Name 1',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Property Name 1',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Property Name 1',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Property Name 1',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Property Name 1',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Property Name 1',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Property Name 1',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Property Name 1',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Property Name 1',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Property Name 1',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Property Name 1',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        agent: 'Rajat Kulkarni',
        agentImg: 'tb1',
        propertyName: 'Property Name 1',
        propertyImg: 'tb1',
        city: 'Ahmedabad',
    },
    
]

export default Booking;