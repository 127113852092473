import React from "react";
import "./PageLoaderStyle.css";
import Lottie from "lottie-react";
import groovyWalkAnimation from "./loaderAnim.json";

const PageLoader = () => {
  return (
    <div className="preloader-wrapper">
      <div className="cssloader">
        {/* <img src="/assets/img/pageLoader.gif" alt="page_loader" style={{width: '400px'}}/> */}
        <Lottie animationData={groovyWalkAnimation} loop={true} style={{maxWidth: '400px'}}/>
      </div>
    </div>
  );
};
export default PageLoader;