import React, { useEffect, useState } from "react";
import styles from "./documentation.module.scss";
import CommonHead from "../../../Components/CommonHead/CommonHead";
import Filter from "../../../Components/Filter/Filter";
import { useNavigate } from "react-router-dom";
import Tags from "../../../Components/Tags/Tags";
import { useDispatch, useSelector } from "react-redux";
import { getAllBookings, projectData } from "../../../reducer/projectSlice";
import Tablepagination from "../../../Components/pagination/Tablepagination";
import PageLoader from "../../../Components/PageLoader/PageLoader";
import RoleService from "../../../helpers/RoleServicec";

const Documentation = () => {
  const router = useNavigate();

  const dispatch = useDispatch();

  const role = RoleService.getRoleName();
  //pagination states
  const [index, setIndex] = useState(1);

  //filter states
  const [filters, setFilters] = useState({
    projectId: "",
    propertyId: "",
    customerId: "",
    searchText: "",
  });
  console.log("filters: ", filters);

  const { isFetching, allBookings } = useSelector(projectData);

  useEffect(() => {
    dispatch(
      getAllBookings({
        index,
        query: { ...filters },
        search: filters?.searchText,
      })
    );
  }, [index, filters]);

  return (
    <>
      {isFetching && <PageLoader />}
      <div className={styles.bookingWrap}>
        <CommonHead
          Heading={"Details of Ownership"}
          customMsg={"Properties bought"}
          ProjectCount={allBookings?.totalCount}
          buttonText={"Add Bookings"}
          noSideButton={role != "agent"}
          onButtonClick={() => router(`/agent/addBooking`)}
        />
        <Filter className={`${styles.bookingFilter} mt20`} onFilterChange={setFilters} />

        <div className={`${styles.tableWrap} mt20`}>
          <table className={styles.bookingTable}>
            <thead>
              <tr>
                <th>Sr.no</th>
                <th>Property Name</th>
                <th>Costumer Name</th>
                <th>Location</th>
                <th>Documents Uploaded</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {allBookings?.bookings?.map((item, index) => (
                <tr key={item?.id}>
                  <td>
                    <div className={styles.tabData}>{index + 1}</div>
                  </td>
                  <td>
                    <div className={styles.tabData} onClick={() => router(`/${role}/property/${item?.property?.id}`)}>
                      <img
                        src={item?.property?.media?.[0]?.mediaUrl}
                        alt="proImg"
                      />
                      {item?.property.name}
                    </div>
                  </td>
                  <td>
                    <div className={styles.tabData} onClick={() => router(`/${role}/customers/${item?.customer?.id}`)}>
                      <img
                        src={
                          item?.customer.profileImg ?? "/assets/img/user1.png"
                        }
                        alt="profileImg"
                      />
                      {item?.customer.name}
                    </div>
                  </td>
                  <td>
                    <div className={styles.tabData}>
                      {item?.property.address}
                    </div>
                  </td>
                  <td>
                    <div className={styles.tabData}>
                      <Tags purple text={"Receipt"} />
                      <Tags yellow text={"Booking Form"} />
                    </div>
                  </td>
                  <td>
                    <div className={styles.tabData}>
                      <img
                        className="pointer"
                        src="/assets/svg/action1.svg"
                        alt=""
                        onClick={() => router(`/agent/ownerShip/${item?.id}`)}
                      />
                      {/* <img src="/assets/svg/action2.svg" alt="" /> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Tablepagination
        itemsPerPage={10}
        totalItems={allBookings?.totalCount}
        setIndex={setIndex}
        itemName={"Bookings"}
      />
    </>
  );
};

export default Documentation;
