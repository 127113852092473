import React from 'react'
import styles from './Login.module.scss'
import Input from '../../Components/InputField/Input'
import Button from '../../Components/Button/Button'
import { Link } from 'react-router-dom'

const ForgetPass = () => {
    return (
        <div className={styles.forgetWrap}>
            <img src="/assets/svg/logoDark.svg" alt="" />

            <div className={`${styles.forgetBox}`}>
                <div className={styles.forgetHead}>
                    <h4>Forgot Password?</h4>
                    <p>No worries! We will send you reset instructions</p>
                </div>
                <Input
                    label={'Email Id'}
                    placeholder={'Enter your Email Id'}
                    id={'Email'}
                />
                <Button blueButton text={'Reset Password'} />
                <Link className={styles.goBack} to={'/'}>
                    <img src="/assets/svg/backArrowLeft.svg" alt="" />
                    Back to Login
                </Link>
            </div>

            <div className={styles.blank}></div>
        </div>
    )
}

export default ForgetPass