const addMessage = [
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        prop: 'Rajat Kulkarni',
        propImg: 'tb1',
        city: 'Ahmedabad'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        prop: 'Rajat Kulkarni',
        propImg: 'tb1',
        city: 'Ahmedabad'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        prop: 'Rajat Kulkarni',
        propImg: 'tb1',
        city: 'Ahmedabad'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        prop: 'Rajat Kulkarni',
        propImg: 'tb1',
        city: 'Ahmedabad'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        prop: 'Rajat Kulkarni',
        propImg: 'tb1',
        city: 'Ahmedabad'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        prop: 'Rajat Kulkarni',
        propImg: 'tb1',
        city: 'Ahmedabad'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        prop: 'Rajat Kulkarni',
        propImg: 'tb1',
        city: 'Ahmedabad'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        prop: 'Rajat Kulkarni',
        propImg: 'tb1',
        city: 'Ahmedabad'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        prop: 'Rajat Kulkarni',
        propImg: 'tb1',
        city: 'Ahmedabad'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        prop: 'Rajat Kulkarni',
        propImg: 'tb1',
        city: 'Ahmedabad'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        prop: 'Rajat Kulkarni',
        propImg: 'tb1',
        city: 'Ahmedabad'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        prop: 'Rajat Kulkarni',
        propImg: 'tb1',
        city: 'Ahmedabad'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        prop: 'Rajat Kulkarni',
        propImg: 'tb1',
        city: 'Ahmedabad'
    },
    {
        custName: 'Rashmika Gulati',
        custImg: 'tb1',
        prop: 'Rajat Kulkarni',
        propImg: 'tb1',
        city: 'Ahmedabad'
    },
    
]

export default addMessage;