import styles from "../Project.module.scss";
import FilterBox from "../../../../Components/FilterBox/FilterBox";
import { useDispatch, useSelector } from "react-redux";
import { getAllReceipts, projectData } from "../../../../reducer/projectSlice";
import { useEffect, useState } from "react";
import Tablepagination from "../../../../Components/pagination/Tablepagination";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import Reciptpop from "../../../../Components/Popup/Reciptpop/Reciptpop";
import RoleService from "../../../../helpers/RoleServicec";

function Receipts({ active }) {
  const role = RoleService.getRoleName();
  const navigate = useNavigate();
  const searchIcon = [
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.25 3.3125C5.24695 3.3125 2.8125 5.74695 2.8125 8.75C2.8125 11.753 5.24695 14.1875 8.25 14.1875C9.72046 14.1875 11.0546 13.6038 12.0333 12.6555C12.0502 12.6325 12.069 12.6105 12.0898 12.5898C12.1105 12.569 12.1325 12.5502 12.1555 12.5333C13.1038 11.5546 13.6875 10.2205 13.6875 8.75C13.6875 5.74695 11.253 3.3125 8.25 3.3125ZM13.2712 12.9757C14.233 11.834 14.8125 10.3597 14.8125 8.75C14.8125 5.12563 11.8744 2.1875 8.25 2.1875C4.62563 2.1875 1.6875 5.12563 1.6875 8.75C1.6875 12.3744 4.62563 15.3125 8.25 15.3125C9.85967 15.3125 11.334 14.733 12.4757 13.7712L15.3523 16.6477C15.5719 16.8674 15.9281 16.8674 16.1477 16.6477C16.3674 16.4281 16.3674 16.0719 16.1477 15.8523L13.2712 12.9757Z"
        fill="#737373"
      />
    </svg>,
  ];

  const dispatch = useDispatch();

  const { projectId, customerId } = useParams();

  //pagination states
  const [index, setIndex] = useState(1);

  //popup
  const [Show, setShow] = useState(false);
  const [bookingId, setBookingId] = useState(null);

  //filter states
  const [filters, setFilters] = useState({
    query: {},
    searchText: "",
  });

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setFilters({ searchText: event.target.value });
    }
  };

  const { allReceipts } = useSelector(projectData);

  useEffect(() => {
    dispatch(
      getAllReceipts({
        index,
        query: {
          ...filters?.query,
          projectId: projectId ?? "",
          customerId: customerId ?? "",
        },
        search: filters?.searchText,
      })
    );
  }, [index, filters]);

  return (
    <>
      {Show && (
        <Reciptpop
          show={true}
          handleClose={() => setShow(false)}
          bookingId={bookingId}
        />
      )}
      <div className={styles.propertyTable}>
        <div className={styles.countBar}>
          <p>
            Total Receipts <b>{allReceipts?.totalCount}</b>
          </p>

          <div className={styles.buttons}>
            <div className={styles.wrap}>
              <input
                type="text"
                placeholder="Search Here..."
                onKeyDown={handleKeyPress}
              />
              {searchIcon}
            </div>
            {/* <Button whiteButton text={'Filter'} icon={'filter-lines'} maxWidth={90} padding={10} height={'auto'} /> */}
            {/* <FilterBox active={active} onFilterChange={setFilters} /> */}
          </div>
        </div>

        <div className={styles.tableWrap}>
          <table className={styles.bookingTable}>
            <thead>
              <tr>
                <th>Receipt No.</th>
                <th>Property Name</th>
                <th>Customer Name</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {allReceipts?.receipt?.map((item, index) => (
                <tr key={item?.id}>
                  <td>
                    <div className={styles.tabData}>
                      {"#INV" + item?.receiptNo}
                    </div>
                  </td>
                  <td>
                    <div
                      className={styles.tabData}
                      onClick={() =>
                        navigate(`/${role}/property/${item?.property?.id}`)
                      }
                    >
                      <img
                        src={item?.property?.media?.[0]?.mediaUrl}
                        alt="proImg"
                      />
                      {item?.property.name}
                    </div>
                  </td>
                  <td>
                    <div className={styles.tabData}>
                      <img
                        src={item?.user?.profileImg ?? "/assets/img/user1.png"}
                        alt="profileImg"
                      />
                      {item?.user.name}
                    </div>
                  </td>
                  <td>
                    <div className={styles.tabData}>
                      {moment(item?.createdAt).format("DD MMM YYYY")}
                    </div>
                  </td>
                  <td className={styles.greenText}>
                    <div className={styles.tabData}>₹ {item?.amount}</div>
                  </td>
                  <td>
                    <div className={styles.tabData}>
                      <img
                        className="pointer"
                        src="/assets/svg/action1.svg"
                        alt=""
                        onClick={() => {
                          setBookingId(item?.bookingId);
                          setShow(true);
                        }}
                      />
                      {/* <img src="/assets/svg/action2.svg" alt="" /> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Tablepagination
        itemsPerPage={10}
        totalItems={allReceipts?.totalCount}
        setIndex={setIndex}
        itemName={"Receipts"}
      />
    </>
  );
}

export default Receipts;
