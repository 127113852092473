import React, { useEffect, useState } from "react";
import styles from "./filter.module.scss";
import filter from "../Filter/Filter.module.scss";
import Button from "../Button/Button";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProAgeCusByProject,
  projectData,
} from "../../reducer/projectSlice";
import { useParams } from "react-router-dom";

const FilterBox = ({ active, onFilterChange }) => {
  const [filterBtn, setFilterBtn] = useState(false);

  const [propertyId, setPropertyId] = useState("");
  const [agentId, setAgentId] = useState("");
  const [customerId, setCustomerId] = useState("");

  const arrowDown = [
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.60225 6.45226C8.82192 6.22913 9.17808 6.22913 9.39775 6.45226L13.8977 11.0231C14.1174 11.2462 14.1174 11.6079 13.8977 11.8311C13.6781 12.0542 13.3219 12.0542 13.1023 11.8311L9 7.66427L4.89775 11.8311C4.67808 12.0542 4.32192 12.0542 4.10225 11.8311C3.88258 11.6079 3.88258 11.2462 4.10225 11.0231L8.60225 6.45226Z"
        fill="#737373"
      />
    </svg>,
  ];

  const { projectId } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    if ((active == 3 || active == 6) && projectId) {
      dispatch(getAllProAgeCusByProject(projectId));
    }
  }, []);

  const { filterData } = useSelector(projectData);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionChange = (optionValue) => {
    // Check if the option is already selected
    const index = selectedOptions.indexOf(optionValue);
    if (index === -1) {
      // If not selected, add it to the selectedOptions array
      setSelectedOptions([...selectedOptions, optionValue]);
    } else {
      // If already selected, remove it from the selectedOptions array
      const updatedOptions = [...selectedOptions];
      updatedOptions.splice(index, 1);
      setSelectedOptions(updatedOptions);
    }
  };

  const applyFilters = () => {
    onFilterChange({
      type: selectedOptions,
      query: { propertyId, agentId, customerId },
    });
  };

  const handleReset = () => {
    setSelectedOptions([]);
  };

  return (
    <div className={styles.buttonFilterWrap}>
      <Button
        whiteButton
        text={"Filter"}
        icon={"filter-lines"}
        maxWidth={90}
        padding={10}
        height={"auto"}
        onClick={() => setFilterBtn(!filterBtn)}
      />
      <div className={`${styles.filterBoxWrap} ${filterBtn && styles.active}`}>
        <h4>Filter Options</h4>

        <div className={styles.filterInside}>
          {active === 2 && (
            <>
              {/* <div className={styles.selectorBox}>
                                <label htmlFor="" className={styles.labelz}>Location:</label>
                                <div className={`${filter.selectorWrapper} ${filterBtn && styles.selectorWrap}`}>
                                    <span>{arrowDown}</span>
                                    <select name="location" id="location">
                                        <option value={'value'}>Select Location</option>
                                        <option value={'value'}>Saab</option>
                                        <option value={'value'}>Mercedes</option>
                                        <option value={'value'}>Audi</option>
                                    </select>
                                </div>
                            </div> */}

              <div className={`${styles.selectorBox} `}>
                <label htmlFor="" className={styles.labelz}>
                  Property Type:
                </label>
                <div className={`${styles.propertyType}`}>
                  <CustomCheckbox
                    className={styles.filterSelector}
                    names={"Residency / Villa"}
                    value={"residency"}
                    isChecked={selectedOptions.includes("residency")}
                    onOptionChange={() => handleOptionChange("residency")}
                  />
                  <CustomCheckbox
                    className={styles.filterSelector}
                    names={"Apartment"}
                    value={"apartment"}
                    isChecked={selectedOptions.includes("apartment")}
                    onOptionChange={() => handleOptionChange("apartment")}
                  />
                  <CustomCheckbox
                    className={styles.filterSelector}
                    names={"Commercial Space"}
                    value={"commercial"}
                    isChecked={selectedOptions.includes("commercial")}
                    onOptionChange={() => handleOptionChange("commercial")}
                  />
                </div>
              </div>
            </>
          )}

          {(active === 3 || active == 6) && (
            <>
              <div className={`${styles.selectorBox} mt20`}>
                <label htmlFor="" className={styles.labelz}>
                  Property:
                </label>
                <div
                  className={`${filter.selectorWrapper} ${
                    filterBtn && styles.selectorWrap
                  }`}
                >
                  <span>{arrowDown}</span>
                  <select
                    name="property"
                    id="property"
                    onChange={(e) => setPropertyId(e.target.value)}
                    value={propertyId}
                  >
                    <option value={""}>Select Property</option>
                    {filterData?.property?.map((pro) => (
                      <option value={pro.id}>{pro.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              {active !== 6 && (
                <div className={`${styles.selectorBox} mt20`}>
                  <label htmlFor="" className={styles.labelz}>
                    Agent:
                  </label>
                  <div
                    className={`${filter.selectorWrapper} ${
                      filterBtn && styles.selectorWrap
                    }`}
                  >
                    <span>{arrowDown}</span>
                    <select
                      name="agent"
                      id="agent"
                      onChange={(e) => setAgentId(e.target.value)}
                      value={agentId}
                    >
                      <option value={""}>Select Agent</option>
                      {filterData?.agents?.map((pro) => (
                        <option value={pro.id}>{pro.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              <div className={`${styles.selectorBox} mt20`}>
                <label htmlFor="" className={styles.labelz}>
                  Customer:
                </label>
                <div
                  className={`${filter.selectorWrapper} ${
                    filterBtn && styles.selectorWrap
                  }`}
                >
                  <span>{arrowDown}</span>
                  <select
                    name="cust"
                    id="cust"
                    onChange={(e) => setCustomerId(e.target.value)}
                    value={customerId}
                  >
                    <option value={""}>Select Customer</option>
                    {filterData?.customers?.map((pro) => (
                      <option value={pro.id}>{pro.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </>
          )}

          {active === 4 && (
            <>
              <div className={styles.selectorBox}>
                <label htmlFor="" className={styles.labelz}>
                  City:
                </label>
                <div
                  className={`${filter.selectorWrapper} ${
                    filterBtn && styles.selectorWrap
                  }`}
                >
                  <span>{arrowDown}</span>
                  <select name="location" id="location">
                    <option value={"value"}>Select Option</option>
                    <option value={"value"}>Saab</option>
                    <option value={"value"}>Mercedes</option>
                    <option value={"value"}>Audi</option>
                  </select>
                </div>
              </div>

              <div className={`${styles.selectorBox} mt20`}>
                <label htmlFor="" className={styles.labelz}>
                  Commission:
                </label>
                <div
                  className={`${filter.selectorWrapper} ${
                    filterBtn && styles.selectorWrap
                  }`}
                >
                  <span>{arrowDown}</span>
                  <select name="location" id="location">
                    <option value={"value"}>Select Option</option>
                    <option value={"value"}>Saab</option>
                    <option value={"value"}>Mercedes</option>
                    <option value={"value"}>Audi</option>
                  </select>
                </div>
              </div>

              <div className={`${styles.selectorBox} mt20`}>
                <label htmlFor="" className={styles.labelz}>
                  Location:
                </label>
                <div className={`${styles.propertyType}`}>
                  <CustomCheckbox
                    className={styles.filterSelector}
                    names={"Residency / Villa"}
                  />
                  <CustomCheckbox
                    className={styles.filterSelector}
                    names={"Apartment"}
                  />
                  <CustomCheckbox
                    className={styles.filterSelector}
                    names={"Commercial Space"}
                  />
                  <CustomCheckbox
                    className={styles.filterSelector}
                    names={"Plot"}
                  />
                </div>
              </div>
            </>
          )}

          <div className={styles.filterButtonGroup}>
            <Button
              greyButton
              height={"auto"}
              text={"Reset"}
              padding={8}
              onClick={handleReset}
              fontSize={13}
              maxWidth={70}
            />
            <Button
              blueBtnLight
              height={"auto"}
              text={"Apply"}
              onClick={applyFilters}
              padding={8}
              fontSize={13}
              maxWidth={70}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBox;
