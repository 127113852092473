import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import RoleService from "./RoleServicec";

export const AdminPrivateRoute = () => {
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  const role = RoleService.getRoleName();
  if (role) {
    if (role === "admin") return <Outlet />
  }
  localStorage.clear();
  return <Navigate to="/login" />
};