import React, { useEffect, useState } from "react";
import styles from "./Project.module.scss";
import About from "./ProjectComponets/About";
import Properties from "./ProjectComponets/Properties";
import Agents from "./ProjectComponets/Agents";
import Customers from "./ProjectComponets/Customers";
import Receipts from "./ProjectComponets/Receipts";
import StatusUpdate from "./ProjectComponets/StatusUpdate";
import Billing from "./ProjectComponets/Billing";
import BookingPage from "./ProjectComponets/Booking";
import CommonHead from "../../../Components/CommonHead/CommonHead";
import { useNavigate ,useParams} from "react-router-dom";
import { clearState, projectData } from "../../../reducer/projectSlice";
import PageLoader from "../../../Components/PageLoader/PageLoader";
import { useSelector,useDispatch } from "react-redux";
import ErrorToast from "../../../Components/Toast/ErrorToast";
import SuccessToast from "../../../Components/Toast/SuccessToast";
import RoleService from "../../../helpers/RoleServicec";

const role = RoleService.getRoleName();

const Project = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(1);
  const router = useNavigate();
  const { projectId } = useParams();

  const { isFetching,isSuccess,projectDetails, isError, errorMessage } = useSelector(projectData);

  //Toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      setIsShowError(true);
      setErrMessage(errorMessage);
    }
  }, [isError]);

  return (
    <>
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <SuccessToast
        show={isShowSuccess}
        setShow={() => setIsShowSuccess(false)}
        message={successMessage}
      />
      <div className={styles.projectPrevWrap}>
        <CommonHead
          Heading={"Project"}
          ProjectCount={projectDetails?.name}
          buttonText={"Add Property"}
          onButtonClick={() => router("/admin/addproperty/"+projectId)}
          noSideButton={role !="admin"}
        />

        <div className={styles.projectOverview}>
          <ul className={styles.projectTabs}>
            <li
              className={active === 1 && styles.active}
              onClick={() => setActive(1)}
            >
              About Project
            </li>
            <li
              className={active === 2 && styles.active}
              onClick={() => setActive(2)}
            >
              Properties
            </li>
            <li
              className={active === 3 && styles.active}
              onClick={() => setActive(3)}
            >
              Bookings
            </li>
            <li
              className={active === 4 && styles.active}
              onClick={() => setActive(4)}
            >
              Agents
            </li>
            <li
              className={active === 5 && styles.active}
              onClick={() => setActive(5)}
            >
              Customers
            </li>
            <li
              className={active === 6 && styles.active}
              onClick={() => setActive(6)}
            >
              Receipts
            </li>
            <li
              className={active === 7 && styles.active}
              onClick={() => setActive(7)}
            >
              Status Update
            </li>
            {/* <li
              className={active === 8 && styles.active}
              onClick={() => setActive(8)}
            >
              Billing
            </li> */}
          </ul>
          <div className={styles.projectPages}>
            {(active === 1 && <About />) ||
              (active === 2 && <Properties active={active} />) ||
              (active === 3 && <BookingPage active={active} />) ||
              (active === 4 && <Agents active={active} />) ||
              (active === 5 && <Customers />) ||
              (active === 6 && <Receipts active={active} />) ||
              (active === 7 && <StatusUpdate />) ||
              (active === 8 && <Billing />)}
          </div>
        </div>
      </div>
    </>
  );
};

export default Project;
