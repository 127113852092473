import React from "react";
import "./CustomCheckboxStyle.css";

const CustomCheckbox = ({
  id,
  names,
  isChecked,
  onOptionChange,
  value,
  key,
  className,
  disabled
}) => {
  // const handleChange = (event) => {
  //   onOptionChange(id, event.target.checked);
  // };

  return (
    <label className={`checboxWrap ${disabled === true && 'disable'} ${className}`} htmlFor={id}>
      <input
        disabled={disabled}
        type="checkbox"
        name={id}
        checked={isChecked}
        onChange={onOptionChange}
        key={key}
        id={id}
        value={value}
      />
      <span className="checkmarkNew"></span>
      {names}
    </label>
  );
};

export default CustomCheckbox;
