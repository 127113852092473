import React from 'react'
import styles from './Login.module.scss'
import Input from '../../Components/InputField/Input'
import Button from '../../Components/Button/Button'

const SignupForm = () => {
    return (
        <>
            <div className={styles.buttonGroup}>
                <Button whiteButton icon={'gmail'} text={'Continue with Gmail'} />
                <Button whiteButton icon={'google'} text={'Continue with Google'} />
                <Button whiteButton icon={'apple'} text={'Continue with Apple'} />
                <Button whiteButton icon={'facebook'} text={'Continue with Facebook'} />
            </div>
            <div className={styles.breakerBar}>
                Or
            </div>
            <div className={`${styles.loginForm} ${styles.signupForm}`}>
                <Input
                    placeholder={'Enter your email id'}
                    id={'userName'}
                />
                <Button blueButton text={'Get Started'} />
            </div>
        </>
    )
}

export default SignupForm