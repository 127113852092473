import React from 'react'
import button from './Button.module.scss'

const Button = ({
    className,
    text,
    icon,
    revIcon,
    disabled,
    minWidth,
    maxWidth,
    blueButton,
    blueBorderBtn,
    blueBtnLight,
    blackButton,
    whiteButton,
    background,
    greyButton,
    fontSize,
    padding,
    height,
    onClick
}) => {
  return (
    <button className={`${className} 
        ${revIcon && button.reverseIcon}
        ${blueBorderBtn && button.blueBorderBtn}
        ${blueButton && button.blueBtn}
        ${blueBtnLight && button.blueBtnLight}
        ${blackButton && button.blackBtn}
        ${whiteButton && button.whiteBtn}
        ${greyButton && button.greyBtn}
        ${disabled && button.disabled}
        `}
        disabled={disabled}
        style={{minWidth: `${minWidth}px`, maxWidth: `${maxWidth}px`, height: height, background: background, borderColor: background, fontSize: `${fontSize}px`, padding: `${padding}px`}}
        onClick={onClick}
        >
        {icon && <img src={`/assets/svg/${icon}.svg`} alt={icon} />}
        {text}
    </button>
  )
}

export default Button