// src/Receipt3.js
import React from "react";
import style from "./Receipt.module.scss";

function Receipt3({ data }) {
  const applicationDate = new Date(data.createdAt).toLocaleDateString();
  const currentDate = new Date().toLocaleDateString();
  const plotNo = data.property.plotNo || "391"; // Update as needed
  const projectName = data.property.name || "Shamanta Residency";
  const customerName = data.customer.name;
  const place = "Indore M.P"; // Assuming place as provided in image

  return (
    <div className={style.receiptWrapper}>
      <img src="/assets/svg/shamanta.svg" alt="Shamanta Logo" />

      <div className={style.letterHead}>
        <h6>To</h6>
        <h5>{customerName}</h5>
        {data.customer.address.split(", ").map((line, index) => (
          <p className={style.mt4} key={index}>
            {line}
          </p>
        ))}
      </div>

      <p>Dear Sir/Ma’am</p>

      <div className={style.letterbody}>
        <p>
          With reference to your application no. <b>{data.customer.code}</b>{" "}
          dated {applicationDate} and as per your <b>verbal request and</b>{" "}
          interest of booking property being a member with us, M/S "SHAMANTA
          BUILDERS LLP” here by give a confirmation for the Booking a property
          as described below mention details of our Project{" "}
          <b>"Shamanta Residency"</b> at village Anandpur, (Survey no. 72(Old
          Survey No. 61), Taluka: DHOLERA, District: AHMEDABAD.
        </p>
        <p>
          This booking confirmation letter is valid in subject to the{" "}
          <b>fulfillment of the </b>terms & conditions which has been attached
          herewith. This will prevail our other terms and conditions which have
          been given in our brochures, advertisement, and any other document
          serve to you by our any of authorized sales executive.
        </p>
        <p>
          On Receipt of full and final payment as per the below{" "}
          <b>mentioned payment</b> schedule and as per the Terms & Conditions of
          joining as a member with us against your registration ID. The company
          is bound to provide allotment/possession/Dastavej (Registry) after the
          completion of all installments of your plot.
        </p>
      </div>
      <h2>Booking & Payment Details</h2>
      <div className={style.outerDivWrapOuter}>
        <div className={style.outerDivWrap}>
          <div className={style.contentWrap}>
            <p>Plot No.</p>
            <p className={style.fontblack}>{plotNo}</p>
          </div>
          <div className={style.contentWrap}>
            <p>Area</p>
            <p className={style.fontblack}>
              {data.property.area} {data.property.areaType}
            </p>
          </div>
          {data.property.rate && (
            <div className={style.contentWrap}>
              <p>Rate</p>
              <p className={style.fontblack}>
                ₹ {data.property.rate} / per {data.property.areaType}
              </p>
            </div>
          )}
          <div className={style.contentWrap}>
            <p>Total Value of Property</p>
            <p className={style.fontblack}>₹ {data.property.price}</p>
          </div>
          {data.paymentType === "emi" && (
            <>
              <div className={style.contentWrap}>
                <p>Down Payment</p>
                <p className={style.fontblack}>₹ {data.receipts[0].amount}</p>
              </div>
              <div className={style.contentWrap}>
                <p>Monthly EMI</p>
                <p className={style.fontblack}>₹ {data.emiAmount} / PM</p>
              </div>
              <div className={style.contentWrap}>
                <p>Payment Plan</p>
                <p className={style.fontblack}>
                  Total payment must be clear within {data.emiDurationYears}{" "}
                  years from Booking date
                </p>
              </div>
              <div className={style.contentWrap}>
                <p>Month of First EMI</p>
                <p className={style.fontblack}>
                  {new Date(data.emiStartDate).toLocaleString("default", {
                    month: "long",
                    year: "numeric",
                  }) || "N/A"}
                </p>
              </div>
              <div className={style.contentWrap}>
                <p>Expected Month of Last EMI</p>
                <p className={style.fontblack}>
                  {new Date(data.emiEndDate).toLocaleString("default", {
                    month: "long",
                    year: "numeric",
                  }) || "N/A"}
                </p>
              </div>
            </>
          )}
        </div>
      </div>

      <div className={style.termandConditions}>
        <h2>General Terms and Conditions:</h2>

        <ul>
          {[
            "All payment will have to be paid fully within your committed period from booking date.",
            "Every 3 months it is required to pay 1 mega EMI.",
            "All EMI need to be paid on time every month on the committed date.",
            "At the time of booking whichever payment terms are discussed and confirmed by, that has to be strictly followed by you in future.",
            "If EMI is not paid on time or not responding to us in that case @18% of interest will be charged on your overdue days.",
            "In case if you fail to pay your EMIs more than 3 months without any intimation, we are bound to consider that you are not further interested to continue with us and so that we have all the right to cancel your booking and give it to others. And the booking amount or any paid amount is non-refundable.",
            "Full payment has to be made within committed time period.",
            "If any kind of change in your communication address and contact number, it is your responsibility to inform at our head office to better communicate to you.",
            "Any verbal promises given by our sales executive will not be considered as legal other than written and authorized signed documents by company. Company is not liable to fulfill the same.",
          ].map((term, index) => (
            <li key={index}>
              <img src="/assets/svg/listtick.svg" alt="tick" />
              <p>{term}</p>
            </li>
          ))}
        </ul>
      </div>

      <div className={style.acceptance}>
        <p>
          I, <b>{customerName}</b> understand, agreed and accepted all necessary
          terms & conditions for being member and my booking to{" "}
          <b>Plot No. {plotNo}</b> in {projectName} at village Anandpur,
          Dholera.
        </p>
        <p className={style.mt4}>Accepted By,</p>
        <p className={style.signature}>{customerName}</p>
        <p className={style.mt4}>Yours Faithfully,</p>
        <p className={style.authorizedSign}>For Shamanta Builders LLP</p>
        <p className={style.authorizedSign}>(Authorized Sign)</p>
        {/* <p className={style.mt4}>Date: {applicationDate}</p>
        <p className={style.mt4}>Place: {place}</p> */}
      </div>
    </div>
  );
}

export default Receipt3;
