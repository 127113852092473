import styles from "../Project.module.scss";
import Booking from "../../Bookings/Booking";
import FilterBox from "../../../../Components/FilterBox/FilterBox";
import Button from "../../../../Components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import Tablepagination from "../../../../Components/pagination/Tablepagination";
import {
  clearState,
  getAllUsersByProject,
  projectData,
} from "../../../../reducer/projectSlice";
import { useEffect, useState } from "react";
import Confirmation from "../../../../Components/Popup/Confirmation/Confirmation";
import { deleteUser, userSelector,  clearState as userClearState } from "../../../../reducer/userSlice";

function Agents() {
  const searchIcon = [
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.25 3.3125C5.24695 3.3125 2.8125 5.74695 2.8125 8.75C2.8125 11.753 5.24695 14.1875 8.25 14.1875C9.72046 14.1875 11.0546 13.6038 12.0333 12.6555C12.0502 12.6325 12.069 12.6105 12.0898 12.5898C12.1105 12.569 12.1325 12.5502 12.1555 12.5333C13.1038 11.5546 13.6875 10.2205 13.6875 8.75C13.6875 5.74695 11.253 3.3125 8.25 3.3125ZM13.2712 12.9757C14.233 11.834 14.8125 10.3597 14.8125 8.75C14.8125 5.12563 11.8744 2.1875 8.25 2.1875C4.62563 2.1875 1.6875 5.12563 1.6875 8.75C1.6875 12.3744 4.62563 15.3125 8.25 15.3125C9.85967 15.3125 11.334 14.733 12.4757 13.7712L15.3523 16.6477C15.5719 16.8674 15.9281 16.8674 16.1477 16.6477C16.3674 16.4281 16.3674 16.0719 16.1477 15.8523L13.2712 12.9757Z"
        fill="#737373"
      />
    </svg>,
  ];

  const [showPopup, setShowPopup] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const handleConfirm = (id) => {
    console.log("Confirmed");
    dispatch(deleteUser(id));
    setShowPopup(false);
  };

  const handleCancel = () => {
    console.log("Cancelled");
    setShowPopup(false);
  };

  const router = useNavigate();

  const dispatch = useDispatch();

  const { projectId } = useParams();

  //Sorting states
  const [sortedBy, setSortedBy] = useState("");
  const [sortedOrder, setSortedOrder] = useState("");

  //pagination states
  const [index, setIndex] = useState(1);

  //filter states
  const [filters, setFilters] = useState("");

  const { allUsers } = useSelector(projectData);
  const { isUserDeleted } = useSelector(userSelector);

  useEffect(() => {
    userClearState();
    clearState();


    dispatch(
      getAllUsersByProject({
        projectId,
        roleName: "agent",
        search: filters,
        index,
      })
    );
  }, [index, filters,isUserDeleted]);

  const handleSort = (sort, order) => {
    if (sort === sortedBy && order === sortedOrder) return;
    setIndex(1);
    setSortedBy(sort);
    setSortedOrder(order);
    dispatch(
      getAllUsersByProject({
        projectId,
        roleName: "agent",
        search: filters?.searchText,
        index,
        sortedBy: sort,
        sortedOrder: order,
      })
    );
  };

  return (
    <>
      {showPopup && (
        <Confirmation
          message="Are you sure you want to delete agent?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          id={selectedItemId}
        />
      )}
      <div className={styles.propertyTable}>
        <div className={styles.countBar}>
          <p>
            Total Agents <b>{allUsers?.totalCount}</b>
          </p>

          <div className={styles.buttons}>
            <div className={styles.wrap}>
              <input
                type="text"
                placeholder="Search Here..."
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    setFilters(event.target.value);
                  }
                }}
              />
              {searchIcon}
            </div>
            {/* <FilterBox active={active}/> */}
            <Button
              blueBorderBtn
              text={"Add Agent"}
              icon={"plus"}
              maxWidth={90}
              padding={10}
              height={"auto"}
              minWidth={130}
              onClick={() => router("/admin/addagent")}
            />
          </div>
        </div>
        <div className={styles.tableWrap}>
          <table className={styles.bookingTable}>
            <thead>
              <tr>
                <th>Agent Id</th>
                <th>Agent Name</th>
                <th>City</th>
                <th>Commision</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {allUsers?.users?.map((item, index) => (
                <tr key={item?.id}>
                  <td>
                    <div className={styles.tabData}>{item.code}</div>
                  </td>
                  <td>
                    <div className={styles.tabData}>
                      <img
                        src={item.profileImg ?? `/assets/img/user1.png`}
                        alt="profileImg"
                      />
                      {item.name}
                    </div>
                  </td>
                  <td>
                    <div className={styles.tabData}>
                      {item.address ?? "N/A"}
                    </div>
                  </td>
                  <td>
                    <div className={styles.tabData}>
                      {item?.userProjects?.[0]?.commision ?? "0"}%
                    </div>
                  </td>
                  <td>
                    <div className={styles.tabData}>
                      <img
                        className="pointer"
                        src="/assets/svg/edit-icon.svg"
                        alt=""
                        onClick={() => router("/admin/editAgent/" + item.id)}
                      />
                      <img
                        className="pointer"
                        src="/assets/svg/action3.svg"
                        alt=""
                        onClick={() => {
                          setSelectedItemId(item.id);
                          setShowPopup(true);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Tablepagination
        itemsPerPage={10}
        totalItems={allUsers?.totalCount}
        setIndex={setIndex}
        itemName={"Agents"}
      />
    </>
  );
}

export default Agents;
