import React from 'react'
import style from './InputNew.module.scss'

const Textarea = ({className,placeholder,id,labelName,checkBoxWrap, onChange,disabled, value, checked, isError, errorMessage,notImp, onKeyDown, blackLabel, name, min, max, onBlur}) => {
  return (
    <div className={`${style.formGroups} ${checkBoxWrap ?? ""}`}>
      <label className={blackLabel && 'visible0'} htmlFor={id}>{labelName}
        {!notImp && <span className={style.impRed}> *</span>}
      </label>
      <div className={style.w-100}>
        <textarea rows={5} className={className} placeholder={placeholder} onKeyDown={onKeyDown} disabled={disabled} value={value} onChange={onChange} id={id} checked={checked} name={name} onBlur={onBlur}></textarea>
        {errorMessage && <span className={style.FormError}>{errorMessage}</span>}
      </div>
    </div>
  )
}

export default Textarea