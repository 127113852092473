import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { environment } from "../environment/environments";
const apiRoute = "/api";

let token = "";

const setTokenValues = () => {
    token = `user ${localStorage.getItem("token")}`;
}

export const getAllProjects = createAsyncThunk(
    "project/getAllProjects",
    async ({ search, index, stus }, thunkAPI) => {
        try {
            setTokenValues();
            const title = search ?? "";
            const page = index ?? "";
            const status = stus ?? "";
            // const sort = sortedBy ?? "";
            // const order = sortedOrder ?? "";

            const response = await fetch(
                environment.baseURL +
                apiRoute +
                `/getAllProjects?title=${title}&page=${page}&status=${status}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                }
            );

            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getProjectsList = createAsyncThunk(
    "project/getProjectsList",
    async (_, thunkAPI) => {
        try {
            setTokenValues();

            const response = await fetch(
                environment.baseURL +
                apiRoute +
                `/getProjectsList`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                }
            );

            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const getPropertyList = createAsyncThunk(
    "project/getPropertyList",
    async ({ projectId, isBooked }, thunkAPI) => {
        try {
            setTokenValues();

            const response = await fetch(
                environment.baseURL +
                apiRoute +
                `/${projectId}/getPropertyList?booked=${isBooked}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                }
            );

            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getProjectDetails = createAsyncThunk(
    "project/getProjectDetails",
    async (projectId, thunkAPI) => {
        try {
            setTokenValues();

            const response = await fetch(
                environment.baseURL +
                apiRoute +
                `/${projectId}/getProjectDetails`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                }
            );
            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const getBookingDetails = createAsyncThunk(
    "project/getBookingDetails",
    async (bookingId, thunkAPI) => {
        try {
            setTokenValues();

            const response = await fetch(
                environment.baseURL +
                apiRoute +
                `/${bookingId}/getBookingDetails`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                }
            );
            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const getPropertyDetails = createAsyncThunk(
    "project/getPropertyDetails",
    async (propertyId, thunkAPI) => {
        try {
            setTokenValues();

            const response = await fetch(
                environment.baseURL +
                apiRoute +
                `/${propertyId}/getPropertyDetails`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                }
            );
            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getAllProAgeCusByProject = createAsyncThunk(
    "project/getAllProAgeCusByProject",
    async (projectId, thunkAPI) => {
        try {
            setTokenValues();

            const response = await fetch(
                environment.baseURL +
                apiRoute +
                `/${projectId}/getAllProAgeCusByProject`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                }
            );
            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const getAllPropertiesByProject = createAsyncThunk(
    "project/getAllPropertiesByProject",
    async ({ projectId, search, index, typ }, thunkAPI) => {
        try {
            setTokenValues();
            const title = search ?? "";
            const page = index ?? "";
            const type = typ ?? "";

            const response = await fetch(
                environment.baseURL +
                apiRoute +
                `/${projectId}/getAllPropertiesByProject?title=${title}&page=${page}&type=${type}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                }
            );
            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const getAllTimelines = createAsyncThunk(
    "project/getAllTimelines",
    async ({ projectId, index }, thunkAPI) => {
        try {
            setTokenValues();
            const page = index ?? "";

            const response = await fetch(
                environment.baseURL +
                apiRoute +
                `/${projectId}/getAllTimelines?page=${page}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                }
            );
            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getCustomerProperties = createAsyncThunk(
    "project/getCustomerProperties",
    async ({ search, index, typ }, thunkAPI) => {
        try {
            setTokenValues();
            const title = search ?? "";
            const page = index ?? "";
            const type = typ ?? "";

            const response = await fetch(
                environment.baseURL +
                apiRoute +
                `/getCustomerProperties?title=${title}&page=${page}&type=${type}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                }
            );
            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const getCustomerPropertiesList = createAsyncThunk(
    "project/getCustomerPropertiesList",
    async (thunkAPI) => {
        try {
            setTokenValues();

            const response = await fetch(
                environment.baseURL +
                apiRoute +
                `/getCustomerPropertiesList`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                }
            );
            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getAllBookings = createAsyncThunk(
    "project/getAllBookings",
    async ({ search, index, query }, thunkAPI) => {
        try {
            setTokenValues();
            const { projectId, propertyId, agentId, customerId } = query;
            const title = search ?? "";
            const page = index ?? "";
            const projectQuery = projectId ? `&projectId=${projectId}` : "";
            const propertyQuery = propertyId ? `&propertyId=${propertyId}` : "";
            const agentQuery = agentId ? `&agentId=${agentId}` : "";
            const customerQuery = customerId ? `&customerId=${customerId}` : "";
            // const sort = sortedBy ?? "";
            // const order = sortedOrder ?? "";

            const response = await fetch(
                environment.baseURL +
                apiRoute +
                `/getAllBookings?title=${title}&page=${page}${projectQuery}${propertyQuery}${agentQuery}${customerQuery}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                }
            );

            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const getTopProjects = createAsyncThunk(
    "project/getTopProjects",
    async ({ search }, thunkAPI) => {
        try {
            setTokenValues();
            const title = search ?? "";

            const response = await fetch(
                environment.baseURL +
                apiRoute +
                `/getTopProjects?title=${title}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                }
            );

            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getAllReceipts = createAsyncThunk(
    "project/getAllReceipts",
    async ({ search, index, query }, thunkAPI) => {
        try {
            setTokenValues();
            const { projectId, propertyId, customerId } = query;
            const title = search ?? "";
            const page = index ?? "";
            const projectQuery = projectId ? `&projectId=${projectId}` : "";
            const propertyQuery = propertyId ? `&propertyId=${propertyId}` : "";
            const customerQuery = customerId ? `&customerId=${customerId}` : "";
            // const sort = sortedBy ?? "";
            // const order = sortedOrder ?? "";

            const response = await fetch(
                environment.baseURL +
                apiRoute +
                `/getAllReceipts?title=${title}&page=${page}${projectQuery}${propertyQuery}${customerQuery}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                }
            );

            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getAllUsersByProject = createAsyncThunk(
    "project/getAllUsersByProject",
    async ({ projectId, roleName, search, index, sortedBy, sortedOrder }, thunkAPI) => {
        try {
            setTokenValues();
            const title = search ?? "";
            const page = index ?? "";
            const sort = sortedBy ?? "";
            const order = sortedOrder ?? "";

            const response = await fetch(
                environment.baseURL +
                apiRoute +
                `/${projectId}/${roleName}/getAllUsersByProject?title=${title}&page=${page}&sort=${sort}&order=${order}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                }
            );

            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const getProjectCounts = createAsyncThunk(
    "project/getProjectCounts",
    async (thunkAPI) => {
        try {
            setTokenValues();

            const response = await fetch(
                environment.baseURL +
                apiRoute +
                `/getProjectCounts`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                }
            );

            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const addProject = createAsyncThunk(
    "admin/addProject",
    async (obj, thunkAPI) => {
        try {
            setTokenValues();
            const response = await fetch(
                environment.baseURL +
                apiRoute +
                "/addProject",
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                    body: JSON.stringify(obj)
                }
            );
            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const uploadBookingFiles = createAsyncThunk(
    "admin/uploadBookingFiles",
    async ({obj,bookingId}, thunkAPI) => {
        try {
            setTokenValues();
            const response = await fetch(
                environment.baseURL +
                apiRoute +
                `/${bookingId}/uploadBookingFiles`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                    body: JSON.stringify(obj)
                }
            );
            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const addProperty = createAsyncThunk(
    "admin/addProperty",
    async ({ obj, projectId }, thunkAPI) => {
        try {
            setTokenValues();
            const response = await fetch(
                environment.baseURL +
                apiRoute +
                `/${projectId}/addProperty`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                    body: JSON.stringify(obj)
                }
            );
            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateProperty = createAsyncThunk(
    "admin/updateProperty",
    async ({ obj, propertyId }, thunkAPI) => {
        try {
            setTokenValues();
            const response = await fetch(
                environment.baseURL +
                apiRoute +
                `/${propertyId}/updateProperty`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                    body: JSON.stringify(obj)
                }
            );
            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const addNewBooking = createAsyncThunk(
    "admin/addNewBooking",
    async ({ obj, propertyId }, thunkAPI) => {
        try {
            setTokenValues();
            const response = await fetch(
                environment.baseURL +
                apiRoute +
                `/${propertyId}/addNewBooking`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                    body: JSON.stringify(obj)
                }
            );
            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const addPayment = createAsyncThunk(
    "admin/addPayment",
    async ({ obj, bookingId }, thunkAPI) => {
        try {
            setTokenValues();
            const response = await fetch(
                environment.baseURL +
                apiRoute +
                `/${bookingId}/addPayment`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                    body: JSON.stringify(obj)
                }
            );
            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const deleteProperty = createAsyncThunk(
    "admin/deleteProperty",
    async (propertyId, thunkAPI) => {
        try {
            setTokenValues();
            const response = await fetch(
                environment.baseURL +
                apiRoute +
                `/${propertyId}/deleteProperty`,
                {
                    method: "DELETE",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                }
            );
            if (response.statusText === 'Unauthorized') {
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return thunkAPI.rejectWithValue({ Message: 'Session Timed Out!' });
            }
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const projectSlice = createSlice({
    name: "project",
    initialState: {
        isFetching: false,
        isFetching2: false,
        isSuccess: false,
        isError: false,
        errorMessage: "",
        allProjects: [],
        allReceipts: [],
        allBookings: [],
        isProjectAdded: false,
        uploadedFile: false,
        isPropertyAdded: false,
        isPropertyUpdated: false,
        isBookedAdded: false,
        isPaymentAdded: false,
        isDeletedProperty: false,
        bookingDetails: null,
        projectDetails: null,
        propertyDetails: null,
        allProperties: null,
        allTimeslines: null,
        allCustProperties: null,
        projectCounts: null,
        allUsers: null,
        filterData: null,
        projectsList: [],
        propertyList: [],
        proFilterList: [],
        allCustPropertiesList: [],
        topProjects: []
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            state.isFetching2 = false;
            state.uploadedFile = false;
            state.isProjectAdded = false;
            state.isDeletedProperty = false;
            state.isPropertyAdded = false;
            state.isPropertyUpdated = false;
            state.isBookedAdded = false;
            state.isPaymentAdded = false;
            state.errorMessage = "";
            state.allUsers = null;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllProjects.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getAllProjects.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.allProjects = payload;
        });
        builder.addCase(getAllProjects.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
        builder.addCase(getProjectsList.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getProjectsList.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.projectsList = payload;
        });
        builder.addCase(getProjectsList.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
        builder.addCase(getPropertyList.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getPropertyList.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.propertyList = payload;
        });
        builder.addCase(getPropertyList.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
        builder.addCase(getProjectDetails.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getProjectDetails.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.projectDetails = payload;
        });
        builder.addCase(getProjectDetails.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
        builder.addCase(getBookingDetails.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getBookingDetails.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.bookingDetails = payload;
        });
        builder.addCase(getBookingDetails.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
        builder.addCase(getPropertyDetails.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getPropertyDetails.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.propertyDetails = payload;
        });
        builder.addCase(getPropertyDetails.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
        builder.addCase(getAllProAgeCusByProject.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getAllProAgeCusByProject.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.filterData = payload;
        });
        builder.addCase(getAllProAgeCusByProject.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
        builder.addCase(getAllPropertiesByProject.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getAllPropertiesByProject.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.allProperties = payload;
        });
        builder.addCase(getAllPropertiesByProject.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
        builder.addCase(getAllTimelines.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getAllTimelines.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.allTimeslines = payload;
        });
        builder.addCase(getAllTimelines.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
        builder.addCase(getCustomerProperties.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getCustomerProperties.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.allCustProperties = payload;
        });
        builder.addCase(getCustomerProperties.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
        builder.addCase(getCustomerPropertiesList.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getCustomerPropertiesList.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.allCustPropertiesList = payload;
        });
        builder.addCase(getCustomerPropertiesList.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
        builder.addCase(getAllBookings.pending, (state) => {
            state.isFetching2 = true;
            state.isFetching = true;
        });
        builder.addCase(getAllBookings.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isFetching2 = false;
            state.isSuccess = true;
            state.allBookings = payload;
        });
        builder.addCase(getAllBookings.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isFetching2 = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
        builder.addCase(getAllReceipts.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getAllReceipts.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.allReceipts = payload;
        });
        builder.addCase(getAllReceipts.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
        builder.addCase(getAllUsersByProject.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getAllUsersByProject.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.allUsers = payload;
        });
        builder.addCase(getAllUsersByProject.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
        builder.addCase(getProjectCounts.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getProjectCounts.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.projectCounts = payload;
        });
        builder.addCase(getProjectCounts.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
        builder.addCase(getTopProjects.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(getTopProjects.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.topProjects = payload;
        });
        builder.addCase(getTopProjects.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
        builder.addCase(addProject.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(addProject.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isProjectAdded = true;
        });
        builder.addCase(addProject.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
        builder.addCase(uploadBookingFiles.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(uploadBookingFiles.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.uploadedFile = true;
        });
        builder.addCase(uploadBookingFiles.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
        builder.addCase(addProperty.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(addProperty.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isPropertyAdded = true;
        });
        builder.addCase(addProperty.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
        builder.addCase(updateProperty.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(updateProperty.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isPropertyUpdated = true;
        });
        builder.addCase(updateProperty.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
        builder.addCase(addNewBooking.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(addNewBooking.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isBookedAdded = true;
        });
        builder.addCase(addNewBooking.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
        builder.addCase(addPayment.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(addPayment.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isPaymentAdded = true;
        });
        builder.addCase(addPayment.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
        builder.addCase(deleteProperty.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(deleteProperty.fulfilled, (state, { payload }) => {
            state.isFetching = false;
            state.isDeletedProperty = true;
        });
        builder.addCase(deleteProperty.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message ?? "Something went wrong!";
        });
    },
});


export const { clearState } = projectSlice.actions;

export const projectData = (state) => state.project;