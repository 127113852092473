import React from 'react'
import styles from './newsCard.module.scss'

const NewsBox = ({className}) => {

    const arrow = [
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.64612 2.98043C7.84138 2.78517 8.15797 2.78517 8.35323 2.98043L13.0199 7.6471C13.2152 7.84236 13.2152 8.15894 13.0199 8.3542L8.35323 13.0209C8.15797 13.2161 7.84138 13.2161 7.64612 13.0209C7.45086 12.8256 7.45086 12.509 7.64612 12.3138L11.4592 8.50065H3.33301C3.05687 8.50065 2.83301 8.27679 2.83301 8.00065C2.83301 7.72451 3.05687 7.50065 3.33301 7.50065H11.4592L7.64612 3.68754C7.45086 3.49228 7.45086 3.17569 7.64612 2.98043Z" fill="black" />
        </svg>
    ]

    return (
        <div className={`${className} ${styles.cardWrapIn}`}>
            <img src="/assets/img/advImg.png" alt="" />
            <h5>Excepteur sint occaecat cupidatat minima veniam</h5>
            <p>Property is any item that a person or a business has legal title over.Property is any item that a person or a business has legal title over.Property is any item that a person or a business has legal title over. </p>
            <span>Read More {arrow}</span>
        </div>
    )
}

export default NewsBox