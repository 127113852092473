import React, { useEffect } from "react";
import styles from "./Reciptpop.module.scss";
import Receipt2 from "../../Receipt/Receipt2";
import { useDispatch, useSelector } from "react-redux";
import { getBookingDetails, projectData } from "../../../reducer/projectSlice";
import PageLoader from "../../PageLoader/PageLoader";
const Reciptpop2 = ({ show, handleClose, bookingId }) => {
  const dispatch = useDispatch();

  const { bookingDetails, isFetching } = useSelector(projectData);
  useEffect(() => {
    bookingId && show && dispatch(getBookingDetails(bookingId));
  }, []);
  return (
    <>
      {isFetching && <PageLoader />}
      <div className={`popup ${show ? "show" : ""}`}>
        <div className={styles.popupinner}>
          <div className={styles.popupHeader}>
            <h3>Booking Form</h3>
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={handleClose}
            >
              <rect
                x="4.79883"
                y="5.79785"
                width="0.7"
                height="14"
                transform="rotate(-45 4.79883 5.79785)"
                fill="#171717"
              />
              <rect
                width="0.7"
                height="14"
                transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 15.1953 5.79785)"
                fill="#171717"
              />
            </svg>
          </div>
          <div className={styles.popupbody}>
            {bookingDetails && <Receipt2 data={bookingDetails} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Reciptpop2;
